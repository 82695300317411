import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DataImportModel, DataImportStateKey } from '@ninety/data-import/_state/data-import.model';
import {
  CUSTOMER_FACING_DATA_IMPORT_TYPES,
  DataImportType,
  HELPFUL_DATA_IMPORT_TYPES,
} from '@ninety/data-import/models/data-import-types';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentUserIsAdminOrOwner } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectCompany } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectCurrentPerson } from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';
import { selectHasHelpfulDataImportPermissions } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

export namespace DataImportSelectors {
  export const selectDataImportState = createFeatureSelector<DataImportModel>(DataImportStateKey);

  export const selectShowedConfirmDialog = createSelector(selectDataImportState, state => state.showedConfirmDialog);

  export const selectShowOneSchemaIframe = createSelector(selectDataImportState, state => state.showOneSchemaIframe);
  export const selectShowPreviewTable = createSelector(selectDataImportState, state => state.showPreviewTable);

  export const selectToken = createSelector(selectDataImportState, state => state.userJwt);

  export const selectImportType = createSelector(selectDataImportState, state => state.selectedImportType);

  export const selectTemplateKey = createSelector(selectImportType, type => type?.templateKey);

  export const selectTemplateOptions = createSelector(
    selectLanguage,
    selectHasHelpfulDataImportPermissions,
    selectTemplateKey,
    (language, isHelpful, templateKey): QuickFilterOption<DataImportType>[] =>
      (isHelpful ? HELPFUL_DATA_IMPORT_TYPES : CUSTOMER_FACING_DATA_IMPORT_TYPES).map(item => ({
        id: item.templateKey,
        displayText: item.label || language[item.languageKey][item.labelKey],
        item: {
          ...item,
          correspondingPage: language[item.languageKey][item.labelKey],
        },
        icon: { name: item.icon, size: 20 },
        selected: item.templateKey === templateKey,
      }))
  );

  export const selectTeamId = createSelector(selectDataImportState, state => state.selectedTeamId);

  export const selectHasImportPermissions = createSelector(
    selectHasHelpfulDataImportPermissions,
    selectFeatureFlag(FeatureFlagKeys.clientFacingDataUpload),
    selectCurrentUserIsAdminOrOwner,
    (isHelpful, flag, isAdminOrOwner): boolean => isHelpful || (flag && isAdminOrOwner)
  );

  export const selectShowTeamFilter = createSelector(
    selectTemplateKey,
    templateKey => templateKey !== OneSchemaTemplateKey.users
  );

  export const selectGainsightData = createSelector(
    selectCurrentPerson,
    selectCompany,
    selectHasHelpfulDataImportPermissions,
    (person, company, isHelpful) => ({
      email: person?.primaryEmail,
      companyName: company?.name,
      isHelpful,
    })
  );
}
