import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ninety-data-import-user-step-three',
  template: `<ninety-card>
    <div class="header">
      <terra-icon icon="edit" size="28"></terra-icon>
      <div class="title">Step 3: Validate Your Data</div>
    </div>
    <div class="content">
      <ul>
        <li>To proceed with the import process, resolve any denoted issues.</li>
        <li>Users assigned to a non-existent team cannot be added.</li>
        <li>
          Assigning paid roles to imported users beyond the number of a available licenses will increase your licenses
          count and subscription cost. The payment method on file will be charged accordingly.
        </li>
      </ul>
    </div>
  </ninety-card>`,
  styleUrls: ['./info-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataImportUserStepThreeComponent {}
