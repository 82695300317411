import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ninety-data-import-user-step-two',
  template: `<ninety-card>
    <div class="header">
      <terra-icon icon="warning-triangle" size="28"></terra-icon>
      <div class="title">Step 2: Resolve Import Issues</div>
    </div>
    <div class="content">
      <ul>
        <li>To proceed with the import process, resolve any issues listed in the “Rows with issues” tab.</li>
        <li>Users without assigned roles will be automatically designated as Team Members/Managees.</li>
        <li>
          If your imported CSV file does not match the template format, utilize the “Map columns” feature to reformat
          your data.
        </li>
        <li>For any issues or concerns, please contact our Support team.</li>
      </ul>
    </div>
  </ninety-card>`,
  styleUrls: ['./info-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataImportUserStepTwoComponent {}
