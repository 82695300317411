import { Pipe, PipeTransform } from '@angular/core';

import { NinetyFeatures } from '../models/company/company-pricing-tiers';

@Pipe({
  name: 'isTieredPageActive',
  standalone: true,
})
export class IsTieredPageActivePipe implements PipeTransform {
  static transform(activeUrl: string, urlFrag: NinetyFeatures | string): boolean {
    if (!activeUrl || !urlFrag) return false;
    return activeUrl.includes(urlFrag);
  }

  transform(activeUrl: string, urlFrag: NinetyFeatures | string): boolean {
    return IsTieredPageActivePipe.transform(activeUrl, urlFrag);
  }
}
