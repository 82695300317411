<div class="ninety-cm-tiered-inline">
  <div class="ninety-cm-tiered-inline__content">
    <div class="ninety-cm-tiered-inline__headline">
      <ng-content select="[headline]" />
    </div>
    <div class="ninety-cm-tiered-inline__description">
      <ng-content select="[description]" />
    </div>
  </div>
  <div class="ninety-cm-tiered-inline__upsell">
    <ng-content select="button[ninety-cm-button]" />
  </div>
</div>
