import { Attachment } from '@ninety/ui/legacy/shared/models/_shared/attachment';

import { AttachmentListItem } from '../models';
import { createId } from '../utils/create-id';

export function mapAttachmentsToListItem(attachments: Attachment[]): AttachmentListItem[] {
  if (!attachments?.length) return [];

  const items = attachments.map(att => {
    const item: AttachmentListItem = {
      id: createId(),
      attachment: att,
      markedForRemoval: false,
      markedForUpload: false,
    };

    return item;
  });

  return items;
}
