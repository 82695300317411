import { NgModule } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { NinetyCmButtonComponent } from './cm-button/cm-button.component';
import { NinetyCmPopoverTriggerDirective } from './cm-popover/cm-popover-trigger.directive';
import { NinetyCmPopoverComponent } from './cm-popover/cm-popover.component';
import { NinetyCmTieredInlineComponent } from './cm-tiered-inline/cm-tiered-inline.component';
import { NinetyCmTieredPageComponent } from './cm-tiered-page/cm-tiered-page.component';

@NgModule({
  imports: [
    NinetyCmButtonComponent,
    NinetyCmTieredInlineComponent,
    NinetyCmTieredPageComponent,
    NinetyCmPopoverComponent,
    NinetyCmPopoverTriggerDirective,
    TerraIconModule,
  ],
  exports: [
    NinetyCmButtonComponent,
    NinetyCmTieredInlineComponent,
    NinetyCmTieredPageComponent,
    NinetyCmPopoverComponent,
    NinetyCmPopoverTriggerDirective,
    TerraIconModule,
  ],
})
export class NinetyCMModule {}
