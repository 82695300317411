import { Injectable } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

/**
 * Default Error state matcher for Terra components
 * To be used in places where we want a consistent check for error state
 */
@Injectable({ providedIn: 'root' })
export class TerraErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form?: FormGroupDirective | NgForm | null): boolean;
  isErrorState(control: NgControl, form?: FormGroupDirective | NgForm | null): boolean;

  isErrorState(control: AbstractControl | NgControl | null, form?: FormGroupDirective | NgForm | null): boolean {
    if (control instanceof NgControl) {
      return this.isErrorState(control.control, form);
    }
    return !!(control && control.invalid && (control.touched || (form && form.submitted)));
  }
}

/**
 * Error state matcher for Terra components that only shows error state when the control is dirty
 */
@Injectable({ providedIn: 'root' })
export class TerraShowOnDirtyErrorStateMatcher {
  isErrorState(control: AbstractControl | null, form?: FormGroupDirective | NgForm | null): boolean;
  isErrorState(control: NgControl, form?: FormGroupDirective | NgForm | null): boolean;

  isErrorState(control: AbstractControl | NgControl | null, form?: FormGroupDirective | NgForm | null): boolean {
    if (control instanceof NgControl) {
      return this.isErrorState(control.control, form);
    }
    return !!(control && control.invalid && (control.dirty || (form && form.submitted)));
  }
}
