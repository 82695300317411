import { createEntityAdapter, EntityState, IdSelector } from '@ngrx/entity';

import { Team } from '@ninety/ui/legacy/shared/index';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockSort } from '@ninety/ui/legacy/shared/models/rocks/rock-sort';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';

export const RockModuleStateKey = 'rocks';

const selectRockId: IdSelector<Rock> = ({ _id }) => _id;
const selectMilestoneId: IdSelector<Milestone> = ({ _id }) => _id;
export const rocksStateAdapter = createEntityAdapter<Rock>({ selectId: selectRockId });
export const rocksStateAdapterForCompany = createEntityAdapter<Rock>({ selectId: selectRockId });
export const rocksStateAdapterForDepartment = createEntityAdapter<Rock>({ selectId: selectRockId });
export const rocksStateAdapterForUsersNotOnTeam = createEntityAdapter<Rock>({ selectId: selectRockId });
export const milestonesStateAdapter = createEntityAdapter<Milestone>({
  selectId: selectMilestoneId,
  sortComparer: sortByDueDate,
});

export function sortByDueDate(a: Milestone, b: Milestone): number {
  // Some users are encountering missing dueDates and it's breaking things
  // Need to handle missing values.
  if (a.dueDate?.toLocaleString && b.dueDate?.toLocaleString) {
    return a.dueDate.toLocaleString().localeCompare(b.dueDate.toLocaleString());
  } else if (a.title?.length && b.title?.length) {
    return a.title.localeCompare(b.title);
  } else {
    return 0;
  }
}
export interface RockModuleStateModel {
  rocks: EntityState<Rock>;
  rocksForCompany: EntityState<Rock>;
  rocksForDepartment: EntityState<Rock>;
  rocksForUsersNotOnTeam: EntityState<Rock>;
  milestones: EntityState<Milestone>;

  loading: boolean;
  selectedRockId: string;
  selectedMilestoneId: string;
  totalCount: number;

  filters: {
    userId: string;
    teamId: string;
    statusCode: RockStatusCode;
  };
  pagination: { index: number; size: number };
  sort: { direction: SortDirection; field: RockSort };
  rocksV3: boolean;
  currentTeam: Team;
  tooltipStatistics: {
    completionRateAll: string;
    completionRate: string;
    companyRocksDone: string;
    allRocksDone: string;
    departmentCompletionRate: string;
    departmentRocksDone: string;
  };

  error: boolean;
}

export const rocksMilestonesInitialState: RockModuleStateModel = {
  rocks: rocksStateAdapter.getInitialState(),
  rocksForCompany: rocksStateAdapterForCompany.getInitialState(),
  rocksForDepartment: rocksStateAdapterForDepartment.getInitialState(),
  rocksForUsersNotOnTeam: rocksStateAdapterForUsersNotOnTeam.getInitialState(),
  milestones: milestonesStateAdapter.getInitialState(),
  loading: false,
  selectedRockId: null,
  selectedMilestoneId: null,
  totalCount: 0,
  filters: {
    userId: null,
    teamId: null,
    statusCode: null,
  },
  pagination: { index: 0, size: 10 },
  sort: { direction: null, field: null },
  rocksV3: false,
  currentTeam: null,
  tooltipStatistics: {
    completionRateAll: '',
    completionRate: '',
    companyRocksDone: '',
    allRocksDone: '',
    departmentCompletionRate: '',
    departmentRocksDone: '',
  },

  error: false,
};
