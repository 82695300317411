<div
  *ngrxLet="{
    language: language$,
    selectedTeam: selectedTeam$,
    teams: teams$,
    agendas: agendas$,
  } as vm">
  <div class="card-wrp" *ngIf="vm.selectedTeam">
    <ninety-card class="agendas-v2-card">
      <ninety-card-header header>
        <ninety-card-title-with-count title="Agendas" [count]="vm.agendas.length"></ninety-card-title-with-count>
        <span class="description">Manage meeting agendas for your team.</span>
      </ninety-card-header>

      <table mat-table [dataSource]="vm.agendas" class="agendas-v2">
        <ng-container matColumnDef="frontSpacer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef class="front-spacer"></td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Title</th>
          <td mat-cell *matCellDef="let agenda">
            <div class="title-wrp">
              <terra-icon [icon]="agenda.type === 'Custom' ? 'lightning' : 'calendar'" size="32" />
              <span>{{ agenda.title }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let agenda">
            {{ agenda.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endSpacer">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef class="end-spacer"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          id="agenda-row-{{ row._id }}"
          (click)="onSelected(row)"></tr>
      </table>
      <ninety-card-footer footer class="full-width flex-justify-space-between flex-wrap">
        <ninety-add-item-button
          class="create-agenda-btn"
          data-cy="create-agenda-btn"
          label="Create Agenda"
          (clicked)="createAgenda()"></ninety-add-item-button>
      </ninety-card-footer>
    </ninety-card>
  </div>
</div>
