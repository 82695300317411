import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatestWith, map } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { SegmentTrackEvent } from '@ninety/ui/legacy/core/analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '@ninety/ui/legacy/core/analytics/segment/segment.actions';

@Injectable()
export class DataImportAnalyticsEffects {
  constructor(private readonly actions$: Actions, private readonly store: Store) {}

  trackReceivedDataFromOneSchema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleDataFromOneSchema),
      combineLatestWith(this.store.select(DataImportSelectors.selectGainsightData)),
      map(([{ data }, importContext]) => {
        const params = {
          ...importContext,
          rowCount: data?.records?.length,
        };
        return SegmentActions.track({ event: SegmentTrackEvent.DATA_IMPORT_RECEIVED_DATA_FROM_ONE_SCHEMA, params });
      })
    )
  );

  trackCanceled$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.cancel),
      combineLatestWith(this.store.select(DataImportSelectors.selectGainsightData)),
      map(([_, importContext]) =>
        SegmentActions.track({
          event: SegmentTrackEvent.DATA_IMPORT_CANCELED,
          params: importContext,
        })
      )
    )
  );

  trackCreateUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createUsers),
      combineLatestWith(this.store.select(DataImportSelectors.selectGainsightData)),
      map(([{ users }, importContext]) =>
        SegmentActions.track({
          event: SegmentTrackEvent.DATA_IMPORT_CREATE_USERS,
          params: {
            ...importContext,
            userCount: users.length,
          },
        })
      )
    )
  );

  trackCreateTodos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createTodos),
      combineLatestWith(this.store.select(DataImportSelectors.selectGainsightData)),
      map(([{ todos }, importContext]) =>
        SegmentActions.track({
          event: SegmentTrackEvent.DATA_IMPORT_CREATE_TODOS,
          params: {
            ...importContext,
            todoCount: todos.length,
          },
        })
      )
    )
  );

  trackCreateIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createIssues),
      combineLatestWith(this.store.select(DataImportSelectors.selectGainsightData)),
      map(([{ issues }, importContext]) =>
        SegmentActions.track({
          event: SegmentTrackEvent.DATA_IMPORT_CREATE_ISSUES,
          params: {
            ...importContext,
            issueCount: issues.length,
          },
        })
      )
    )
  );

  trackCreateRocks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createRocks),
      combineLatestWith(this.store.select(DataImportSelectors.selectGainsightData)),
      map(([{ rocks }, importContext]) =>
        SegmentActions.track({
          event: SegmentTrackEvent.DATA_IMPORT_CREATE_ROCKS,
          params: {
            ...importContext,
            rockCount: rocks.length,
          },
        })
      )
    )
  );
}
