import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { ConversationListState, ConversationListStateKey } from './conversation-list/conversation-list.model';
import { conversationListReducer } from './conversation-list/conversation-list.reducer';
import { FitCheckState, FitCheckStateKey } from './fit-check-list/fit-check.model';
import { fitCheckReducer } from './fit-check-list/fit-check.reducer';
import { FeedbackPageState, FeedbackPageStateKey } from './page/feedback-page.model';
import { feedbackPageReducer } from './page/feedback-page.reducer';
import { WeeklyStateKey } from './weekly/weekly.model';
import { WeeklyState, weeklyReducer } from './weekly/weekly.reducer';

export const feedbackFeatureKey = 'page-feedback';

export const selectFeedbackState = createFeatureSelector<FeedbackState>(feedbackFeatureKey);

export interface FeedbackState {
  [FeedbackPageStateKey]: FeedbackPageState;
  [ConversationListStateKey]: ConversationListState;
  [FitCheckStateKey]: FitCheckState;
  [WeeklyStateKey]: WeeklyState;
}

export const feedbackStateReducers: ActionReducerMap<FeedbackState> = {
  [FeedbackPageStateKey]: feedbackPageReducer,
  [ConversationListStateKey]: conversationListReducer,
  [FitCheckStateKey]: fitCheckReducer,
  [WeeklyStateKey]: weeklyReducer,
};
