import { createReducer, on } from '@ngrx/store';

import { MeetingAgendaType, Team } from '../../../_shared/index';

import { teamNone } from './api/team-list.model';
import { TeamListStateActions, UserTeamsActions } from './team-list-state.actions';
import { teamListInitialState, teamListStateAdapter, TeamListStateModel } from './team-list-state.model';

export const teamListStateReducer = createReducer(
  teamListInitialState,
  on(TeamListStateActions.getTeamListSuccess, (state, { teams }) => ({
    ...teamListStateAdapter.setAll(teams, state),
    fetchingTeams: false,
  })),

  on(TeamListStateActions.getArchivedTeamListSuccess, (state, { archivedTeams }) => {
    const currentTeams = teamListStateAdapter.getSelectors().selectAll(state);
    const mergedTeams = [...currentTeams, ...archivedTeams];

    return {
      ...teamListStateAdapter.setAll(mergedTeams, state),
      fetchingArchivedTeams: false,
      archivedTeamsLoaded: true,
    };
  }),

  on(TeamListStateActions.getSelectedTeam, (state, { id }) => ({
    ...state,
    // This is a temporary population of the selected team object while the API is fetching the full team object
    // If the Id is not found in the entities, we default to the first team in the list or the teamNone object
    // Do not default to null as this has bad downstream side effects
    filterBarTeam: (state.entities[id] || state.entities[Object.keys(state.entities)[0]] || teamNone) as Team,
  })),
  on(TeamListStateActions.getSelectedTeamSuccess, (state, { team }) => ({ ...state, filterBarTeam: team })),

  on(TeamListStateActions.addOne, (state, { team }) => teamListStateAdapter.addOne(team, state)),
  on(TeamListStateActions.removeOne, (state, { teamId }) => teamListStateAdapter.removeOne(teamId, state)),
  on(TeamListStateActions.updateOneSuccess, (state, { id, update }) => {
    const newState = {
      ...state,
    };

    if (state.filterBarTeam?._id === id) {
      newState.filterBarTeam = { ...newState.filterBarTeam, ...update };
    }

    return teamListStateAdapter.updateOne({ id, changes: update }, newState);
  }),
  on(TeamListStateActions.editTeam, (state, { teamId }) => ({ ...state, editedTeamId: teamId })),
  on(TeamListStateActions.setEditedTeamId, TeamListStateActions.openTeamDetail, (state, { teamId }) => ({
    ...state,
    editedTeamId: teamId,
  })),
  on(TeamListStateActions.updateSelectedTeamInMemory, (state, { teamId, changes }) => {
    if (state.filterBarTeam?._id !== teamId) return state;
    return {
      ...state,
      filterBarTeam: { ...state.filterBarTeam, ...changes },
    };
  }),
  on(TeamListStateActions.updateSelectedTeamSettingInMemory, (state, { teamId, changes }) => {
    if (state.filterBarTeam?._id !== teamId) return state;
    return {
      ...state,
      filterBarTeam: { ...state.filterBarTeam, settings: { ...state.filterBarTeam.settings, ...changes } },
    };
  }),

  on(
    TeamListStateActions.updateSelectedTeamAgendasOnPushToAllTeams,
    (state, { meetingAgendas, typeOrCustomId }): TeamListStateModel => {
      const { agendaType, agendaId } = typeOrCustomId;

      if (agendaType !== MeetingAgendaType.custom) {
        return {
          ...state,
          filterBarTeam: {
            ...state.filterBarTeam,
            settings: { ...state.filterBarTeam.settings, ...{ [agendaType]: meetingAgendas[agendaType] } },
          },
        };
      }

      if (agendaType === MeetingAgendaType.custom && agendaId) {
        const customAgendaToUpdate = state.filterBarTeam.settings.custom.find(agenda => agenda._id === agendaId);

        const updatedCustomAgenda = meetingAgendas.custom.find(agenda => agenda._id === agendaId);

        if (customAgendaToUpdate) {
          //update the custom agenda on selected team

          const updatedCustomAgendasList = state.filterBarTeam.settings.custom.map(agenda =>
            agenda._id === agendaId ? updatedCustomAgenda : agenda
          );

          return {
            ...state,
            filterBarTeam: {
              ...state.filterBarTeam,
              settings: {
                ...state.filterBarTeam.settings,
                custom: updatedCustomAgendasList,
              },
            },
          };
        }

        //add the custom agenda, as we could not find it in the selected team agenda list
        return {
          ...state,
          filterBarTeam: {
            ...state.filterBarTeam,
            settings: {
              ...state.filterBarTeam.settings,
              custom: [...state.filterBarTeam.settings.custom, updatedCustomAgenda],
            },
          },
        };
      }
    }
  ),

  on(UserTeamsActions.closeDetailView, state => ({ ...state, editedTeamId: null })),
  on(TeamListStateActions.updateSettingsSuccess, (state, { teamId, update }) => {
    if (state.filterBarTeam?._id === teamId) {
      return {
        ...state,
        filterBarTeam: { ...state.filterBarTeam, settings: { ...state.filterBarTeam.settings, ...update } },
      };
    }
    return state;
  }),
  on(TeamListStateActions.updateTeamMeetingAgendaSuccess, (state, { update }) => {
    if (state.filterBarTeam?._id === update.teamId) {
      const { agendaType, teamAgenda } = update;
      const changes = { settings: {} };
      if (agendaType === 'custom' && Array.isArray(teamAgenda)) {
        changes.settings['custom'] = teamAgenda;
      } else if (agendaType !== 'custom' && !Array.isArray(teamAgenda)) {
        changes.settings[agendaType] = teamAgenda;
      }
      return {
        ...state,
        filterBarTeam: {
          ...state.filterBarTeam,
          settings: { ...state.filterBarTeam.settings, ...changes.settings },
        },
      };
    }
    return state;
  })
);
