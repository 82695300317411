export enum ConversationType {
  quarterly = 'quarterly',
  annual = 'annual',
}

export const WeeklyConversation = 'weekly';

export const ConversationTypeLabels = {
  [ConversationType.quarterly]: 'Quarterly',
  [ConversationType.annual]: 'Annual',
};
