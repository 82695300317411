import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { TerraSizing } from '../../models';
import { TerraInputBoolean } from '../../models/terra-input-boolean.models';
import { TerraIconModule, TerraIconName } from '../terra-icon';

import { TERRA_BADGE_ICONS, TerraBadgeIconModel } from './terra-badge-icon.model';

export type TerraBadgeSize = 'tiny' | 'small' | 'medium';
export type TerraBadgeColor = 'neutral' | 'brand' | 'green' | 'yellow' | 'orange' | 'red';

export type TerraBadgeIconNames = 'check' | 'edit' | 'clock-hands' | 'question-mark' | 'exclamation-mark' | 'x';
export type TerraBadgeIcon = Extract<TerraIconName, TerraBadgeIconNames>;
@Component({
  selector: 'terra-badge',
  standalone: true,
  exportAs: 'terraBadge',
  imports: [CommonModule, TerraIconModule],
  templateUrl: './terra-badge.component.html',
  styleUrls: ['./terra-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraBadgeComponent implements OnInit {
  /**
   * Reference to the wrapping div element where classes will be added to
   */
  @ViewChild('badge', { static: true }) private _badge!: ElementRef<HTMLElement>;

  /**
   * Icon displayed in the badge
   * @default check
   */
  @Input() get icon(): TerraBadgeIcon {
    return this._icon;
  }
  set icon(value: TerraBadgeIcon) {
    this._icon = value;
    this._badgeIcon = this._getBadgeIcon(this._icon);
    this._changeDetectorRef.markForCheck();
  }
  private _icon: TerraBadgeIcon = 'check';
  protected _badgeIcon: TerraBadgeIconModel = TERRA_BADGE_ICONS[0];

  /**
   * Size of the badge
   * @default medium
   */
  @Input() get size(): TerraBadgeSize {
    return this._size;
  }
  set size(value: TerraBadgeSize) {
    this._removeClass('size', this._size);
    this._size = value;
    this._addClass('size', this._size);
    this._changeDetectorRef.markForCheck();
  }
  private _size: TerraBadgeSize = 'medium';

  /**
   * Color of the badge
   * @default neutral
   */
  @Input() get color(): TerraBadgeColor {
    return this._color;
  }
  set color(value: TerraBadgeColor) {
    this._removeClass('color', this._color);
    this._color = value;
    this._addClass('color', this._color);
    this._changeDetectorRef.markForCheck();
  }
  private _color: TerraBadgeColor = 'neutral';

  /**
   * Set whether to display white stroke on the badge
   * @default false
   */
  @Input() get stroke(): boolean {
    return this._stroke;
  }
  set stroke(value: TerraInputBoolean) {
    this._stroke = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _stroke = false;

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef, private readonly _renderer: Renderer2) {}

  ngOnInit() {
    this._addClass('size', this.size);
    this._addClass('color', this.color);
  }

  protected _getIconSize(): TerraSizing {
    let badgeIconSize: TerraSizing;

    switch (this.size) {
      case 'small':
        badgeIconSize = 12;
        break;
      case 'medium':
        badgeIconSize = 16;
        break;
      default:
        badgeIconSize = 16;
    }

    return badgeIconSize;
  }

  private _getBadgeIcon(icon: TerraBadgeIcon): TerraBadgeIconModel {
    return TERRA_BADGE_ICONS.find(badgeIcon => badgeIcon.name === icon) || TERRA_BADGE_ICONS[0];
  }

  /**
   * Helper function for adding proper styles to the badge
   */
  private _addClass(modifier: string, value: string): void {
    if (this._badge) {
      this._renderer.addClass(this._badge.nativeElement, `terra-badge--${modifier}-${value}`);
    }
  }

  /**
   * Helper function to remove previously added classes from the badge
   */
  private _removeClass(modifier: string, value: string): void {
    if (this._badge) {
      this._renderer.removeClass(this._badge.nativeElement, `terra-badge--${modifier}-${value}`);
    }
  }

  /**
   * @ignore
   *
   * With content projection and input enforcement a wrapping component needs to tell this component to detectChanges
   *
   * When using make sure that no new errors are introduced in console
   */
  public _detectChanges(): void {
    this._changeDetectorRef.detectChanges();
  }
}
