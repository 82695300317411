<div class="ninety-cm-tiered-page">
  <img src="assets/images/ninety-cm-tiered-page-graphic.png" class="ninety-cm-tiered-page__image" />
  <div class="ninety-cm-tiered-page__content">
    <div class="ninety-cm-tiered-page__headline">
      <ng-content select="[headline]" />
    </div>
    <div class="ninety-cm-tiered-page__description">
      <ng-content select="[description]" />
    </div>
  </div>
  <div class="ninety-cm-tiered-page__upsell">
    <ng-content select="button[ninety-cm-button]" />
  </div>
  <div class="ninety-cm-tiered-page__hyperlink">
    <ng-content select="a" />
  </div>
</div>
