import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { merge as _merge } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CompanyService } from '@ninety/ui/legacy/core/services/company.service';
import { MeetingAgendaService } from '@ninety/ui/legacy/core/services/meeting-agenda.service';
import { CompanyMeetingAgendaPush, MeetingAgendas } from '@ninety/ui/legacy/shared/models/_shared/team';
import { Company } from '@ninety/ui/legacy/shared/models/company/company';
import { MeetingAgendaType } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda-type.enum';
import { selectCompany, selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';
import { CompanyActions, TeamListStateActions } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

@Component({
  selector: 'ninety-company-meeting-agenda',
  template: `<ninety-meeting-agendas
    *ngrxLet="currentCompanyId$ as currentCompanyId"
    class="meeting-settings"
    [agendas]="agendas$ | ngrxPush"
    subtitle="See More Options to push these agenda changes to all existing teams."
    (saveCompanyAgendaByType)="companyService.updateMeetingAgendasByType($event).subscribe()"
    (deleteCustomAgenda)="deleteAgendas($event)"
    (pushCompanyAgenda)="pushCompanyAgenda($event)"
    (getResetAgendas)="getCompanyResetAgendas(currentCompanyId)"
    [resetAgendas]="resetAgendas$ | ngrxPush"></ninety-meeting-agendas>`,
  styles: [],
})
export class CompanyMeetingAgendaComponent {
  agendas$ = this.store.select(selectCompany).pipe(switchMap(company => this.setAgendas(company)));
  currentCompanyId$ = this.store.select(selectCompanyId);

  private _resetAgendas = new BehaviorSubject<MeetingAgendas>(null);
  resetAgendas$ = this._resetAgendas.asObservable();

  constructor(
    public companyService: CompanyService,
    private store: Store,
    private agendasService: MeetingAgendaService
  ) {}

  setAgendas(currentCompany: Company): Observable<MeetingAgendas> {
    return this.agendasService
      .getDefaultBosAgendas(currentCompany.bos)
      .pipe(map(bosAgendas => _merge({}, bosAgendas, currentCompany.meetingAgendas)));
  }

  deleteAgendas(agendaId: string): void {
    this.companyService.deleteCustomAgenda(agendaId).subscribe();
  }

  pushCompanyAgenda({ agendaType, agendaId }: CompanyMeetingAgendaPush): void {
    const companyId = extractValueFromStore(this.store, selectCompanyId);
    const request =
      agendaType === MeetingAgendaType.custom
        ? this.companyService.pushCustomAgenda(companyId, agendaId)
        : this.companyService.pushAgendaByType(companyId, agendaType);

    this.store.dispatch(SpinnerActions.startPrimary({ source: 'Company Meeting Agendas' }));

    request.pipe(switchMap(() => this.companyService.getCompanyById(companyId))).subscribe({
      next: cia => {
        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(CompanyActions.updateCompany({ changes: { meetingAgendas: cia.meetingAgendas } }));

        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(
          TeamListStateActions.updateSelectedTeamAgendasOnPushToAllTeams({
            meetingAgendas: cia.meetingAgendas,
            typeOrCustomId: { agendaType, agendaId },
          })
        );

        // eslint-disable-next-line @ngrx/avoid-dispatching-multiple-actions-sequentially
        this.store.dispatch(SpinnerActions.stopPrimary({ source: 'Company Meeting Agendas' }));
      },
    });
  }

  getCompanyResetAgendas(companyId: string): void {
    this.agendasService.getCompanyResetAgendas(companyId).subscribe({
      next: agendas => this._resetAgendas.next(agendas),
    });
  }
}
