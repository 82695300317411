import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectLanguage } from '../../../_state';
import { Locale } from '../../models/_shared/user-settings';
import { Issue } from '../../models/issues/issue';
import { Rock } from '../../models/rocks/rock';
import { Todo } from '../../models/todos/todo';

@Component({
  selector: 'ninety-item-additional-details',
  templateUrl: './item-additional-details.component.html',
  styleUrls: ['./item-additional-details.component.scss'],
})
export class ItemAdditionalDetailsComponent implements OnChanges {
  @Input() item: Rock | Todo | Issue;
  @Input() locale: Locale;

  private readonly store = inject(Store);

  isRepeatingTodo = false;
  showAutomaticallyCreated = false;
  createdDate: Date;
  originalCreatedDate: Date;
  createdBy: string;

  $vm = { language: this.store.select(selectLanguage) };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item) {
      this.createdDate = new Date(parseInt(this.item._id.toString().substring(0, 8), 16) * 1000);
      if (this.item.hasOwnProperty('originalSeriesTodo')) {
        this.isRepeatingTodo = true;
        this.showAutomaticallyCreated = this.item['originalSeriesTodo']._id !== this.item._id;
        this.originalCreatedDate = new Date(
          parseInt(this.item['originalSeriesTodo']._id.toString().substring(0, 8), 16) * 1000
        );
        this.createdBy = this.item['originalSeriesTodo'].createdBy;
      } else {
        this.isRepeatingTodo = false;
        this.showAutomaticallyCreated = false;
        this.createdBy = this.item.createdBy;
      }
    }
  }
}
