import { coerceCssPixelValue, coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectorRef, Directive, EventEmitter, Input, Output } from '@angular/core';

import { TerraErrorStateMatcher } from '../../forms/terra-error-state-matcher';

@Directive({
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
  },
})
export class TerraSelectBaseClass {
  /**
   * Event emitted when the select panel has been toggled
   */
  @Output() readonly openedChange = new EventEmitter<boolean>(false);
  get isSelectOpen(): boolean {
    return this._isSelectOpen;
  }
  protected _isSelectOpen = false;

  /**
   * Event emitted when the selected value changes
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @angular-eslint/no-output-native
  @Output() readonly selectionChange = new EventEmitter<any>();

  /**
   * Placeholder for the trigger if no value is selected
   * @default
   */
  @Input({ required: false }) get placeholder(): string | undefined {
    return this._placeholder;
  }
  set placeholder(value: string | undefined) {
    this._placeholder = value;
    this._changeDetectorRef.markForCheck();
  }
  private _placeholder?: string;

  /**
   * Maximum width of the select panel of options.  If number is provided, pixel units are assumed
   */
  @Input() get maxWidth(): number | string | undefined {
    return this._maxWidth;
  }
  set maxWidth(value: number | string | undefined) {
    // Compensates for maxWidth="12" scenario where 12 is passed as a string
    // This checks if the length of the int and string are the same, if so we parse to an int so
    // that the coerceCSSPixelValue will correctly coerce it to 12px
    if (typeof value === 'string' && parseInt(value).toString().length === value.length) {
      value = parseInt(value);
    }
    this._maxWidth = coerceCssPixelValue(value);
    this._changeDetectorRef.markForCheck();
  }
  protected _maxWidth?: number | string;

  /**
   * Maximum height of the select panel of options.  If number is provided, pixel units are assumed
   */
  @Input() get maxHeight(): number | string | undefined {
    return this._maxHeight;
  }
  set maxHeight(value: number | string | undefined) {
    // Compensates for maxHeight="12" scenario where 12 is passed as a string
    // This checks if the length of the int and string are the same, if so we parse to an int so
    // that the coerceCSSPixelValue will correctly coerce it to 12px
    if (typeof value === 'string' && parseInt(value).toString().length === value.length) {
      value = parseInt(value);
    }
    this._maxHeight = coerceCssPixelValue(value);
    this._changeDetectorRef.markForCheck();
  }
  protected _maxHeight?: number | string;

  /**
   * Function to compare the option values with the selected values. The first argument is a value from an option.
   * The second is a value from the selection. A boolean should be returned.
   * @default (o1: any, o2: any) => o1 === o2
   */
  @Input() get compareWith() {
    return this._compareWith;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set compareWith(fn: (o1: any, o2: any) => boolean) {
    this._compareWith = fn;
    this._changeDetectorRef.markForCheck();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected _compareWith: (optionValue: any, selectionValue: any) => boolean = (optionVal, selectionVal) =>
    optionVal === selectionVal;

  /**
   * Object used to control when error messages are shown
   */
  @Input() get errorStateMatcher() {
    return this._errorStateMatcher;
  }
  set errorStateMatcher(value: TerraErrorStateMatcher) {
    this._errorStateMatcher = value;
    this._changeDetectorRef.markForCheck();
  }
  private _errorStateMatcher: TerraErrorStateMatcher = this._terraErrorStateMatcher;

  /**
   * Optional input to override aria-label
   * @default null
   */
  @Input('aria-label') ariaLabel: string | null = null;
  /**
   * Optional input to override aria-labelledby
   * @default null
   */
  @Input('aria-labelledby') ariaLabelledby: string | null = null;

  /**
   * @ignore
   * Special case for tabindex - using @Attribute causes a race condition with a wrapper component
   * Don't use this as a pattern for most cases
   */
  @Input('tabindex') get tabIndex(): number {
    return this._tabIndex;
  }
  set tabIndex(value: string | number) {
    this._tabIndex = coerceNumberProperty(value);
  }
  protected _tabIndex = 0;

  constructor(
    protected readonly _changeDetectorRef: ChangeDetectorRef,
    protected readonly _terraErrorStateMatcher: TerraErrorStateMatcher
  ) {}
}
