<div class="terra-quick-filter">
  <terra-select
    class="terra-quick-filter__select"
    [formControl]="_hostFormControl.control"
    (openedChange)="_openedChanged()"
    (selectionChange)="_selectionChanged()"
    [multiple]="multiple"
    [disabled]="disabled"
    [maxWidth]="maxWidth"
    [maxHeight]="maxHeight"
    [compareWith]="compareWith"
    [errorStateMatcher]="errorStateMatcher"
    [aria-label]="placeholder || _hostFormControl.control.value?.length > 0 ? null : ariaLabel || null"
    [aria-labelledby]="ariaLabelledby"
    [tabindex]="_tabIndex">
    <terra-select-trigger class="terra-quick-filter__trigger">
      <terra-icon *ngIf="icon" class="terra-quick-filter__icon" [icon]="icon" [size]="20" />
      <div
        class="terra-quick-filter__selected"
        [ngStyle]="{
          width: icon ? 'calc(100% - 48px)' : 'calc(100% - 36px)',
          'min-width': _isValueSelected() ? 'min(40px, 100%)' : '0'
        }">
        <div *ngIf="key" class="terra-quick-filter__key">{{ key }}:</div>
        <div
          *ngIf="!multiple"
          class="terra-quick-filter__value"
          [ngStyle]="{ 'min-width': _isValueSelected() ? '40px' : '0' }">
          {{ (_selectedLabel$ | async) || placeholder }}
        </div>
        <div *ngIf="multiple" class="terra-quick-filter__value--multiple">
          <div class="terra-quick-filter__value" [ngStyle]="{ 'min-width': _isValueSelected() ? '40px' : '0' }">
            {{ (_selectedLabel$ | async) || placeholder }}
          </div>
          <terra-counter *ngIf="_hostFormControl.control.value.length > 1" color="light"
            >+{{ _hostFormControl.control.value.length - 1 }}</terra-counter
          >
        </div>
      </div>
      <terra-icon
        class="terra-quick-filter__caret"
        [icon]="_isSelectOpen ? 'caret-up' : 'caret-down'"
        [size]="16"
        variant="bold" />
    </terra-select-trigger>
    <terra-select-search>
      <ng-content select="terra-search-input" ngProjectAs="terra-search-input" />
    </terra-select-search>
    <ng-content select="terra-option" ngProjectAs="terra-option" />
  </terra-select>
</div>
