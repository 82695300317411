import { DecimalPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep, every as _every } from 'lodash';
import { Observable, Subscription, filter, map, skip, switchMap, tap } from 'rxjs';

import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { MeetingsFacade } from '@ninety/pages/meetings/meetings.facade';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import {
  MeetingAgendaPrintParams,
  PrintApi,
  PrintOptions,
  PrintService,
} from '@ninety/ui/legacy/core/services/print.service';
import { SortingService } from '@ninety/ui/legacy/core/services/sorting.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { DetailType } from '@ninety/ui/legacy/shared//models/_shared/detail-type.enum';
import { MeetingDetailInput } from '@ninety/ui/legacy/shared//models/_shared/detail-view-input';
import { SortingTracker } from '@ninety/ui/legacy/shared//models/_shared/sorting-tracker';
import { Team } from '@ninety/ui/legacy/shared//models/_shared/team';
import { ItemType } from '@ninety/ui/legacy/shared//models/enums/item-type';
import { Sorted } from '@ninety/ui/legacy/shared//models/enums/sorted';
import { Meeting } from '@ninety/ui/legacy/shared//models/meetings/meeting';
import { MeetingSection } from '@ninety/ui/legacy/shared//models/meetings/meeting-section';
import { MeetingType } from '@ninety/ui/legacy/shared//models/meetings/meeting-type.enum';
import { MeetingsResponse } from '@ninety/ui/legacy/shared//models/meetings/meetings-response';
import { selectUserAvatarInfo } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { CurrentUserSelectors, TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

import { MeetingStartDialogComponent } from '../../meeting-start-dialog/meeting-start-dialog.component';

@Component({
  selector: 'ninety-meetings-list',
  templateUrl: './meetings-list.component.html',
  styleUrls: ['./meetings-list.component.scss'],
})
export class MeetingsListComponent implements OnInit, OnDestroy {
  pastMeetings: Meeting[];
  activeMeetings$: Observable<Meeting[]>;
  filteredActiveMeetings: Meeting[];
  filteredActiveMeetingsCopy: Meeting[];
  sortProperties = ['teamName', 'date', 'presenterFullName', 'type'];
  filteredActiveMeetingsSorter: SortingTracker = new SortingTracker(...this.sortProperties);

  // Pagination
  pastMeetingsPageIndex = 0;
  pastMeetingsPageSize = 10;
  activeMeetingsPageIndex = 0;
  activeMeetingsPageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 100];

  numberOfPastMeetings: number;
  numberOfActiveMeetings: number;
  selectedMeeting: Meeting = null;
  teamUsers: { [key: string]: Team } = {};
  deletedMeeting: Meeting;
  meetingType: string;
  loaded = {
    activeMeetings: false,
    pastMeetings: false,
  };

  userTeams$ = this.store.select(CurrentUserSelectors.selectTeams);

  readonly ItemType = ItemType;
  readonly Sorted = Sorted;
  readonly MeetingType = MeetingType;

  private subscriptions = new Subscription();
  selectUserAvatarInfo = selectUserAvatarInfo;
  constructor(
    public filterService: FilterService,
    public meetingService: MeetingService,
    private sortingService: SortingService,
    public spinnerService: SpinnerService,
    public userService: UserService,
    public matDialog: MatDialog,
    private decimalPipe: DecimalPipe,
    public stateService: StateService,
    public errorService: ErrorService,
    private cdr: ChangeDetectorRef,
    private detailService: DetailService<MeetingDetailInput>,
    private printService: PrintService,
    public store: Store,
    private meetingFacade: MeetingsFacade
  ) {}

  ngOnInit() {
    this.filterService.setOptions({ settingsBtn: true, searchInput: false });

    this.subscriptions.add(
      this.filterService.selectedTeamId$.subscribe({
        next: (teamId: string) => {
          if (teamId !== 'all' && !this.meetingService.startingMeeting) {
            this.getMeetings();
          }
        },
      })
    );

    this.subscriptions.add(
      this.filterService.selectedTeamId$
        .pipe(
          // Skip 2 because it's a BehaviorSubject, emits immediately and then gets it's value changed
          // on page load
          skip(2),
          tap(() => this.closeDetailView())
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.filterService.refresh$.subscribe({
        next: () => {
          this.filteredActiveMeetingsSorter = new SortingTracker(...this.sortProperties);
          this.getMeetings();
        },
      })
    );

    this.meetingType = this.getMeetingType();

    this.activeMeetings$ = this.meetingService.activeMeetingsPaged$.pipe(
      filter(r => !!r),
      tap(response => {
        const meetingIds = response.items.map(m => m._id);
        const meetings = response.items.filter(m => !meetingIds.includes(m.previousMeetingId));
        this.numberOfActiveMeetings = response?.totalCount;
        this.filteredActiveMeetings = meetings;
        this.filteredActiveMeetingsCopy = _cloneDeep(meetings);
        this.loaded.activeMeetings = true;
        this.turnOffSpinner();
        this.cdr.markForCheck();
      }),
      map(response => response.items)
    );

    this.registerDetailEvents();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private registerDetailEvents() {
    this.subscriptions.add(
      this.detailService
        .getInputs()
        .pipe(
          tap(data => {
            this.selectedMeeting = data?.meeting;
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.detailService.meetingDelete$.subscribe({
        next: meeting => this.deleteMeeting(meeting),
      })
    );

    this.subscriptions.add(
      this.detailService.meetingSave$.subscribe({
        next: ({ item, changes }) => this.onSaveMeeting(item, changes),
      })
    );
  }

  getMeetings() {
    this.getActiveMeetings();
    this.getPastMeetings();
  }

  getPastMeetings() {
    this.meetingService.getPastMeetings(this.pastMeetingsPageIndex, this.pastMeetingsPageSize).subscribe({
      next: (resp: MeetingsResponse) => {
        if (this.deletedMeeting) {
          this.pastMeetings = [this.deletedMeeting, ...resp.items];
          this.deletedMeeting = null;
        } else {
          this.pastMeetings = resp.items;
        }
        this.pastMeetings = resp.items;
        this.numberOfPastMeetings = resp.totalCount;
        this.loaded.pastMeetings = true;
        this.turnOffSpinner();
      },
    });
  }

  getActiveMeetings(): void {
    this.meetingService.getActiveMeetings(this.activeMeetingsPageIndex, this.activeMeetingsPageSize).subscribe();
  }

  deleteMeeting(meeting: Meeting) {
    this.meetingService
      .deleteMeeting(meeting)
      .pipe(filter(confirmed => confirmed))
      .subscribe({
        next: _ => {
          //first, check active meetings as they are the ones we usually delete
          const index1 = this.filteredActiveMeetings.findIndex(m => m._id === meeting._id);
          const index2 = this.filteredActiveMeetingsCopy.findIndex(m => m._id === meeting._id);
          if (index1 > -1 && index2 > -1) {
            this.filteredActiveMeetings.splice(index1, 1);
            this.filteredActiveMeetingsCopy.splice(index2, 1);
          } else {
            //if not found in active check past
            const index = this.pastMeetings.findIndex(m => m._id === meeting._id);
            if (index > -1) this.pastMeetings.splice(index, 1);
          }

          this.closeDetailView();
          this.turnOffSpinner();
          this.cdr.detectChanges();
        },
      });
  }

  turnOffSpinner() {
    if (_every(this.loaded)) this.spinnerService.stop();
  }

  getMeetingAverageRating(meeting: Meeting): number | string {
    let isNumber = false;
    const average = meeting.userRatings.reduce(
      ((sum, count) => (avg, rating) => {
        if (rating.rating != null) {
          isNumber = true;
          return (sum += +rating.rating) / ++count;
        }
        return avg;
      })(0, 0),
      0
    );
    return isNumber ? this.decimalPipe.transform(average as number, '1.0-2') : 'N/A';
  }

  clearSelectedMeeting(): void {
    this.selectedMeeting = null;
  }

  onSelectMeeting(meeting: Meeting) {
    const meetingId = meeting.previousMeetingId || meeting._id;

    const navigating = this.detailService.open({ pathParts: [DetailType.meeting, meetingId] });

    this.spinnerService.spinWhile(navigating).subscribe({
      next: () => this.cdr.markForCheck(),
    });
  }

  closeDetailView() {
    this.detailService.close().subscribe();
  }

  onSaveMeeting(meeting: Meeting, changes: Partial<Meeting>): void {
    const list = meeting.inProgress ? this.filteredActiveMeetings : this.pastMeetings;
    const index = list.findIndex(m => m._id === meeting._id);
    if (index > -1) {
      list[index] = Object.assign({}, list[index], changes);

      this.cdr.markForCheck();
    }
  }

  openStartMeetingDialog() {
    this.matDialog
      .open(MeetingStartDialogComponent)
      .afterClosed()
      .pipe(
        filter(data => !!data),
        switchMap(data => this.meetingService.createNewMeeting(data))
      )
      .subscribe();
  }

  onPastMeetingsPageEvent($event: PageEvent) {
    this.closeDetailView();
    this.pastMeetingsPageIndex = $event.pageIndex;
    this.pastMeetingsPageSize = $event.pageSize;
    this.getPastMeetings();
  }

  onActiveMeetingsPageEvent($event: PageEvent) {
    this.closeDetailView();
    this.activeMeetingsPageIndex = $event.pageIndex;
    this.activeMeetingsPageSize = $event.pageSize;
    this.getActiveMeetings();
  }

  getElapsedTime(meeting: Meeting): number {
    if (meeting.sections)
      return Object.values(meeting.sections).reduce(
        (sum: number, section: MeetingSection) => sum + (section.elapsedTime || 0),
        0
      );
    if (meeting.elapsedTimes)
      return Object.values(meeting.elapsedTimes).reduce((sum: number, section) => sum + section, 0);
    if (meeting.elapsedTime) return meeting.elapsedTime;
  }

  printAgenda() {
    const sortOptions = this.meetingFacade.getPrintMeetingAgendaSortOptions();
    const teamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);

    this.printService
      .openPdf<MeetingAgendaPrintParams>(PrintApi.meetingAgenda, {
        teamId,
        meetingType: this.stateService.meetingType,
        ...(sortOptions ? { sortOptions } : null),
        printOptions: new PrintOptions(),
      })
      .subscribe();
  }

  getMeetingType(): string {
    switch (this.stateService.meetingType) {
      case MeetingType.quarterly:
        return this.stateService.language.meeting.quarterlySessions;
      case MeetingType.annualDayOne:
      case MeetingType.annualDayTwo:
        return this.stateService.language.meeting.annualSessions;
      case MeetingType.weekly:
        return this.stateService.language.meeting.levelTens;
      default:
        return this.stateService.language.meeting.items;
    }
  }

  meetingName(meeting: Meeting): string {
    switch (meeting.type) {
      case MeetingType.focusDay:
        return this.stateService.language.meeting.focusDay;
      case MeetingType.visionBuildingDayOne:
        return this.stateService.language.meeting.visionBuildingDayOne;
      case MeetingType.visionBuildingDayTwo:
        return this.stateService.language.meeting.visionBuildingDayTwo;
      case MeetingType.custom:
        return meeting.name;
      default:
        return 'Custom';
    }
  }

  sortActiveMeetings(prop: string) {
    SortingService.changeSortDirection(this.filteredActiveMeetingsSorter, prop);
    if (this.filteredActiveMeetingsSorter[prop] !== Sorted.false) {
      if (this.sortProperties.includes(prop)) {
        this.sortingService
          .sortAlphabetically(this.filteredActiveMeetings, prop, this.filteredActiveMeetingsSorter[prop])
          .subscribe({
            next: sorted => {
              this.filteredActiveMeetings = sorted;
            },
          });
      }
    } else {
      this.filteredActiveMeetings = _cloneDeep(this.filteredActiveMeetingsCopy);
    }
  }
}
