import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { EmptyListViewModule } from '@ninety/_layouts/components/empty-list-view/empty-list-view.module';
import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { PaginationComponent } from '@ninety/ui/legacy/components/pagination/pagination.component';
import { WidgetSkeletonComponent } from '@ninety/ui/legacy/components/skeletons/compositions/widget-skeleton.component';

import { MeetingsTableComponent } from '../../meetings-table/meetings-table.component';
import { MeetingsFacade } from '../../meetings.facade';

@Component({
  selector: 'ninety-past-meetings',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    MeetingsTableComponent,
    WidgetSkeletonComponent,
    CardModule,
    EmptyListViewModule,
    PaginationComponent,
    MaterialMdcModule,
  ],
  templateUrl: './past-meetings.component.html',
  styles: [
    `
      @use 'terra';

      .meetings-v2-card-wrp {
        margin-bottom: 24px;
      }

      .card-wrp {
        padding: 24px;
        padding-bottom: 0;

        ninety-card {
          max-width: 1300px;
          margin: auto;
        }

        .description {
          display: block;
          color: terra.color('text', 'light');
          line-height: 200%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastMeetingsTableComponent {
  facade = inject(MeetingsFacade);

  readonly WidgetTemplateType = WidgetTemplateType;

  LOADING_SKELETON_ROWS = 2;
}
