<h1 mat-dialog-title>Print Agenda</h1>
<div class="header">Please select what agenda you would like to be printed</div>
<ninety-button flat icon class="close-dialog-btn" (click)="dialogRef.close()">
  <terra-icon icon="x" />
</ninety-button>
<terra-divider height="short" />
<mat-dialog-content>
  <div class="content">
    <div *ngrxLet="language$ as language">
      <div class="menu-item">
        <terra-checkbox
          class="project-checkbox"
          ninetyStopPropagation
          aria-labelledby="meeting-level-ten-label"
          (change)="printAgenda(MeetingAgendaType.weekly)"
          [ngModel]="selectedMeetingType === MeetingAgendaType.weekly">
          <div class="label-text-wrp">
            <terra-icon icon="calendar" />
            <span id="meeting-level-ten-label">{{ language.meeting.levelTen }}</span>
          </div>
        </terra-checkbox>
      </div>
      <div class="menu-item">
        <terra-checkbox
          class="project-checkbox"
          ninetyStopPropagation
          aria-labelledby="meeting-quarterly-session-label"
          (change)="printAgenda(MeetingAgendaType.quarterly)"
          [ngModel]="selectedMeetingType === MeetingAgendaType.quarterly">
          <div class="label-text-wrp">
            <terra-icon icon="calendar" />
            <span id="meeting-quarterly-session-label" class="flex-2">{{ language.meeting.quarterlySession }}</span>
          </div>
        </terra-checkbox>
      </div>
      <div class="menu-item">
        <terra-checkbox
          class="project-checkbox"
          ninetyStopPropagation
          aria-labelledby="meeting-annual-session-label"
          (change)="printAgenda(MeetingAgendaType.annualDayOne)"
          [ngModel]="selectedMeetingType === MeetingAgendaType.annualDayOne">
          <div class="label-text-wrp">
            <terra-icon icon="calendar" />
            <span id="meeting-annual-session-label" class="flex-2">{{ language.meeting.annualSession }}</span>
          </div>
        </terra-checkbox>
      </div>
      <div *ngFor="let customMeetingAgenda of customMeetingSettings$ | async; let i = index">
        <div class="menu-item custom-agenda">
          <terra-checkbox
            class="project-checkbox"
            ninetyStopPropagation
            [aria-labelledby]="'custom-meeting-agenda-label' + i"
            (change)="printAgenda(MeetingAgendaType.custom, customMeetingAgenda.id)"
            [ngModel]="selectedCustomAgendaId === customMeetingAgenda.id">
            <div class="label-text-wrp">
              <terra-icon icon="calendar" />
              <span [id]="'custom-meeting-agenda-label' + i" class="flex-2">{{ customMeetingAgenda.displayText }}</span>
            </div>
          </terra-checkbox>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<terra-divider height="short" />
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <ninety-button stroked mat-dialog-close>Cancel</ninety-button>
</mat-dialog-actions>
