import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { catchError, concatMap, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';

import { FilterService, StateService, UserService } from '../../../_core';
import { AuxiliaryRouterOutletService } from '../../../_core/services/auxiliary-router-outlet.service';
import { SessionStorageService } from '../../../_core/services/storage.service';
import { TeamsApiService } from '../../../_core/services/team-api.service';
import { DetailType } from '../../../_shared';
import { MeetingAgendaType } from '../../../_shared/index';
import { CompanyActions } from '../../app-global/company/company-state.actions';
import { SpinnerActions } from '../../app-global/spinner/spinner-state.actions';
import { appActions, CurrentUserSelectors, recurseRouteToFindParameter } from '../../index';
import { selectCurrentUser } from '../users/users-state.selectors';

import { teamAll, teamNone } from './api/team-list.model';
import { TeamListStateActions, UserTeamsActions } from './team-list-state.actions';
import { TeamSelectors } from './team-list-state.selectors';

@Injectable()
export class TeamListStateEffects {
  constructor(
    private action$: Actions,
    private store: Store,
    private teamApiService: TeamsApiService,
    private auxiliaryRouterOutletService: AuxiliaryRouterOutletService,
    private sessionStorageService: SessionStorageService,
    private stateService: StateService,
    private userService: UserService,
    private filterService: FilterService
  ) {}

  routerNavigatedToTeamDetail$ = createEffect(() =>
    this.action$.pipe(
      ofType(ROUTER_NAVIGATED),
      // FYI: selectRouteParam('id') doesn't work with auxiliary routes, using a more manual method to get the Id
      filter((action: RouterNavigationAction) =>
        action.payload.routerState.url.toLowerCase().includes('detail:detail/team')
      ),
      map((action: RouterNavigationAction) => recurseRouteToFindParameter('id', action.payload.routerState)),
      filter(id => !!id),
      map(teamId => TeamListStateActions.setEditedTeamId({ teamId }))
    )
  );

  getArchivedTeams$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.getArchivedTeamList),
      concatLatestFrom(() => this.store.select(TeamSelectors.selectArchivedTeamsLoaded)),
      filter(([_, loaded]) => !loaded),
      switchMap(() =>
        this.teamApiService.getArchivedTeamsLite().pipe(
          map(teams => TeamListStateActions.getArchivedTeamListSuccess({ archivedTeams: teams })),
          catchError((error: unknown) => of(TeamListStateActions.getArchivedTeamListError({ error })))
        )
      )
    )
  );

  setInitialTeam$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.getTeamListSuccess),
      concatLatestFrom(() => this.store.select(selectCurrentUser).pipe(filter(cu => !!cu))),
      map(([action, currentUser]) => ({ teams: action.teams, currentUser })),
      map(({ teams, currentUser }) => {
        // set default team as selected team when logging in, as long as the user still belongs to that team
        const sessionTeamId = this.sessionStorageService.get('lastAccessedTeamId');
        let initialTeamId = sessionTeamId ?? currentUser.settings.defaultTeamId;
        const userStillOnTeam = currentUser.teams.some(t => t.teamId === initialTeamId);
        if (!userStillOnTeam && currentUser?.teams.length > 0) {
          initialTeamId = currentUser.teams[0].teamId;
        }

        const initialTeam = teams.find(team => team._id === initialTeamId);
        if (initialTeam) {
          this.sessionStorageService.set('lastAccessedTeamId', initialTeam._id);
        }
        return TeamListStateActions.getSelectedTeam({ id: initialTeamId });
      })
    )
  );

  changeSelectedTeam$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.changeFilterBarTeam),
      concatLatestFrom(() => this.store.select(TeamSelectors.selectFilterBarTeamId)),
      filter(([{ id }, selectedTeamId]) => id !== selectedTeamId),
      map(([{ id }]) => TeamListStateActions.getSelectedTeam({ id }))
    )
  );

  getSelectedTeam$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.getSelectedTeam),
      concatLatestFrom(() => this.store.select(CurrentUserSelectors.selectTeamIds)),
      switchMap(([{ id }, currentUserTeamIds]) => {
        if (id === teamAll._id) return of(TeamListStateActions.getSelectedTeamSuccess({ team: teamAll }));
        if (id === teamNone._id) return of(TeamListStateActions.getSelectedTeamSuccess({ team: teamNone }));

        const noTeams = currentUserTeamIds.length === 0;
        if (noTeams) return of(TeamListStateActions.getSelectedTeamSuccess({ team: teamNone }));

        if (!id || !currentUserTeamIds.find(teamId => teamId === id)) {
          // If the team is not in the user's teams or the Id is null, default to the first team in the list
          return of(TeamListStateActions.getSelectedTeam({ id: currentUserTeamIds[0] }));
        }

        return this.teamApiService.getTeamById(id).pipe(
          map(team => TeamListStateActions.getSelectedTeamSuccess({ team })),
          catchError((error: unknown) => of(TeamListStateActions.getSelectedTeamError({ error })))
        );
      })
    )
  );

  getSelectedTeamSuccess_UpdateLastTeam$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(TeamListStateActions.getSelectedTeamSuccess),
        filter(({ team }) => ![teamAll._id, teamNone._id].includes(team._id)), // exclude all and none
        tap(({ team }) => {
          this.sessionStorageService.set('lastAccessedTeamId', team._id);

          // This may run before state service is initilized, if so just return
          if (!this.stateService?.currentCompanyUser$?.value) return;

          // ToDo: Convert to User Store when the store is ready
          if (this.stateService.currentCompanyUser$.value.lastAccessedTeamId !== team._id) {
            this.stateService.currentCompanyUser$.value.lastAccessedTeamId = team._id;
            this.userService.update({ lastAccessedTeamId: team._id }).subscribe();
          }
        })
      ),
    { dispatch: false }
  );

  updateOne$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.updateOne),
      switchMap(({ id, update }) =>
        this.teamApiService.update(id, update).pipe(
          map(() => TeamListStateActions.updateOneSuccess({ id, update })),
          catchError((error: unknown) => of(TeamListStateActions.updateOneError({ error })))
        )
      )
    )
  );

  // Duplicate of above, but for props that are not on the TeamListModel.  Might be a better way to do this.
  updateTeam$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.updateTeam),
      switchMap(({ id, update }) =>
        this.teamApiService.update(id, update).pipe(
          map(() => TeamListStateActions.updateOneSuccess({ id, update })),
          catchError((error: unknown) => of(TeamListStateActions.updateOneError({ error })))
        )
      )
    )
  );

  filterService_optionsChanged$ = createEffect(() =>
    this.filterService.options$.pipe(
      map(options => options.allTeamsOption),
      distinctUntilChanged(),
      concatLatestFrom(() => [
        this.store.select(TeamSelectors.selectFilterBarTeamId),
        this.store.select(TeamSelectors.selectAll),
        this.store.select(selectCurrentUser),
      ]),
      map(([allTeamsOption, filterBarTeamId, teams, user]) => {
        // All Teams is true change the selected Team to All
        if (allTeamsOption) return TeamListStateActions.changeFilterBarTeam({ id: teamAll._id });

        // All Teams is false and the current filter bar team is not All, do nothing we already have the team
        if (filterBarTeamId !== teamAll._id) return appActions.noop();

        // Run through the logic to set the filter bar team to the last accessed team, which will load that team from the server
        const teamId = user ? user.lastAccessedTeamId : window.sessionStorage.getItem('lastAccessedTeamId') || null;
        const lastTeam = teamId ? teams.find(t => t._id === teamId) : null;
        const teamToSelect = lastTeam || teams[0] || teamNone;

        return TeamListStateActions.changeFilterBarTeam({ id: teamToSelect._id });
      })
    )
  );

  closeDetailView$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(UserTeamsActions.closeDetailView),
        switchMap(() => this.auxiliaryRouterOutletService.close())
      ),
    { dispatch: false }
  );

  openDetailView$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(TeamListStateActions.openTeamDetail),
        switchMap(({ teamId }) => {
          if (!teamId) {
            this.auxiliaryRouterOutletService.close();
          }
          return this.auxiliaryRouterOutletService.open({ pathParts: [DetailType.team, teamId] });
        })
      ),
    { dispatch: false }
  );

  updateTeamSettings$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.updateSettings),
      concatMap(({ teamId, update }) =>
        this.teamApiService.updateSettings(teamId, update).pipe(
          map(() => TeamListStateActions.updateSettingsSuccess({ teamId, update })),
          catchError((error: unknown) => of(TeamListStateActions.updateSettingsError({ error })))
        )
      )
    )
  );

  pushCustomAgenda$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.pushCustomAgenda),
      concatMap(({ agendaRequest }) => {
        const request =
          agendaRequest.agendaType === MeetingAgendaType.custom
            ? this.teamApiService.pushCustomAgenda(agendaRequest.teamId, agendaRequest.agendaId)
            : this.teamApiService.pushAgendaByType(agendaRequest.teamId, agendaRequest.agendaType);

        return request.pipe(
          map(() => TeamListStateActions.pushCustomAgendaSuccess({ teamId: agendaRequest.teamId })),
          catchError((error: unknown) => of(TeamListStateActions.pushCustomAgendaError({ error })))
        );
      })
    )
  );

  pushCustomAgendaSuccess_RefreshCompany$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.pushCustomAgendaSuccess),
      map(() => CompanyActions.refreshCurrentCompanyFromDb())
    )
  );

  pushCustomAgendaSuccess_RefreshTeam$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(TeamListStateActions.pushCustomAgendaSuccess),
        map(({ teamId }) => TeamListStateActions.getSelectedTeam({ id: teamId }))
      ),
    { dispatch: false }
  );

  updateTeamMeetingAgenda$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.updateTeamMeetingAgenda),
      concatMap(({ update }) =>
        this.teamApiService.updateSettings(update.teamId, { [update.agendaType]: update.teamAgenda }).pipe(
          map(() => TeamListStateActions.updateTeamMeetingAgendaSuccess({ update })),
          catchError((error: unknown) => of(TeamListStateActions.updateTeamMeetingAgendaError({ error })))
        )
      )
    )
  );

  deleteCustomAgenda$ = createEffect(() =>
    this.action$.pipe(
      ofType(TeamListStateActions.deleteCustomAgenda),
      concatMap(({ teamId, agendaId }) =>
        this.teamApiService.deleteCustomAgenda(teamId, agendaId).pipe(
          map(() => TeamListStateActions.deleteCustomAgendaSuccess({ teamId, agendaId })),
          catchError((error: unknown) => of(TeamListStateActions.deleteCustomAgendaError({ error })))
        )
      )
    )
  );

  startSpinner$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        TeamListStateActions.pushCustomAgenda,
        TeamListStateActions.updateTeamMeetingAgenda,
        TeamListStateActions.deleteCustomAgenda
      ),
      map(action => SpinnerActions.startPrimary({ source: action.type }))
    )
  );

  stopSpinner$ = createEffect(() =>
    this.action$.pipe(
      ofType(
        TeamListStateActions.pushCustomAgendaSuccess,
        TeamListStateActions.pushCustomAgendaError,
        TeamListStateActions.updateTeamMeetingAgendaSuccess,
        TeamListStateActions.updateTeamMeetingAgendaError,
        TeamListStateActions.deleteCustomAgendaSuccess,
        TeamListStateActions.deleteCustomAgendaError
      ),
      map(action => SpinnerActions.stopPrimary({ source: action.type }))
    )
  );
}
