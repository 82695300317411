import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'ninety-cm-popover',
  standalone: true,
  exportAs: 'ninetyCmPopover',
  imports: [CommonModule, OverlayModule],
  templateUrl: './cm-popover.component.html',
  styleUrls: ['./cm-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NinetyCmPopoverComponent {
  @ViewChild('popover', { read: TemplateRef, static: true }) private _popover!: TemplateRef<HTMLElement>;

  private _popoverHasHover = false;
  private _overlayRef: OverlayRef | undefined = undefined;

  constructor(private _overlay: Overlay, private _viewContainerRef: ViewContainerRef) {}

  /**
   * Shows the popover
   * @param connectedElement What the popover should be connected to
   */
  showPopover(connectedElement: ElementRef<unknown>): void {
    if (!this._overlayRef) {
      this._overlayRef = this._overlay.create({
        hasBackdrop: false,
        width: 360,
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(connectedElement)
          .withLockedPosition()
          .withPositions([
            {
              originX: 'start',
              originY: 'bottom',
              overlayX: 'start',
              overlayY: 'top',
            },
            {
              originX: 'end',
              originY: 'bottom',
              overlayX: 'end',
              overlayY: 'top',
            },
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
            },
            {
              originX: 'end',
              originY: 'top',
              overlayX: 'end',
              overlayY: 'bottom',
            },
          ]),
      });
    }

    if (this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
    const portal = new TemplatePortal(this._popover, this._viewContainerRef);

    this._overlayRef.attach(portal);
  }

  /**
   * Hides the popover
   */
  hidePopover(): void {
    if (!this._popoverHasHover) {
      this._overlayRef?.detach();
    }
  }

  protected _mouseEntered() {
    this._popoverHasHover = true;
  }

  protected _mouseLeft() {
    this._popoverHasHover = false;
    this.hidePopover();
  }
}
