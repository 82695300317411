import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { mergeMap, Observable, of, switchMap, tap } from 'rxjs';

import { ConfirmDialogComponent } from '../../_shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../_shared/components/_mdc-migration/confirm-dialog/models/confirm-dialog-data';

const PENDING_CHANGES_MSG = 'No changes have been saved.';

/**
 * Call this function to register trigger the browser confirmation dialog on page refresh.
 *
 * Note: Only add unload handlers conditionally
 * Read More: https://web.dev/bfcache/#only-add-beforeunload-listeners-conditionally
 *
 * Use Case(s):
 *  - Detail component lifecycle hooks
 *  - Ngrx effects for entering/exiting an edit state
 *
 * @example
 * ```ts
 * class MyDetailComponent implements OnInit {
 *  ngOnInit() {
 *    registerUnloadHandler();
 *  }
 *
 *  ngOnDestroy() {
 *    unregisterUnloadHandler();
 *  }
 * }
 * ```
 */
function pendingChangesBeforeUnloadHandler(event: BeforeUnloadEvent) {
  event.preventDefault();

  // Display message to user on refresh + url bar change.
  return (event.returnValue = PENDING_CHANGES_MSG);
}

/**
 * Call this function to register the @see {pendingChangesBeforeUnloadHandler}. This must be registered indepentently
 * from the @see {PendingChangesGuard}, that guard only gets created the moment Angular router registers a navigation
 * event for a route decorated with `canDeactivate`.
 *
 * Uses:
 *  - Detail component ngOnInit methods
 *  - Ngrx effect for entering an edit state
 */
export const registerWindowBeforeUnloadConfirmDialog = () =>
  window.addEventListener('beforeunload', pendingChangesBeforeUnloadHandler, { capture: true });

/**
 * Call this function when you no longer need to account for data loss on page refresh.
 *
 * Note: You need to pass the same function reference that was passed in `window.addEventListener` to correctly
 * unregister the function.
 *
 * Uses:
 *  - Detail component ngOnDestroy methods
 *  - Ngrx effect for exiting an edit state
 */
export const unregisterWindowBeforeUnloadConfirmDialog = () =>
  window.removeEventListener('beforeunload', pendingChangesBeforeUnloadHandler, { capture: true });

/**
 * Implement this interface on a component for the route guard to use the
 * component to determine whether the user can navigate away from the
 * page.
 */
export interface ComponentCanDeactivate {
  /**
   * Return whether the user can leave the route.
   *
   * NOTE: this warning method will only fire when navigating within angular,
   * which is the context the route guards fire in.  External links & page
   * refreshes will not trigger this.
   */
  canDeactivate(): Observable<boolean>;
}

/**
 * Add this guard to a page route and implement ComponentCanDeactivate on the
 * page component to present a prompt to the user about potential data loss,
 * giving them the option to cancel the navigation and finish their work,
 * or continue anyways.
 *
 * This does not handle browser events like f5/refresh.
 * For that, use @see {registerUnloadHandler} & @see {unregisterUnloadHandler}
 *
 * Reference:
 * https://stackoverflow.com/a/41187919
 */
@Injectable({
  providedIn: 'root',
})
export class PendingChangesGuard {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    return component.canDeactivate().pipe(
      switchMap(canDeactivate => {
        if (canDeactivate) return of(canDeactivate);

        return this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
              title: 'Unsaved changes',
              message: ` If you navigate away you will lose your unsaved changes. Are you sure you want to continue?`,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
            },
          })
          .afterClosed()
          .pipe(
            mergeMap((confirmed: true | undefined) => of(!!confirmed)),
            tap(confirmed => {
              // Make sure the URL doesn't update if they cancelled
              // Taken from https://stackoverflow.com/a/62621855
              if (!confirmed) {
                history.pushState(null, '', '');
              }
            })
          );
      })
    );
  }
}
