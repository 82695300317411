export namespace DashboardConstants {
  /**
   * Cell height has been optimized to ensure all 4 main widgets are visible, above the fold, without scrolling on the #1 90
   * screen size (1920 x 1080). https://traxion.atlassian.net/browse/DEV-12691
   */
  export const CELL_HEIGHT = 437;

  /**
   * The breakpoint at which the grid should switch to a single column layout. Used by the default Gridstack responsive behavior
   */
  export const ONE_COL_BREAKPOINT = 975;

  /** The number of columns in the grid */
  export const COLUMN_COUNT = 6;

  /**
   * Defines how one column works - bellow `w` of breakpoint, create `c` columns. When no breakpoint is applied, `columnMax` is
   * used.
   */
  export const COLUMN_OPTS = { breakpoints: [{ w: ONE_COL_BREAKPOINT, c: 1 }], columnMax: COLUMN_COUNT };
}
