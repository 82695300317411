<ninety-card
  *ngrxLet="meetingStatus$ as meeting"
  class="card-container"
  data-cy="active-meeting-card"
  [class.active]="meeting?.inProgress === true"
  [class.empty]="meeting === null"
  [class.suspended]="meeting?.paused === true">
  <ng-container *ngIf="meeting; then activeMeetings; else noActiveMeetings"> </ng-container>
</ninety-card>

<ng-template #activeMeetings>
  <div
    class="meeting-card"
    *ngrxLet="{
      meeting: meetingStatus$,
      team: team$,
      meetingName: meetingName$,
      language: language$,
      loading: loading$,
      currentUserIsManageeOrAbove: currentUserIsManageeOrAbove$,
      meetingAttendees: meetingAttendees$,
    } as vm">
    <span class="terra-title-small-light meeting-status">
      {{ vm.language.meeting.item }} {{ vm.meeting?.paused ? 'suspended' : 'in progress' }}
    </span>
    <span class="terra-headline">
      <span class="team">{{ vm.team?.name }}: </span>
      <span class="meeting-name">{{ vm.meetingName }}</span>
    </span>
    <div class="meeting-attendees" [class.no-attendees]="vm.meetingAttendees.attendees.length === 0">
      <ng-container *ngFor="let userId of vm.meetingAttendees.attendees">
        <terra-avatar
          *ngrxLet="userId | userIdToAvatar as user"
          class="meeting-user"
          [initials]="user.userInitials"
          [src]="user.pictureURL"
          [interactive]="true"
          [ninetyContactCardHover]="userId" />
      </ng-container>
      <terra-avatar
        *ngIf="vm.meetingAttendees.nonVisibleAttendees.length"
        class="meeting-user"
        [initials]="'+' + vm.meetingAttendees.nonVisibleAttendees.length"
        matTooltipClass="ninety-tooltip multiline-tooltip"
        matTooltipPosition="above"
        [matTooltip]="vm.meetingAttendees.nonVisibleAttendees?.join(', ')" />
    </div>
    <span class="button-row">
      <ninety-button
        primary
        filled
        (click)="joinMeeting()"
        *ngIf="vm.meeting?.inProgress"
        [disabled]="vm.loading"
        class="long-button"
        id="join-meeting-btn"
        data-cy="join-meeting-btn">
        <span>Join {{ vm.language.meeting.item }}</span>
      </ninety-button>
      <ninety-button
        stroked
        primary
        (click)="resumeActiveMeeting()"
        [disabled]="vm.loading"
        id="resume-meeting-btn"
        data-cy="resume-meeting-btn"
        *ngIf="vm.currentUserIsManageeOrAbove && vm.meeting?.paused">
        <terra-icon icon="reset" size="20" />
        <span>Resume {{ vm.language.meeting.item }}</span>
      </ninety-button>
      <ninety-button
        filled
        negative
        (click)="openDeleteMeetingDialog()"
        [disabled]="vm.loading"
        id="delete-meeting-btn"
        data-cy="delete-meeting-btn"
        *ngIf="currentUserIsManageeOrAbove$ | async">
        <terra-icon icon="delete" size="20" />
        <span>Delete {{ vm.language.meeting.item }}</span>
      </ninety-button>
    </span>
    <span class="inverted section-name" *ngIf="vm.meeting?.inProgress">
      Currently on:
      <span class="inverted-heavy"> {{ vm.meeting.currentSection.name }}</span>
    </span>
  </div>
</ng-template>

<ng-template #noActiveMeetings>
  <ng-container
    *ngrxLet="{
      team: team$,
      language: language$,
      loading: loading$,
      currentUserIsManageeOrAbove: currentUserIsManageeOrAbove$,
      meetingSchedulingFlag: meetingSchedulingFlag$,
      teamHasNonObserverUsers: teamHasNonObserverUsers$,
      weeklyMeetingsOnlyFeature: weeklyMeetingsOnlyFeature$,
    } as vm">
    <div class="meeting-card">
      <span class="team">{{ vm.team?.name }}</span>
      <span class="title">No {{ vm.language.meeting.items }} in progress</span>
      <span class="button-row">
        <ninety-button
          *ngIf="vm.currentUserIsManageeOrAbove && vm.weeklyMeetingsOnlyFeature"
          primary
          filled
          class="long-button"
          id="start-weekly-meeting-btn"
          (click)="startWeeklyMeeting()"
          data-cy="start-weekly-meeting-btn"
          [disabled]="vm.loading || !vm.teamHasNonObserverUsers">
          <span>Start a {{ vm.language.meeting.levelTen }}</span>
        </ninety-button>

        <ninety-button
          *ngIf="vm.currentUserIsManageeOrAbove && !vm.weeklyMeetingsOnlyFeature"
          primary
          filled
          class="long-button"
          id="start-meeting-btn"
          data-cy="start-meeting-btn"
          (click)="openStartMeetingDialog()"
          [disabled]="vm.loading || !vm.teamHasNonObserverUsers">
          <span>Start a {{ vm.language.meeting.item }}</span>
          <terra-icon icon="caret-down" variant="bold" size="16" />
        </ninety-button>

        <ninety-button
          *ngIf="vm.currentUserIsManageeOrAbove && vm.meetingSchedulingFlag"
          primary
          stroked
          class="long-button"
          id="schedule-meeting-btn"
          data-cy="schedule-meeting-btn"
          (click)="openScheduleMeetingDialog()"
          [disabled]="vm.loading || !vm.teamHasNonObserverUsers">
          <terra-icon icon="calendar" size="16" />
          <span>Schedule a {{ vm.language.meeting.item }}</span>
        </ninety-button>
      </span>
    </div>
  </ng-container>
</ng-template>
