import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { selectFeatureEnabled } from '@ninety/ui/legacy/state/index';

@Component({
  selector: 'ninety-item-type-select',
  templateUrl: './item-type-select.component.html',
  styleUrls: ['./item-type-select.component.scss'],
})
export class ItemTypeSelectComponent {
  @Input() itemType: ItemType;
  @Input() disabled = false;
  @Input() showCascadedOption = true;

  @Output() itemTypeChange = new EventEmitter<ItemType>();

  cascadingMessagesFeature$ = this.store.select(selectFeatureEnabled(NinetyFeatures.cascadingMessages));

  readonly itemTypeEnum = ItemType;

  constructor(public stateService: StateService, public meetingService: MeetingService, private store: Store) {}

  onSelection(itemType: ItemType) {
    this.itemType = itemType;
    this.itemTypeChange.emit(itemType);
  }
}
