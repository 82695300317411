import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap } from 'rxjs';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { GuideSelectors } from '@ninety/getting-started/guide/_state/guide.selectors';
import { Phase } from '@ninety/getting-started/guide/models/phase';
import { GettingStartedService } from '@ninety/getting-started/guide/services/getting-started.service';
import { MazActions } from '@ninety/getting-started/maz/_state/maz.actions';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { UsersStateActions } from '@ninety/ui/legacy/state/app-entities/users/users-state.actions';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { NavMenuIntercomActions } from '@ninety/ui/legacy/state/index';

@Injectable()
export class GuideEffects {
  getGuideInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.init),
      switchMap(() =>
        this.gettingStartedService.getGuideInfo().pipe(map(guideInfo => GuideActions.setGuideInfo({ guideInfo })))
      )
    )
  );

  getPhases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.init, GuideActions.getPhasesAndCompletionPercentage),
      switchMap(() =>
        this.gettingStartedService.getPhases().pipe(
          map(({ phases, completion_percentage: completionPercentage }) =>
            GuideActions.setPhasesAndCompletionPercentage({
              phases: phases.map((phase, i) =>
                setPhaseComputedProperties(phase, phases[i - 1] && !phases[i - 1].completed)
              ),
              completionPercentage,
            })
          )
        )
      )
    )
  );

  /** after getting phases, check if the guide has been viewed before and if not, open it automatically */
  checkGuideViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.setPhasesAndCompletionPercentage),
      concatLatestFrom(() => this.store.select(GuideSelectors.selectGuideViewed)),
      filter(([_, guideViewed]) => !guideViewed),
      map(() => GuideActions.forceOpenGuide())
    )
  );

  /** we only open the guide automatically once  */
  afterForceOpeningGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.forceOpenGuide),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      map(([_, { _id: userId }]) => UsersStateActions.update({ userId, update: { guideViewed: true } }))
    )
  );

  /** this will be littered around the app at various places to check if the completion status changed */
  checkCompletionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.checkCompletionStatus),
      /** very important to only run this if the guide is enabled */
      concatLatestFrom(() => [
        this.store.select(GuideSelectors.selectIsGuideEnabled),
        this.store.select(GuideSelectors.selectCompletionPercentage),
      ]),
      filter(([_, enabled, completionPercentage]) => enabled && completionPercentage < 100),
      map(() => GuideActions.getPhasesAndCompletionPercentage())
    )
  );

  dismissGuide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.dismissGuide),
      switchMap(() =>
        this.dialog
          .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
            data: {
              title: 'You did it!',
              message:
                "You've unlocked your company's full potential!  " +
                "You can still access the resources at <a href='https://ninety.io/library' target='_blank'>www.ninety.io/library</a>.  " +
                'Would you like to dismiss the onboarding guide permanently?',
              confirmButtonText: 'Dismiss',
              cancelButtonText: 'No',
            },
          })
          .afterClosed()
          .pipe(
            filter(result => result),
            map(() => GuideActions.dismissGuideConfirmed())
          )
      )
    )
  );

  dismissGuideConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.dismissGuideConfirmed),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      map(([_, { _id: userId }]) => UsersStateActions.update({ userId, update: { guideDismissed: true } }))
    )
  );

  openResourceLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.openResourceLink),
      /** open the link in a new tab */
      tap(({ url }) => window.open(url, '_blank')),
      /** sometimes opening a link can trigger a completion status change */
      switchMap(({ url }) =>
        this.gettingStartedService.notifyResourceLinkOpened(url).pipe(map(() => GuideActions.checkCompletionStatus()))
      )
    )
  );

  somethingFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.somethingFailed, MazActions.failedToReceiveMessageFromMaz),
      map(e => NotificationActions.notifyError(e))
    )
  );

  /** Page Tracking */
  pageViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.pageViewed),
      concatLatestFrom(() => this.store.select(selectCurrentUser)),
      /** only track page views if the guide is not enabled and the user hasn't viewed the page before, second part is subject to change */
      filter(([{ page }, { guideEnabled, pageViews }]) => guideEnabled && !pageViews?.[page]),
      map(([{ page }, { _id: userId, pageViews = {} }]) =>
        UsersStateActions.update({ userId, update: { pageViews: { ...pageViews, [page]: 1 } } })
      )
    )
  );

  closeMazAndOpenIntercom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GuideActions.closeMazAndOpenIntercom),
      map(() => NavMenuIntercomActions.showMessenger())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private gettingStartedService: GettingStartedService,
    private dialog: MatDialog
  ) {}
}

function setPhaseComputedProperties(phase: Phase, locked: boolean): Phase {
  const stepsCompleted = phase.steps.filter(s => s.completed).length;
  return {
    ...phase,
    locked,
    stepsCompleted,
    percentageComplete: (stepsCompleted / phase.steps.length) * 100,
  };
}
