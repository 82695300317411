<div class="created-stamp muted" *ngrxLet="$vm as vm">
  <div class="flex1 archived-date">
    <small *ngIf="item.archived">
      Archived on
      {{ item.archivedDate ? (item.archivedDate | date : 'fullDate' : undefined : locale) : 'data unavailable' }}
    </small>
  </div>
  <div class="created-completion-data">
    <small *ngIf="!isRepeatingTodo">
      Created <span *ngIf="item.createdBy">by {{ createdBy }}</span> on
      {{ createdDate | date : 'fullDate' : undefined : locale }}
    </small>
    <small *ngIf="showAutomaticallyCreated">
      Automatically created on
      {{ createdDate | date : 'fullDate' : undefined : locale }}
    </small>
    <small *ngIf="isRepeatingTodo">
      Original {{ vm.language.todo.item }} created by {{ createdBy }} on
      {{ originalCreatedDate | date : 'fullDate' : undefined : locale }}
    </small>
    <div class="not-completed-stamp" *ngIf="item.dueDateChanged">
      <small *ngIf="item.completedDate | notDoneByDueDate : item.dueDate">
        <span class="orange">NOT COMPLETED</span>
        by&nbsp;
      </small>
      <small> {{ item.originalDueDate | date : 'mediumDate' : undefined : locale }} (original due date) </small>
    </div>
  </div>
</div>
