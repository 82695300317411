import { CommonModule } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild } from '@angular/core';

import { NinetyCmButtonComponent } from '../cm-button/cm-button.component';

@Component({
  selector: 'ninety-cm-tiered-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './cm-tiered-page.component.html',
  styleUrls: ['./cm-tiered-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NinetyCmTieredPageComponent implements AfterContentInit {
  @ContentChild(NinetyCmButtonComponent) private _cmButton!: NinetyCmButtonComponent;

  ngAfterContentInit(): void {
    this._cmButton.fullWidth = true;
  }
}
