import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { BehaviorSubject, tap } from 'rxjs';

import { TimePeriod, Cadence } from '@ninety/pages/meetings/_models';
import { TerraIconModule } from '@ninety/terra';
import { MaterialMdcModule } from '@ninety/ui/legacy/angular-material/material-mdc.module';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { MeetingType } from '@ninety/ui/legacy/shared/index';
import { extractValueFromStore, selectCurrentUserId, selectLanguage } from '@ninety/ui/legacy/state/index';

import { MeetingSchedulingActions } from '../../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../../_state/meetings.selectors';

@Component({
  selector: 'ninety-schedule-meeting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TerraIconModule,
    LetDirective,
    ButtonComponent,
    MatDialogModule,
    MaterialMdcModule,
  ],
  templateUrl: './schedule-meeting-dialog.component.html',
  styleUrls: ['./schedule-meeting-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleMeetingDialogComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly dialogRef = inject(MatDialogRef<ScheduleMeetingDialogComponent>);

  form: FormGroup;
  TimePeriod = TimePeriod;
  protected cadence$ = new BehaviorSubject<{ name: string; value: Cadence }[]>([...this.getCadenceOptions(new Date())]);
  minDate = new Date();

  vm$ = {
    language: this.store.select(selectLanguage),
    agendas: this.store.select(MeetingsStateSelectors.selectCombinedAgendaOptionsTiered).pipe(
      tap(agendas => {
        //Note: we default to the first agenda if there is only one
        //happens when on essential tier (for now), only weekly meetings are available
        if (agendas.length === 1) {
          this.form.get('agenda').setValue(agendas[0]);
          this.form.get('agenda').disable();
        }
      })
    ),
    users: this.store.select(MeetingsStateSelectors.selectNonObserverUsersForTeamAndCoaches),
  };

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      agenda: ['', Validators.required],
      date: [new Date(), Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
      period: [TimePeriod.AM, Validators.required],
      cadence: [Cadence.none, Validators.required],
      presenterId: [extractValueFromStore(this.store, selectCurrentUserId), Validators.required],
      scribeId: [],
    });
  }

  dateChanged(date: Date) {
    this.cadence$.next(this.getCadenceOptions(date));

    this.form.patchValue({ cadence: Cadence.none });
  }

  save() {
    this.store.dispatch(MeetingSchedulingActions.addMeetingSchedule(this.formToAddMeetingModel()));
    this.dialogRef.close();
  }

  formToAddMeetingModel() {
    const { agenda, date, cadence, hours, minutes, period, presenterId, scribeId } = this.form.getRawValue();
    const meetingType = agenda.item ? MeetingType.custom : agenda.id;
    const agendaId = agenda.item ? agenda.item._id : null;

    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const parsedHours = Number(hours);
    const adjustedHours =
      period === TimePeriod.PM ? (parsedHours === 12 ? 12 : parsedHours + 12) : parsedHours === 12 ? 0 : parsedHours;
    const dateTime = new Date(dateWithoutTime.setHours(adjustedHours, minutes));

    return {
      schedule: {
        meetingType,
        agendaId,
        presenterId,
        scribeId: scribeId || null,
        cadence,
        cadenceStartDate: dateTime,
        parentId: null,
      },
    };
  }

  getCadenceOptions(date: Date): { name: string; value: Cadence }[] {
    return [
      { name: 'does not repeat', value: Cadence.none },
      { name: `Weekly on ${format(date, 'EEEE')}`, value: Cadence.weekly },
      { name: `Monthly on the ${this.getNthDayInTheMonth(date)} ${format(date, 'EEEE')}`, value: Cadence.monthly },
    ];
  }

  getNthDayInTheMonth(date: Date): string {
    const day = date.getDate();
    const ordinals = ['first', 'second', 'third', 'fourth', 'fifth'];
    return ordinals[Math.floor((day - 1) / 7)];
  }
}
