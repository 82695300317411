import { createSelector } from '@ngrx/store';

import { InsightsFullRoutes } from '@ninety/insights/insights-routes';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlags } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';

export const selectShouldShowInsightsInNavigation = createSelector(
  selectFeatureFlags,
  (flags): boolean =>
    flags[FeatureFlagKeys.insightsTopLevel] &&
    (flags[FeatureFlagKeys.insightsRocksDashboard] || flags[FeatureFlagKeys.insightsFeedbackDashboard])
);

export const selectInsightsInitialRoute = createSelector(selectFeatureFlags, (flags): string => {
  if (flags[FeatureFlagKeys.insightsRocksDashboard]) return InsightsFullRoutes.rocks;
  else return InsightsFullRoutes.feedback;
});
