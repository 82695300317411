import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { isThisYear, isValid } from 'date-fns';
import { Subscription } from 'rxjs';

import { TerraIconModule } from '@ninety/terra';

import { StateService } from '../../../_core/services/state.service';
import { Locale } from '../../models/_shared/user-settings';
import { ItemDueStatusPipe } from '../../pipes/dates/item-due-status.pipe';

@Component({
  selector: 'ninety-inline-editable-due-date',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    ItemDueStatusPipe,
    TerraIconModule,
    FormsModule,
  ],
  templateUrl: './inline-editable-due-date.component.html',
  styleUrls: ['./inline-editable-due-date.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    {
      provide: MAT_DATE_FORMATS,
      useFactory: () => ({
        parse: {
          dateInput: 'MMM D',
        },
        display: {
          dateInput: 'MMM D',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }),
    },
  ],
})
export class InlineEditableDueDateComponent implements OnInit, OnDestroy {
  @Input() dueDate: string | Date;
  @Input() overDue = false;
  @Input() dueToday = false;
  @Input() muted = false;
  @Input() showDueDateYear = true;
  @Input() numericFormat = false;
  @Input() disabled = false;
  @Output() dueDateChange = new EventEmitter<string | Date>();
  @ViewChild('dueDateInput') dueDateInput;
  @ViewChild('dueDatePicker') dueDatePicker;

  private readonly dateFormats = inject(MAT_DATE_FORMATS);
  private readonly subscriptions: Subscription = new Subscription();
  private locale: Locale;
  public stateService: StateService = inject(StateService);
  public isDueDateThisYear = true;
  private readonly dateAdapter = inject(DateAdapter);

  private updateDateFormat() {
    if (this.numericFormat) {
      this.updateNumericDateFormat();
    } else {
      this.updateDefaultDateFormat();
    }
    this.dateAdapter.setLocale(this.locale);
  }

  private updateNumericDateFormat() {
    switch (this.locale) {
      case Locale.greatBritain:
        this.dateFormats.display.dateInput = 'DD/MM/YYYY';
        this.dateFormats.parse.dateInput = 'DD/MM/YYYY';
        break;
      case Locale.canada:
        this.dateFormats.display.dateInput = 'YYYY-MM-DD';
        this.dateFormats.parse.dateInput = 'YYYY-MM-DD';
        break;
      case Locale.default:
      default:
        this.dateFormats.display.dateInput = 'M/D/YYYY';
        this.dateFormats.parse.dateInput = 'M/D/YYYY';
        break;
    }
  }

  private updateDefaultDateFormat() {
    this.isDueDateThisYear = isThisYear(new Date(this.dueDate));
    switch (this.locale) {
      case Locale.greatBritain:
        if (this.isDueDateThisYear || !this.showDueDateYear) {
          this.dateFormats.display.dateInput = 'D MMM';
          this.dateFormats.parse.dateInput = 'D MMM';
        } else {
          this.dateFormats.display.dateInput = 'D MMM YYYY';
          this.dateFormats.parse.dateInput = 'D MMM YYYY';
        }
        break;
      case Locale.canada:
      case Locale.default:
      default:
        if (this.isDueDateThisYear || !this.showDueDateYear) {
          this.dateFormats.display.dateInput = 'MMM D';
          this.dateFormats.parse.dateInput = 'MMM D';
        } else {
          this.dateFormats.display.dateInput = 'MMM D, YYYY';
          this.dateFormats.parse.dateInput = 'MMM D, YYYY';
        }
        break;
    }
  }

  ngOnInit() {
    this.subscriptions.add(
      this.stateService.localeChange$.subscribe({
        next: l => {
          this.locale = l;
          this.updateDateFormat();
        },
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onDueDateChange(event: MatDatepickerInputEvent<Date>) {
    const dueDate = event.value;

    if (!dueDate) {
      this.dueDate = new Date(this.dueDate);
      return;
    }

    const dateObject = new Date(dueDate);

    if (!isValid(dateObject) || dateObject.getFullYear() < 2000) {
      // Disallow dates before year 2000
      this.dueDate = new Date(this.dueDate);
      return;
    }

    this.dueDate = dueDate;
    this.updateDateFormat();
    this.dueDateChange.emit(dueDate);
    event.targetElement.blur();
  }

  openCalendar(event: Event) {
    if (!this.disabled) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      this.dueDateInput.nativeElement.blur();
      this.dueDatePicker.open();
    }
  }
}
