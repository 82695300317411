import { PricingTier } from '../../../_shared/models/company/company-pricing-tiers';
import {
  InitialLoggingFeatureFlagState,
  type LoggingFeatureFlagState,
} from '../../app-logging/logging-feature-flag-state';

export enum FeatureFlagKeys {
  appLogging = 'web-app-logging',
  appRefresh = 'web-app-refresh',
  coreNinetyDefaultVTO = 'web-coreNinety-vto-defaultVto',
  discussionFormV2 = 'web-discussion-form-v-2',
  dragDropFileAttachments = 'web-item-detail-dnd-attachments',
  enableAssessments = 'web-enable-assessments',
  enableAssessmentsSidenavPill = 'web-enable-assessments-new-pill',
  enableContactCard = 'web-app-contact-card',
  enableLearningModule = 'web-learning-module',
  enableLearningModuleCategories = 'web-learning-module-categories',
  enableLearnMode90UGAOnly = 'web-enable-learn-mode-90u-ga-only',
  enableTeamPageEnhancements = 'web-app-team-page-enhancements',
  enableUserManagementEnhancements = 'web-app-user-management-enhancements',
  enableRepeatConversations = 'web-enable-repeat-conversations',
  enableFrequentDiscussions = 'web-enable-frequent-discussions',
  gettingStartedGuide = 'getting-started-guide',
  headlinesV2 = 'web-headlines-v2',
  hideReferFriendMenuItem = 'web-app-hide-refer-a-friend-menu-item',
  linkedItems = 'web-linked-items',
  mazChat = 'maz-chat',
  modifyBillingCoupons = 'web-settings-billing-coupons',
  partnerHubInsights = 'partner-hub-insights',
  pinnacleDefaultVTO = 'web-pinnacle-vto-defaultVto',
  pinnacleMissingData = 'web-pinnacle-missing-data',
  responsibilitiesBeta = 'web-responsibilities-v2',
  responsibiliitesBetaSetAsPrimary = 'web-responsibilities-v2-set-as-primary',
  responsibilitiesHideTopSeatSelect = 'web-org-chart-hide-top-seat-select',
  responsibilitiesSearch = 'web-org-chart-show-seat-search',
  responsibilitiesSeatDetailsV3 = 'web-org-chart-seat-details-v3',
  scorecardBeta = 'web-scorecard-v2',
  sessionLogout = 'web-session-logout',
  showUserEventNotificationsTab = 'web-settings-user-notifications',
  webUserSettingsIntegrationZapier = 'web-user-settings-integrations-zapier',
  webIntegrationsMicrosoft = 'web-integrations-microsoft',
  trialBanner = 'web-billing-trial-banner',
  trialCountdown = 'web-billing-trial-countdown',
  todosTabs = 'web-todos-tabs',
  useImageUploaderForCompany = 'web-settings-use-image-loader-for-company',
  quarterlyQuestionSettings = 'web-quarterly-questions-settings',
  webConversationSettingsRatings = 'web-conversation-settings-ratings',
  webWeeklyConversations = 'web-weekly-conversations',
  webExcelDownload = 'web-excel-download',
  webExcelDownloadBatch2 = 'web-excel-download-batch-2',
  clientFacingDataUpload = 'client-facing-data-upload',
  insightsTopLevel = 'web-insights-top-level-access',
  insightsRightPersonRightSeat = 'web-right-person-right-seat-banner',
  insightsRocksDashboard = 'web-insights-rocks-dashboard',
  insightsFeedbackDashboard = 'web-insights-feedback-dashboard',
  whatNextButton = 'what-next-button',
  mfaDenyList = 'web-account-mfa-denylist',
  whatNextButtonABLabel = 'what-next-button-a-b-test-label',
  inlineDateEdit = 'web-inline-date-edit',
  vidyardLearningVideos = 'vidyard-learning-videos',
  linkedItemsRealTime = 'web-linked-items-real-time',
  timezonePrompt = 'web-app-timezone-prompt',
  microLearningVideos = 'web-app-micro-learning-videos',
  webDiscussionsPubnub = 'web-discussions-pubnub',
  rocksRedesign = 'web-rocks-redesign',
  rockCardRedesign = 'web-rocks-card-redesign',
  followersEnabled = 'web-followers',
  meetingScheduling = 'app-meeting-scheduling',
  meetingsV2 = 'app-meetings-v2',
  meetingAgendasV2 = 'web-meeting-agendas-v2',
  webRocksV3 = 'web-rocks-v3',
  referralProgram = 'web-referral-program',
  terrafyToolbars = 'web-toolbars-terrafy',
  enableKnowledgePartnerHub = 'web-enable-knowledge-partner-hub',
  webTempPricingTier = 'web-temp-pricing-tier',
  linkedVision = 'web-linked-vision',
  webPricingGoLiveDate = 'web-pricing-go-live-date',
}

export interface FeatureFlagStateModel {
  userFlagsAreLoaded: boolean;
  [FeatureFlagKeys.appLogging]: LoggingFeatureFlagState;
  [FeatureFlagKeys.appRefresh]: boolean;
  [FeatureFlagKeys.coreNinetyDefaultVTO]: boolean;
  [FeatureFlagKeys.discussionFormV2]: boolean;
  [FeatureFlagKeys.dragDropFileAttachments]: boolean;
  [FeatureFlagKeys.enableAssessments]: boolean;
  [FeatureFlagKeys.enableAssessments]: boolean;
  [FeatureFlagKeys.enableAssessmentsSidenavPill]: boolean;
  [FeatureFlagKeys.enableContactCard]: boolean;
  [FeatureFlagKeys.enableLearningModule]: boolean;
  [FeatureFlagKeys.enableLearningModuleCategories]: boolean;
  [FeatureFlagKeys.enableLearnMode90UGAOnly]: boolean;
  [FeatureFlagKeys.enableTeamPageEnhancements]: boolean;
  [FeatureFlagKeys.enableUserManagementEnhancements]: boolean;
  [FeatureFlagKeys.enableRepeatConversations]: boolean;
  [FeatureFlagKeys.enableFrequentDiscussions]: boolean;
  [FeatureFlagKeys.gettingStartedGuide]: boolean;
  [FeatureFlagKeys.headlinesV2]: boolean;
  [FeatureFlagKeys.hideReferFriendMenuItem]: boolean;
  [FeatureFlagKeys.linkedItems]: boolean;
  [FeatureFlagKeys.mazChat]: boolean;
  [FeatureFlagKeys.modifyBillingCoupons]: boolean;
  [FeatureFlagKeys.partnerHubInsights]: boolean;
  [FeatureFlagKeys.pinnacleDefaultVTO]: boolean;
  [FeatureFlagKeys.pinnacleMissingData]: boolean;
  [FeatureFlagKeys.responsibilitiesBeta]: boolean;
  [FeatureFlagKeys.responsibiliitesBetaSetAsPrimary]: boolean;
  [FeatureFlagKeys.responsibilitiesHideTopSeatSelect]: boolean;
  [FeatureFlagKeys.responsibilitiesSearch]: boolean;
  [FeatureFlagKeys.responsibilitiesSeatDetailsV3]: boolean;
  [FeatureFlagKeys.scorecardBeta]: boolean;
  [FeatureFlagKeys.sessionLogout]: boolean;
  [FeatureFlagKeys.showUserEventNotificationsTab]: boolean;
  [FeatureFlagKeys.webUserSettingsIntegrationZapier]: boolean;
  [FeatureFlagKeys.webIntegrationsMicrosoft]: boolean;
  [FeatureFlagKeys.trialBanner]: boolean;
  [FeatureFlagKeys.trialCountdown]: boolean;
  [FeatureFlagKeys.todosTabs]: boolean;
  [FeatureFlagKeys.useImageUploaderForCompany]: boolean;
  [FeatureFlagKeys.quarterlyQuestionSettings]: boolean;
  [FeatureFlagKeys.webWeeklyConversations]: boolean;
  [FeatureFlagKeys.webConversationSettingsRatings]: boolean;
  [FeatureFlagKeys.webExcelDownload]: boolean;
  [FeatureFlagKeys.webExcelDownloadBatch2]: boolean;
  [FeatureFlagKeys.clientFacingDataUpload]: boolean;
  [FeatureFlagKeys.insightsTopLevel]: boolean;
  [FeatureFlagKeys.insightsRightPersonRightSeat]: boolean;
  [FeatureFlagKeys.insightsRocksDashboard]: boolean;
  [FeatureFlagKeys.insightsFeedbackDashboard]: boolean;
  [FeatureFlagKeys.whatNextButton]: boolean;
  [FeatureFlagKeys.mfaDenyList]: Array<string>;
  [FeatureFlagKeys.whatNextButtonABLabel]: string;
  [FeatureFlagKeys.inlineDateEdit]: boolean;
  [FeatureFlagKeys.vidyardLearningVideos]: boolean;
  [FeatureFlagKeys.linkedItemsRealTime]: boolean;
  [FeatureFlagKeys.timezonePrompt]: boolean;
  [FeatureFlagKeys.microLearningVideos]: boolean;
  [FeatureFlagKeys.webDiscussionsPubnub]: boolean;
  [FeatureFlagKeys.rocksRedesign]: boolean;
  [FeatureFlagKeys.rockCardRedesign]: boolean;
  [FeatureFlagKeys.followersEnabled]: boolean;
  [FeatureFlagKeys.meetingsV2]: boolean;
  [FeatureFlagKeys.meetingAgendasV2]: boolean;
  [FeatureFlagKeys.meetingScheduling]: boolean;
  [FeatureFlagKeys.webRocksV3]: boolean;
  [FeatureFlagKeys.referralProgram]: boolean;
  [FeatureFlagKeys.terrafyToolbars]: boolean;
  [FeatureFlagKeys.enableKnowledgePartnerHub]: boolean;
  [FeatureFlagKeys.webTempPricingTier]: PricingTier;
  [FeatureFlagKeys.linkedVision]: boolean;
  [FeatureFlagKeys.webPricingGoLiveDate]: string;
}

export const initialFeatureFlagState: FeatureFlagStateModel = {
  userFlagsAreLoaded: false,
  [FeatureFlagKeys.appLogging]: InitialLoggingFeatureFlagState,
  [FeatureFlagKeys.appRefresh]: false,
  [FeatureFlagKeys.coreNinetyDefaultVTO]: false,
  [FeatureFlagKeys.discussionFormV2]: false,
  [FeatureFlagKeys.dragDropFileAttachments]: false,
  [FeatureFlagKeys.enableAssessments]: false,
  [FeatureFlagKeys.enableAssessmentsSidenavPill]: false,
  [FeatureFlagKeys.enableContactCard]: false,
  [FeatureFlagKeys.enableLearningModule]: false,
  [FeatureFlagKeys.enableLearningModuleCategories]: false,
  [FeatureFlagKeys.enableLearnMode90UGAOnly]: false,
  [FeatureFlagKeys.enableKnowledgePartnerHub]: false,
  [FeatureFlagKeys.enableTeamPageEnhancements]: false,
  [FeatureFlagKeys.enableUserManagementEnhancements]: false,
  [FeatureFlagKeys.enableRepeatConversations]: false,
  [FeatureFlagKeys.enableFrequentDiscussions]: false,
  [FeatureFlagKeys.gettingStartedGuide]: false,
  [FeatureFlagKeys.headlinesV2]: false,
  [FeatureFlagKeys.hideReferFriendMenuItem]: true,
  [FeatureFlagKeys.linkedItems]: false,
  [FeatureFlagKeys.mazChat]: false,
  [FeatureFlagKeys.modifyBillingCoupons]: false,
  [FeatureFlagKeys.partnerHubInsights]: false,
  [FeatureFlagKeys.pinnacleDefaultVTO]: false,
  [FeatureFlagKeys.pinnacleMissingData]: false,
  [FeatureFlagKeys.responsibilitiesBeta]: true,
  [FeatureFlagKeys.responsibiliitesBetaSetAsPrimary]: true,
  [FeatureFlagKeys.responsibilitiesHideTopSeatSelect]: false,
  [FeatureFlagKeys.responsibilitiesSearch]: false,
  [FeatureFlagKeys.responsibilitiesSeatDetailsV3]: false,
  [FeatureFlagKeys.scorecardBeta]: false,
  [FeatureFlagKeys.sessionLogout]: false,
  [FeatureFlagKeys.showUserEventNotificationsTab]: false,
  [FeatureFlagKeys.webUserSettingsIntegrationZapier]: false,
  [FeatureFlagKeys.webIntegrationsMicrosoft]: false,
  [FeatureFlagKeys.trialBanner]: false,
  [FeatureFlagKeys.trialCountdown]: false,
  [FeatureFlagKeys.todosTabs]: false,
  [FeatureFlagKeys.useImageUploaderForCompany]: false,
  [FeatureFlagKeys.quarterlyQuestionSettings]: false,
  [FeatureFlagKeys.webConversationSettingsRatings]: false,
  [FeatureFlagKeys.webWeeklyConversations]: false,
  [FeatureFlagKeys.webExcelDownload]: false,
  [FeatureFlagKeys.webExcelDownloadBatch2]: false,
  [FeatureFlagKeys.clientFacingDataUpload]: false,
  [FeatureFlagKeys.insightsTopLevel]: false,
  [FeatureFlagKeys.insightsRightPersonRightSeat]: false,
  [FeatureFlagKeys.insightsRocksDashboard]: false,
  [FeatureFlagKeys.insightsFeedbackDashboard]: false,
  [FeatureFlagKeys.whatNextButton]: false,
  [FeatureFlagKeys.mfaDenyList]: [],
  [FeatureFlagKeys.whatNextButtonABLabel]: 'Need help with next steps?',
  [FeatureFlagKeys.inlineDateEdit]: false,
  [FeatureFlagKeys.vidyardLearningVideos]: false,
  [FeatureFlagKeys.linkedItemsRealTime]: false,
  [FeatureFlagKeys.timezonePrompt]: false,
  [FeatureFlagKeys.microLearningVideos]: false,
  [FeatureFlagKeys.webDiscussionsPubnub]: false,
  [FeatureFlagKeys.rocksRedesign]: false,
  [FeatureFlagKeys.rockCardRedesign]: false,
  [FeatureFlagKeys.followersEnabled]: false,
  [FeatureFlagKeys.meetingsV2]: false,
  [FeatureFlagKeys.meetingAgendasV2]: false,
  [FeatureFlagKeys.meetingScheduling]: false,
  [FeatureFlagKeys.webRocksV3]: false,
  [FeatureFlagKeys.referralProgram]: false,
  [FeatureFlagKeys.terrafyToolbars]: false,
  [FeatureFlagKeys.webTempPricingTier]: PricingTier.thrive,
  [FeatureFlagKeys.linkedVision]: false,
  [FeatureFlagKeys.webPricingGoLiveDate]: '2025-12-31',
};
