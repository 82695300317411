import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { FilterBarActions } from '@ninety/layouts/_state/filterbar/filterbar-state.actions';
import { TerraIconModule } from '@ninety/terra';
import { NinetyCMModule } from '@ninety/ui/legacy/components/commercial-model/cm.module';
import { selectLanguageArea } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

@Component({
  selector: 'ninety-todos-tiered-page',
  standalone: true,
  imports: [CommonModule, TerraIconModule, NinetyCMModule, LetDirective],
  template: `<ninety-cm-tiered-page *ngrxLet="todoLang$ as lang">
    <div headline>You don’t have access to {{ lang.items | titlecase }} on your current plan.</div>
    <div description>Upgrade your plan to start using {{ lang.items | titlecase }}.</div>
    <button ninety-cm-button>
      <terra-icon icon="lightning" variant="fill" />
      Explore Plans
    </button>
    <a href="https://www.ninety.io" rel="external" target="_blank">Learn more about {{ lang.items | titlecase }}</a>
  </ninety-cm-tiered-page>`,
  styles: [''],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TodosTieredPageComponent {
  protected readonly todoLang$ = this.store.select(selectLanguageArea('todo'));

  constructor(private readonly store: Store) {
    this.store.dispatch(FilterBarActions.hide());
  }
}
