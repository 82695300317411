export enum RockStatusCode {
  offTrack = '0000',
  onTrack = '0001',
  complete = '0002',
  canceled = '0003',
}

export namespace RockStatusCode {
  export function isRockStatusCode(value: string): value is RockStatusCode {
    return Object.values(RockStatusCode).includes(value as RockStatusCode);
  }
}
