import { createActionGroup, props } from '@ngrx/store';

import { MoveNodePatch, TreeOrdinalUpdate } from '../logic/move-node.logic';

export type AdjustedCenterCalculator = (center: number) => number;

type DeleteTreeNodeHookUpdates = { ordinals: TreeOrdinalUpdate[]; idsToRemove: string[] };

/**
 * These actions represent hooks into the Tree Module that the domain can respond to. Its expected that the feature
 * domain registers watchers for these actions while the feature domain is active.
 *
 * TODO this actions should be removed as their flows prevent anyone from using the tree component except the org chart.
 *  The org chart effects which respond to these actions have no way of knowing if the org chart is active or not and
 *  thus no way to differentiate between the org chart and other feature domains. All these actions should be converted
 *  to outputs on the component, fed from the component store. Then, feature implementations can dispatch whatever
 *  actions are appropriate for their use case when the tree component emits on its outputs.
 */
export const HierarchyTreeComponentActions = createActionGroup({
  source: 'HierarchyTreeComponent',
  events: {
    /** Event dispatched to request that the tree's scrollable parent scroll to the center of the tree */
    Scroll: props<{ adjustedCenterCalculator: AdjustedCenterCalculator }>(),

    /** Hook for feature modules to update sibling ordinals and changed parentId after changing a nodes siblings */
    'Update After Change Parent': props<MoveNodePatch>(),

    /** Hook for feature module to update sibling ordinals after swapping siblings. */
    'Update Ordinals After Swap Siblings': props<{ updates: TreeOrdinalUpdate[] }>(),

    /**
     * Hook for feature module to update sibling ordinals and remove descendents after deleting a seat.
     *
     * As the tree already has the information necessary to know which nodes to delete, we dispatch this action instead
     * of forcing feature domains to calculate the descendants to delete.
     */
    'Update After Delete': props<{ updates: DeleteTreeNodeHookUpdates }>(),
  },
});
