<!-- Readonly Layout -->
<ng-container *ngIf="!item.isEditing; else editing">
  <!-- Meta - Title + description -->
  <div class="flex-1">
    <div class="accountability-name">{{ item.accountability?.name }}</div>
    <div class="accountability-description">{{ item.accountability?.description }}</div>
  </div>

  <ng-container *ngIf="canEdit">
    <!-- Edit Button -->
    <ninety-button
      data-cy="accountability-list-item_edit-button"
      primary
      icon
      flat
      (click)="enterEditMode()"
      class="edit-button">
      <terra-icon icon="edit" size="20" />
    </ninety-button>

    <!-- Remove Button -->
    <ninety-button
      negative
      icon
      flat
      (click)="clickedRemove.emit(item)"
      class="remove-button"
      data-cy="accountability-list-item_remove-button">
      <terra-icon icon="delete" size="20" />
    </ninety-button>
  </ng-container>
</ng-container>

<!-- Edit Layout -->
<ng-template #editing>
  <fieldset class="flex-1">
    <!-- Role Name -->
    <mat-label class="terra-body acountability-list-item__label">Role</mat-label>
    <mat-form-field appearance="outline">
      <input
        [(ngModel)]="nameCopy"
        #nameInput="ngModel"
        aria-label="Accountabilty name"
        class="role-name-input"
        data-cy="seat-details-role-input"
        matInput
        name="roleName"
        required
        type="text" />
    </mat-form-field>
    <!-- Name error -->
    <div>
      <small *ngIf="nameInput.touched && !nameInput.valid" class="accountability-list-item-error mat-error">
        A role name is required
      </small>
    </div>

    <!-- Description -->
    <mat-label class="acountability-list-item__label">Responsibilities</mat-label>
    <mat-form-field appearance="outline">
      <textarea
        [(ngModel)]="descriptionCopy"
        aria-label="Accountabilty description"
        cdkTextareaAutosize
        class="role-description-input"
        data-cy="seat-details-description-input"
        matInput
        name="description"
        type="text"></textarea>
    </mat-form-field>

    <!-- Buttons -->
    <div class="action-group flex-justify-start">
      <!--
        When the seatName is focused & empty & the clicks down on the cancel button, it triggers a blur event,
        which triggers the error message for the seatName input and shifts the layout displaying the message.
        In this case, we preventDefault to prevent that event from reaching the input and causing the layout shift.
       -->
      <ninety-button
        negative
        flat
        (click)="exitEditMode()"
        (mousedown)="$event.preventDefault()"
        class="accountability-list-item-cancel-button">
        Cancel
      </ninety-button>
      <ninety-button
        secondary
        stroked
        [disabled]="!canSave"
        (click)="onClickSave()"
        class="accountability-list-item-save-button"
        data-cy="accountability-list-item_save-button">
        Save
      </ninety-button>
    </div>
  </fieldset>
</ng-template>
