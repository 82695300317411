export enum NinetyFeatures {
  vision = 'vision',
  printOrDownload = 'print-or-download',
  swot = 'swot',
  strategy = 'strategy',
  orgChart = 'org-chart',
  directory = 'directory',
  assessments = 'assessments',
  kst = 'kst',
  mastery = 'mastery',
  my90 = 'my90',
  weeklyMeetings = 'weekly-meetings',
  allMeetings = 'all-meetings',
  rocks = 'rocks',
  milestones = 'milestones',
  todos = 'to-dos',
  issues = 'issues',
  mazOnboarding = 'maz-onboarding',
  scorecard = 'scorecard',
  headlines = 'headlines',
  cascadingMessages = 'cascading-messages',
  insights = 'insights',
  weeklyDiscussions = 'weekly-discussions',
  quarterlyDiscussions = 'quarterly-discussions',
  process = 'process',
  integrations = 'integrations',
  oneOnOne = 'one-on-one',
}

/*
 * TODO: Remove once feature access is provided by the BE
 */
export enum FeatureAccessLevel {
  read = 'read',
  write = 'write',
}

export interface FeatureAccess {
  featureId: NinetyFeatures;
  access?: FeatureAccessLevel;
}

export enum PricingTier {
  free = 'free',
  essential = 'essential',
  scale = 'scale',
  thrive = 'thrive',
}

const pricingTierFree: FeatureAccess[] = [
  { featureId: NinetyFeatures.vision, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.strategy, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.orgChart, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.directory, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.assessments, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.mazOnboarding, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.read },
  { featureId: NinetyFeatures.mastery, access: FeatureAccessLevel.read },
  { featureId: NinetyFeatures.process, access: FeatureAccessLevel.read },
];
const pricingTierEssential: FeatureAccess[] = [
  { featureId: NinetyFeatures.printOrDownload },
  { featureId: NinetyFeatures.swot, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.my90, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.weeklyMeetings, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.scorecard, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.headlines, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.issues, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.rocks, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.todos, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.read },
  { featureId: NinetyFeatures.mastery, access: FeatureAccessLevel.read },
  { featureId: NinetyFeatures.process, access: FeatureAccessLevel.read },
];
const pricingTierScale: FeatureAccess[] = [
  { featureId: NinetyFeatures.insights, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.cascadingMessages, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.allMeetings, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.oneOnOne, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.read },
  { featureId: NinetyFeatures.mastery, access: FeatureAccessLevel.read },
  { featureId: NinetyFeatures.process, access: FeatureAccessLevel.read },
];

//add thrive features below
const pricingTierThrive: FeatureAccess[] = [
  { featureId: NinetyFeatures.mastery, access: FeatureAccessLevel.write },
  { featureId: NinetyFeatures.kst, access: FeatureAccessLevel.write },
];

const mergeFeatureAccess = (...tiers: FeatureAccess[][]): FeatureAccess[] => {
  const merged = tiers.flat().reduce((acc, feature) => {
    if (!acc[feature.featureId] || feature.access === FeatureAccessLevel.write) {
      acc[feature.featureId] = feature;
    }
    return acc;
  }, {} as Record<string, FeatureAccess>);

  return Object.values(merged);
};

export const PricingTiers: Record<keyof typeof PricingTier, FeatureAccess[]> = {
  [PricingTier.free]: pricingTierFree,
  [PricingTier.essential]: mergeFeatureAccess(pricingTierFree, pricingTierEssential),
  [PricingTier.scale]: mergeFeatureAccess(pricingTierFree, pricingTierEssential, pricingTierScale),
  [PricingTier.thrive]: mergeFeatureAccess(pricingTierFree, pricingTierEssential, pricingTierScale, pricingTierThrive),
};
