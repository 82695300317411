import ObjectID from 'bson-objectid';
import moment from 'moment/moment';

import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockLevelCode } from '@ninety/ui/legacy/shared/models/rocks/rock-level-code';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';

export class RockRecord {
  title: string;
  email: string;
  dueDate: string;
  statusCode: string;
  archivedDate: Date;
  completedDate: Date;
  userId?: string;
  company_rock: string;
  team_2: string;
  team_3: string;
  team_4: string;
  static toRocks(records: RockRecord[], teamId: string, allTeams: Team[]): Rock[] {
    return records.map((record, i: number) => ({
      ...record,
      userId: ObjectID.isValid(record.userId) ? record.userId : null,
      teamId,
      archived: !!record.archivedDate,
      archivedDate: record.archivedDate || null,
      completedDate: setCompletedDate(record),
      createdBy: 'Data Import',
      dueDate: formatDate(record.dueDate),
      additionalTeamIds: mapTeams(record, allTeams),
      levelCode: record.company_rock === 'yes' ? RockLevelCode.companyAndDepartment : RockLevelCode.user,
      statusCode: StatusToCode[record.statusCode] || RockStatusCode.complete,
      // just throw them at the bottom without slowing down the process by trying to count mongo documents
      ordinal: 100000 + i,
      userOrdinal: 100000 + i,
    }));
  }
}

function setCompletedDate({ completedDate, statusCode }: RockRecord): Date {
  if (completedDate) return completedDate;
  if (StatusToCode[statusCode] != RockStatusCode.complete) return null;
  return new Date(); // if completed but no date supplied, set to today's date.
}

function formatDate(date: string): string {
  if (date.length) {
    const parsedDate = moment(date).format();
    const invalidDate = parsedDate === 'Invalid date'; // what moment.js returns if it can't understand the string
    if (invalidDate) return;
    else return parsedDate;
  } else {
    // do nothing if blank (and not required from settings)
    return;
  }
}
function mapTeams(item: RockRecord, allTeams: Team[]): string[] {
  const teams = [];
  allTeams.map(t => {
    const teamName = t.name?.toLowerCase();

    if (
      teamName === item.team_2?.toLowerCase() ||
      teamName === item.team_3?.toLowerCase() ||
      teamName === item.team_4?.toLowerCase()
    )
      teams.push(t._id);
  });

  return teams;
}

enum StatusToCode {
  'On Track' = RockStatusCode.onTrack,
  'Off Track' = RockStatusCode.offTrack,
  'Complete' = RockStatusCode.complete,
}
