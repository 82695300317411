import { AccountabilityListItem } from '@ninety/accountability-chart/models/accountabilities';
import { Accountability } from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';

import { createId } from '../utils/create-id';

export function mapAccountabilityToListItem(accountabilities: Accountability[]): AccountabilityListItem[] {
  if (!accountabilities?.length) return [];

  const items = accountabilities.map(acc => {
    const item: AccountabilityListItem = {
      id: createId(),
      accountability: acc,
      isEditing: false,
      markedForCreate: false,
      markedForRemoval: false,
      markedForUpdate: false,
    };

    return item;
  });

  return items;
}
