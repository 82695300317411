import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { DataImportService } from '@ninety/data-import/services/data-import.service';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import { selectHasHelpfulDataImportPermissions } from '@ninety/ui/legacy/state/app-global/helpful-permissions/helpful-permissions.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { NotificationActions } from '@ninety/ui/legacy/state/app-global/notifications/notification.actions';
import { SpinnerActions } from '@ninety/ui/legacy/state/app-global/spinner/spinner-state.actions';

@Injectable()
export class DataImportEffects {
  launchOneSchema$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DataImportActions.getOneSchemaTokenSuccess,
          DataImportActions.launchOneSchema,
          /** resetting when electing another template or after successful import */
          DataImportActions.selectImportType,
          DataImportActions.success,
          DataImportActions.afterSetIframe
        ),
        concatLatestFrom(() => [
          this.store.select(DataImportSelectors.selectToken),
          this.store.select(DataImportSelectors.selectTemplateKey),
        ]),
        tap(([_, userJwt, templateKey]) => this.dataImportService.launchOneSchema(templateKey, userJwt))
      ),
    { dispatch: false }
  );

  initSelectedOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.init),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTemplateOptions)),
      map(([, options]) => {
        return DataImportActions.setInitialImportType({ option: options[0].item });
      })
    )
  );

  getTokenIfHelpful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.setInitialImportType),
      concatLatestFrom(() => this.store.select(selectHasHelpfulDataImportPermissions)),
      filter(([_, isHelpful]) => isHelpful),
      map(() => DataImportActions.getOneSchemaToken())
    )
  );

  initSelectedTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.init),
      concatLatestFrom(() => this.store.select(TeamSelectors.selectFilterBarTeamId)),
      map(([, teamId]) => DataImportActions.selectTeam({ teamId }))
    )
  );
  getOneSchemaToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.getOneSchemaToken, DataImportActions.confirmedReadyToProceed),
      switchMap(() =>
        this.dataImportService.getOneSchemaToken().pipe(
          map(data => DataImportActions.getOneSchemaTokenSuccess({ userJwt: data.token })),
          catchError((error: unknown) =>
            of(
              NotificationActions.notifyV2({
                message: `Failed to get token ${error}`,
                params: { config: { duration: 3000 } },
              })
            )
          )
        )
      )
    )
  );

  startSpinnerWhenCreatingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createUsers),
      map(() => SpinnerActions.startPrimary({ source: 'Data Import' }))
    )
  );

  startSpinnerSpinnerOnAPIRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createTodos, DataImportActions.createRocks, DataImportActions.createIssues),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTemplateKey)),
      filter(([_, templateKey]) => templateKey !== OneSchemaTemplateKey.users),
      map(() => SpinnerActions.startPrimary({ source: 'Data Import' }))
    )
  );

  destroy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.destroy),
        tap(() => this.dataImportService.destroy())
      ),
    { dispatch: false }
  );

  downloadCSVTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.downloadCSVTemplate),
      concatLatestFrom(() => this.store.select(DataImportSelectors.selectTemplateKey)),
      map(([_, templateKey]) => {
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', `https://ninety-oneschema-data-import-templates.s3.amazonaws.com/${templateKey}.csv`);
        link.setAttribute('download', `template.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        const toast = {
          message: `The template has been saved to your download folder.`,
          title: 'Template downloaded!',
          override: { timeOut: 10000 },
        };
        return NotificationActions.success(toast);
      })
    )
  );

  success$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.success),
      concatLatestFrom(() => this.store.select(selectLanguage)),
      map(([{ key, message }, language]) => {
        const successMessage = message || `Successfully imported ${language[key].items}`;
        return NotificationActions.notifyV2({ message: successMessage, params: { config: { duration: 3000 } } });
      })
    )
  );

  constructor(private actions$: Actions, private store: Store, private dataImportService: DataImportService) {}

  stopSpinnerSpinnerOnAPIResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.success, DataImportActions.error, DataImportActions.launchOneSchema),
      map(() => SpinnerActions.stopPrimary({ source: 'Data Import' }))
    )
  );
}
