<mat-spinner color="accent" *ngIf="spinner"></mat-spinner>
<div data-cy="configuration_container" class="container configuration" *ngrxLet="company$ as company">
  <mat-card class="ninety-card">
    <mat-card-title data-cy="configuration_title" class="PageHeader">Company Information</mat-card-title>

    <div class="company-config">
      <div class="company-logo-wrapper">
        <ninety-logo-uploader
          *ngIf="useImageUploaderForCompany$ | async; else oldCompanyLogo"
          [companyId]="company._id"
          [metadata]="{ picture: { url: company.logo.url } }" />
      </div>

      <ng-template #oldCompanyLogo>
        <div class="company-logo-wrapper">
          <div class="logo-upload-button">
            <img *ngIf="company.logo.url" [src]="company.logo.url" alt="company logo" />
            <terra-icon
              icon="upload"
              aria-label="File upload"
              matTooltip="Company Logo"
              (click)="openCompanyLogoDialog()" />
          </div>
          <ng-container *ngIf="hasHelpfulCompaniesPermissions$ | ngrxPush">
            <p>
              Company Id: <span class="muted">{{ this.company._id }}</span>
            </p>
          </ng-container>
        </div>
      </ng-template>
      <div class="name-phone-div">
        <div class="dflex">
          <terra-form-field class="name-phone-div__form">
            <terra-form-label>Company name</terra-form-label>
            <terra-text-input
              [(ngModel)]="company.name"
              (ngModelChange)="updateSub.next({ key: 'name', value: $event })"
              data-cy="configuration_company-name_input-field"
              aria-label="Company Name" />
          </terra-form-field>
          <div style="width: 24px"></div>
        </div>
        <div class="dflex">
          <div class="name-phone-div__form">
            <terra-form-label>Phone number</terra-form-label>
            <mat-form-field
              appearance="outline"
              class="_terra-migration-approved-override--mat-form-field name-phone-div__form">
              <input
                data-cy="configuration_phone-number_input-field"
                matInput
                aria-label="Phone Number"
                type="tel"
                [(ngModel)]="company.phoneNumber"
                (ngModelChange)="updateSub.next({ key: 'phoneNumber', value: $event })" />
            </mat-form-field>
          </div>
          <div style="width: 24px"></div>
        </div>
        <div class="dflex">
          <terra-form-field class="name-phone-div__form" data-cy="configuration_business-coach-dropdown-container">
            <terra-form-label>Are you working with a business coach?</terra-form-label>
            <terra-select
              data-cy="configuration_business-coach-dropdown"
              [(ngModel)]="company.eosType"
              (ngModelChange)="updateSub.next({ key: 'eosType', value: $event })">
              <terra-option
                attr.data-cy="configuration_business-coach-{{ type }}"
                *ngFor="let type of eosTypeOptions"
                [value]="type"
                >{{ type }}</terra-option
              >
            </terra-select>
          </terra-form-field>
          <terra-icon
            icon="info-circle"
            variant="fill"
            class="orange name-phone-div__info"
            size="16"
            matTooltipClass="ninety-tooltip"
            matTooltipPosition="above"
            matTooltip="This information is used so that 90 can better message you relevant content." />
        </div>
        <div class="dflex" *ngIf="company.eosType === 'Other'">
          <terra-form-field class="name-phone-div__form">
            <terra-form-label>Tell us about where you are in the EOS Process!</terra-form-label>
            <terra-text-input
              [(ngModel)]="company.eosOtherType"
              (ngModelChange)="updateSub.next({ key: 'eosOtherType', value: $event })" />
          </terra-form-field>
          <div style="width: 24px"></div>
        </div>
        <div class="dflex">
          <terra-form-field class="name-phone-div__form" data-cy="configuration_how-did-you-hear-about-us-container">
            <terra-form-label>How did you hear about us?</terra-form-label>
            <terra-select
              maxHeight="350"
              data-cy="configuration_how-did-you-hear-about-us-dropdown"
              [(ngModel)]="company.referralType"
              (ngModelChange)="updateSub.next({ key: 'referralType', value: $event })">
              <terra-option
                attr.data-cy="configuration_referrer-{{ referrer }}"
                *ngFor="let referrer of referrerOptions"
                [value]="referrer"
                >{{ referrer }}</terra-option
              >
            </terra-select>
          </terra-form-field>
          <terra-icon
            icon="info-circle"
            variant="fill"
            class="orange name-phone-div__info"
            size="16"
            matTooltipClass="ninety-tooltip"
            matTooltipPosition="above"
            matTooltip="This information is used so that 90 can better message you relevant content." />
        </div>
        <div class="dflex" *ngIf="company.referralType === 'Other'">
          <terra-form-field class="name-phone-div__form">
            <terra-form-label>Tell us how you found us!</terra-form-label>
            <terra-text-input
              data-cy="configuration_how-you-found-us_input-field"
              [(ngModel)]="company.referralOtherType"
              (ngModelChange)="updateSub.next({ key: 'referralOtherType', value: $event })" />
          </terra-form-field>
          <div style="width: 24px"></div>
        </div>
        <div *ngIf="company.settings?.colorBranding" class="company-color-branding">
          <ninety-custom-colors
            [colorBranding]="colorBranding$ | ngrxPush"
            (colorBrandingChange)="updateColorBranding($event)"
            tooltip="Changing the color scheme is a global setting (it will affect all users in your company).
                     This setting will allow you to change the orange color scheme of Ninety’s buttons,
                     headers, etc. into the two colors you choose." />
        </div>
      </div>
    </div>

    <mat-card-footer *ngIf="stateService.isOwner">
      <terra-divider />
      <mat-card-actions fxLayout="row" fxLayoutAlign="end end" class="actions">
        <button
          data-cy="configuration_delete-company"
          mat-button
          color="warn"
          class="delete-btn uppercase"
          (click)="deleteCompany()"
          matTooltip="Delete Company"
          matTooltipPosition="above"
          matTooltipClass="ninety-tooltip">
          <terra-icon icon="delete" />
          Delete Company
        </button>
      </mat-card-actions>
    </mat-card-footer>
  </mat-card>

  <mat-card class="ninety-card feature-controls-card">
    <mat-card-title class="PageHeader">Feature Controls</mat-card-title>

    <h4 class="section-header bottom-space-20">{{ stateService.language.scorecard.route }}</h4>
    <ninety-scorecard-settings />

    <terra-divider height="standard" />

    <h4 class="section-header">{{ stateService.language.rock.items }}</h4>
    <div class="section-options">
      <div class="option">
        <terra-switch
          attr.data-cy="configuration_toggle-button-{{ stateService.language.milestone.items }} on {{
            stateService.language.rock.items
          }}"
          [(ngModel)]="company.settings.milestones"
          (ngModelChange)="updateSettings('milestones', $event)">
          Show {{ stateService.language.milestone.items }} on {{ stateService.language.rock.items }}
        </terra-switch>
      </div>
      <div class="option flex2">
        <terra-switch
          attr.data-cy="configuration_toggle-button-{{ stateService.language.todo.items }} for {{
            stateService.language.milestone.items
          }}"
          class="milestone-todos-toggle"
          [ngModel]="!company.settings.preventMilestonesTurningIntoTodos"
          (change)="updateMilestonesTodos($event)">
          Automatically create {{ stateService.language.todo.items }} for {{ stateService.language.milestone.items }}
          not completed 7 days before due date
          <terra-description>
            This process runs once a day at 06:00 UTC and will reflect the toggle's state at that moment.
          </terra-description>
        </terra-switch>
      </div>
    </div>

    <terra-divider height="standard" />

    <h4 class="section-header">{{ stateService.language.issue.items }}</h4>
    <div class="section-options">
      <div class="option">
        <terra-switch
          attr.data-cy="configuration_toggle-button-{{ stateService.language.issue.item }}"
          [(ngModel)]="company.settings.issueRating"
          (ngModelChange)="updateSettings('issueRating', $event)">
          Show {{ stateService.language.issue.item }} prioritization
          <terra-description>
            Display an additional field next to your {{ stateService.language.issue.items }} that allows you to
            prioritize from 1 to 5.
          </terra-description>
        </terra-switch>
      </div>
      <div class="option">
        <terra-switch
          attr.data-cy="configuration_toggle-button-{{ stateService.language.meeting.item }}"
          [(ngModel)]="company.settings.issueVoting"
          (ngModelChange)="updateSettings('issueVoting', $event)">
          Voting
          <terra-description>
            Allow {{ stateService.language.meeting.item }} attendees to vote on
            {{ stateService.language.issue.item }} for prioritization during a {{ stateService.language.meeting.item }}.
          </terra-description>
        </terra-switch>
      </div>
    </div>

    <terra-divider height="standard" />
    <h4 class="section-header">{{ stateService.language.todo.items }}</h4>
    <div class="section-options">
      <div class="option">
        <terra-switch
          attr.data-cy="configuration_toggle-button-{{ stateService.language.todo.item }}"
          [(ngModel)]="company.settings.agreementBasedTodos"
          (ngModelChange)="updateSettings('agreementBasedTodos', $event)">
          Agreements-Based {{ stateService.language.todo.items }}
          <terra-description>
            Allow the creator and owner of a {{ stateService.language.todo.item }} to review, negotiate, and accept the
            {{ stateService.language.todo.item }} with an acceptance workflow.
          </terra-description>
        </terra-switch>
      </div>
    </div>

    <terra-divider height="short" />

    <h4 class="section-header">{{ stateService.language.vto.vision }}</h4>
    <ninety-vto-config-settings></ninety-vto-config-settings>

    <terra-divider height="tall" />

    <h4 class="section-header mt-46">Henryx</h4>
    <div class="section-options">
      <div class="option">
        <terra-switch
          attr.data-cy="configuration_toggle-button-{{ stateService.language.rock.items }}/{{
            stateService.language.todo.items
          }}/{{ stateService.language.issue.items }}"
          matTooltip="Allow users in your account to receive notifications and send {{
            stateService.language.rock.items
          }}/{{ stateService.language.todo.items }}/{{ stateService.language.issue.items }} to their teams via text."
          [(ngModel)]="company.settings.smsEnabled"
          (change)="updateSmsSetting($event)">
          SMS Actions (beta)
        </terra-switch>
      </div>
    </div>

    <ng-container *ngIf="this.stateService.isAdminOrOwner">
      <terra-divider height="tall" />

      <h4 class="section-header mt-46">Security</h4>
      <div class="section-options">
        <div class="option">
          <terra-switch
            attr.data-cy="configuration_toggle-button-{{ company.settings.requireMfa }}"
            matTooltip="Turning this on will require all users in this company to use a secondary code verification via text message on login."
            [disabled]="spinner"
            [(ngModel)]="company.settings.requireMfa"
            (change)="updateMfaSetting($event)">
            Require MFA
          </terra-switch>
        </div>
      </div>

      <terra-divider height="tall" />

      <h4 class="section-header mt-46">Private {{ stateService.language.acChart.route }}</h4>
      <div class="section-options">
        <div class="option">
          <terra-switch
            attr.data-cy="configuration_toggle-button-{{ stateService.language.acChart.route }}"
            class="private-accountability-chart-toggle"
            aria-label="Private Accountability Chart"
            [ngModel]="company.settings.privateAccountabilityChart"
            (ngModelChange)="updateAccountabilityChartSettings($event)">
            Toggling this on will make the {{ stateService.language.acChart.route }}
            unavailable to anyone not an owner, admin, or on the Leadership Team.
          </terra-switch>
        </div>
      </div>

      <terra-divider />
      <ninety-mastery-config
        [title]="stateService.language.mastery.route"
        [mastery]="company.settings.mastery"
        subText="The customizable {{
          stateService.language.mastery.route
        }} allows you to learn and track mastery across your teams"
        (updateMastery)="onMasteryUpdated($event)"
        [masteryItemLanguage]="stateService.language.mastery.item"
        headerClass="padding-top-18"></ninety-mastery-config>

      <!-- Temporarily hiding this based on requirements from DEV-7820. We may add this back in the near future -->
      <!--      <mat-divider class="top-space-30"></mat-divider>-->
      <!--      <h4 class="section-header mt-46">{{ stateService.language.feedback.route }}</h4>-->
      <!--      <div class="company-feedback-bar__container">-->
      <!--        <h4>Set The Bar</h4>-->
      <!--        <p class="bar-heading">A person is <b>below</b> The Bar when:</p>-->
      <!--        <div class="indented-bar__infos">-->
      <!--          <p class="core-values__infos">-->
      <!--            <b>Core Values: </b>Any minus (<b class="red">-</b>) response and&nbsp;-->
      <!--            <span class="bar-dropdown">-->
      <!--              <mat-form-field>-->
      <!--                <mat-select-->
      <!--                  data-cy="configuration_set-the-bar"-->
      <!--                  [disabled]="!companySltVto"-->
      <!--                  [(ngModel)]="company.acceptableStandards.coreValueBar"-->
      <!--                  (ngModelChange)="updateSub.next('acceptableStandards')">-->
      <!--                  <mat-option data-cy="configuration_core-value_default-option" [value]="0">0</mat-option>-->
      <!--                  <mat-option-->
      <!--                    attr.data-cy="configuration_core-value_option-{{ i + 1 }}"-->
      <!--                    *ngFor="let amount of companySltVto?.coreValues; let i = index"-->
      <!--                    [value]="i + 1">-->
      <!--                    {{ i + 1 }}-->
      <!--                  </mat-option>-->
      <!--                </mat-select>-->
      <!--              </mat-form-field> </span-->
      <!--            >&nbsp; amount of <b class="yellow">+/-</b> responses.-->
      <!--          </p>-->
      <!--          <p>-->
      <!--            <i-->
      <!--              >(Your company has <b>{{ companySltVto?.coreValues ? companySltVto.coreValues.length : 'N/A' }}</b> Core-->
      <!--              Values on the {{ stateService.language.vto.item }})</i-->
      <!--            >-->
      <!--          </p>-->
      <!--          <p class="top-space-15"><b>{{ stateService.language.feedback.CCC }}: </b>Any "no" response.</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </ng-container>
  </mat-card>
</div>
