import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PastMeetingsTableComponent } from '../past-meetings-table/past-meetings.component';

@Component({
  selector: 'ninety-past-meetings-page',
  standalone: true,
  imports: [CommonModule, PastMeetingsTableComponent],
  template: ` <ninety-past-meetings></ninety-past-meetings> `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PastMeetingsPageComponent {}
