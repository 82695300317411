<div
  class="terra-input"
  (click)="focus()"
  [ngClass]="{
    'terra-input--disabled': disabled,
    'terra-input--invalid': _terraErrorStateMatcher.isErrorState(_ngControl),
    'terra-input--readonly': readonly
  }">
  <span class="terra-input__slot">
    <ng-content select="terra-input-prefix" />
  </span>
  <input
    type="text"
    #input
    class="terra-input__input"
    [attr.placeholder]="placeholder || null"
    [disabled]="disabled"
    [readonly]="readonly"
    [autocomplete]="autocomplete"
    [maxlength]="maxlength || null"
    [(ngModel)]="_inputValue"
    (ngModelChange)="_onModelChange($event)"
    (blur)="_blurred($event)"
    (focus)="_focused($event)"
    [attr.inputmode]="inputmode || null"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledby]="ariaLabelledby"
    [attr.aria-describedby]="ariaDescribedby" />
  <span class="terra-input__slot">
    <ng-content select="terra-input-suffix" />
  </span>
</div>
