import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { TerraIconModule } from '@ninety/terra';
import { ActionBarComponent, QuickFilterComponent, ButtonComponent } from '@ninety/ui/legacy/components/index';
import { Team } from '@ninety/ui/legacy/shared/index';

import { MeetingsTab } from '../../_models';
import { MeetingsFacade } from '../../meetings.facade';

@Component({
  selector: 'ninety-meetings-action-bar',
  standalone: true,
  imports: [CommonModule, ActionBarComponent, QuickFilterComponent, TerraIconModule, ButtonComponent, LetDirective],
  template: `
    <div *ngrxLet="vm$ as vm">
      <ninety-action-bar *ngIf="vm.selectedTeam">
        <div class="action-bar-left">
          <ninety-quick-filter
            pillTitle="Team"
            [pillIcon]="'users-three'"
            (selectionChange)="onSelectionChange($event)"
            [options]="vm.teams"
            [disabled]="vm.loading"
            data-cy="team-filter"
            aria-label="Team filter"
            listFixedLocation="below">
          </ninety-quick-filter>
          <ninety-quick-filter
            pillTitle="Type"
            [options]="vm.meetingTypes"
            (change)="facade.meetingTypeSelected($event)"
            [disabled]="vm.loading"
            data-cy="type-filter"
            aria-label="Type filter"
            [ngClass]="vm.weeklyMeetingOnlyFeature || activeTab !== MeetingsTab.Past ? 'hide-quick-filter' : ''">
          </ninety-quick-filter>
        </div>
        <div class="action-bar-right" [ngClass]="activeTab !== MeetingsTab.Agendas ? 'hide-print-actions-filter' : ''">
          <ninety-button
            *ngIf="activeTab === MeetingsTab.Past"
            primary
            stroked
            (click)="facade.downloadExcel()"
            [disabled]="vm.loading"
            data-cy="export-excel"
            class="export-excel-btn">
            <terra-icon
              matTooltip="Export to Excel"
              matTooltipPosition="below"
              matTooltipClass="ninety-tooltip"
              icon="download"
              aria-hidden="true" />
          </ninety-button>
          <ninety-button
            primary
            stroked
            (click)="facade.printAgenda(vm.weeklyMeetingOnlyFeature)"
            [disabled]="vm.loading"
            data-cy="print-meeting-agenda"
            listFixedLocation="below">
            <terra-icon icon="file-print" aria-hidden="true" /> Print {{ vm.language.meeting.item }} Agenda
          </ninety-button>
        </div>
      </ninety-action-bar>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      ninety-quick-filter,
      .export-excel-btn {
        margin-right: 10px;
      }

      .action-bar-right {
        @media (max-width: 599px) {
          display: none;
        }
      }

      .action-bar-left {
        display: flex;
        align-items: center;
      }

      .hide-filter-bar,
      .hide-quick-filter,
      .hide-print-actions {
        display: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingsActionBarComponent {
  facade = inject(MeetingsFacade);
  MeetingsTab = MeetingsTab;
  vm$ = {
    language: this.facade.language$,
    selectedTeam: this.facade.selectedTeam$,
    teams: this.facade.teamsOptions$,
    loading: this.facade.loading$,
    meetingTypes: this.facade.meetingTypeOptions$,
    weeklyMeetingOnlyFeature: this.facade.weeklyMeetingsOnlyFeature$,
  };

  @Input() activeTab?: string;

  onSelectionChange(team: Team | Team[]) {
    if (this.activeTab === MeetingsTab.Past) {
      this.facade.pastMeetingsTeamSelected(team);
    }
    if (this.activeTab === MeetingsTab.Upcoming) {
      this.facade.teamSelected(team);
    }
    if (this.activeTab === MeetingsTab.Agendas) {
      this.facade.teamAgendaSelected(team);
    }
  }
}
