import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import { NinetyFeatures } from '../../_shared/models/company/company-pricing-tiers';
import { selectFeatureEnabled } from '../../_state';

/**
 * Guard that checks if the user has access to a specific feature.
 * If the user doesn't have access, it redirects to a passed in url or default tiered page if missing.
 */
export function hasFeatureAccessGuard(feature: NinetyFeatures, noAccessUrl?: string): CanActivateFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const redirectUrl = noAccessUrl ?? `no-access/${feature}`;

    return store.select(selectFeatureEnabled(feature)).pipe(
      take(1),
      map(hasFeatureAccess => hasFeatureAccess || router.createUrlTree([redirectUrl]))
    );
  };
}
