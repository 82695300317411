import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, Subject } from 'rxjs';

import { TerraDividerModule, TerraIconModule, TerraIconName, TerraSizing } from '@ninety/terra';

import { ButtonComponent } from '../buttons/button/button.component';
import { PopoverOriginDirective } from '../popover/popover-origin.directive';
import { PopoverComponent } from '../popover/popover.component';

@Component({
  selector: 'ninety-error-overlay',
  standalone: true,
  imports: [
    CommonModule,
    TerraIconModule,
    ButtonComponent,
    PopoverComponent,
    PopoverOriginDirective,
    TerraDividerModule,
  ],
  templateUrl: './error-overlay.component.html',
  styleUrls: ['./error-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorOverlayComponent {
  @Input() errorIcon: TerraIconName = 'info-circle';
  @Input() iconSize: TerraSizing = 24;
  @Input() overlayTitle = 'Issue Found';

  /** Error Information */
  @Input() errorTitle: string;
  /** Can use content projection [errorContent] if wanting more control of error description/body */
  @Input() errorDescription?: string;

  /** Error Actions, can use content projection [errorActions] if wanting more control of error actions,
   * otherwise can use default button and pass in label or hide it altogether */
  /** Label for the default action button that will close the error overlay */
  @Input() actionButtonLabel = 'Got it';
  /** In case you don't want any action button */
  @Input() hideActionButton = false;
  /** When user clicks acknowledge button, not just when overlay closes */
  @Output() acknowledged = new EventEmitter<void>();

  /** allow outside entity to open/close the overlay */
  @Input() showOverlay = false;
  /** In case you want to do something when the overlay is closed */
  @Output() toggleOverlay = new EventEmitter<boolean>();

  private mouseEnter$ = new Subject<void>();
  private _isOpen = false;

  constructor(private cdr: ChangeDetectorRef, private destroyRef: DestroyRef) {
    this.mouseEnter$.pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef)).subscribe(() => this.show());
  }

  onClick() {
    this._isOpen = true;
    this.mouseEnter$.next();
  }

  show(): void {
    if (!this._isOpen) return;
    this.showOverlay = true;
    this.toggleOverlay.emit(true);
    this.cdr.detectChanges();
  }

  hide(): void {
    this.showOverlay = false;
    this.toggleOverlay.emit(false);
    this.cdr.detectChanges();
  }

  acknowledge(): void {
    this.hide();
    this.acknowledged.emit();
  }
}
