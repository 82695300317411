import { createSelector } from '@ngrx/store';

import { selectMazState } from '@ninety/getting-started/_state';
import { MazStatus } from '@ninety/ui/legacy/shared/models/maz/maz-status.enum';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectFeatureFlag } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.selectors';
import { selectCurrentUser } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectCurrentPerson } from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';

export namespace MazSelectors {
  export const selectUserMazStatusEnabled = createSelector(
    selectCurrentPerson,
    ({ mazStatus }) => mazStatus !== MazStatus.DISABLED
  );
  export const selectMazChatEnabled = createSelector(
    selectFeatureFlag(FeatureFlagKeys.mazChat),
    selectUserMazStatusEnabled,
    (mazChatFlag, userEnabledMazChat) => mazChatFlag && userEnabledMazChat
  );
  export const selectIsMazChatExpanded = createSelector(selectMazState, state => state.mazChatExpanded);
  export const selectCommonQuestions = createSelector(selectMazState, state => state.commonQuestions);
  export const selectConversationHistory = createSelector(selectMazState, state => state.mazMessages);
  export const selectWaitingForMazResponse = createSelector(selectMazState, state => state.waitingForMazResponse);

  export const selectShowMazChatToggle = createSelector(
    selectFeatureFlag(FeatureFlagKeys.mazChat),
    selectCurrentUser,
    (mazChatFlag, { guideEnabled }) => mazChatFlag && guideEnabled
  );
}
