<ng-container
  *ngIf="{
    open: open$ | async,
  } as state">
  <div id="main-layout">
    <div
      class="content"
      [style.overflow]="overflowType"
      [class.hasOpenPanel]="state.open"
      [class.terrafy-content]="(enableTerrafyToolbarsFeatureFlag$ | async) === true">
      <ng-content select="[page-sidebar]"></ng-content>
      <!-- See SCSS definition of with-bottom-padding and MainLayoutService for more info about edge cases of bottom padding -->
      <div class="page-without-sidebar with-bottom-padding" id="main-page-content" [style.overflow]="overflowType">
        <ng-content select="[page-banner]"></ng-content>
        <ninety-main-header [inMeeting]="inMeeting" [inFeedback]="inFeedback"></ninety-main-header>

        <div class="page-and-detail-wrapper">
          <!-- This template variable exposes the scrollable page content for the ScrollSpy directive -->
          <div #pageContent class="page-wrapper">
            <ng-content select="[page-content]"></ng-content>
            <ninety-what-next *ngIf="(guideEnabled$ | async) !== true"></ninety-what-next>
          </div>

          <aside
            class="detail-wrapper"
            *ngIf="(enableTerrafyToolbarsFeatureFlag$ | async) !== true"
            [class.hidden]="!state.open"
            [class.detail-card-v2-wrp]="isV2Detail$ | async"
            [class.enable-scrollbar]="(disableScrollbar$ | async) !== true">
            <router-outlet name="detail" (activate)="onActivateDetail()" (deactivate)="onDeactivateDetail()" />
          </aside>
        </div>

        <ninety-getting-started-guide *ngIf="showGettingStartedGuide$ | async" />
      </div>
      <aside
        *ngIf="(enableTerrafyToolbarsFeatureFlag$ | async) === true"
        class="detail-wrapper terrafy-sidebar"
        [class.hidden]="!state.open"
        [class.detail-card-v2-wrp]="isV2Detail$ | async"
        [class.enable-scrollbar]="(disableScrollbar$ | async) !== true">
        <router-outlet name="detail" (activate)="onActivateDetail()" (deactivate)="onDeactivateDetail()" />
      </aside>
      <mat-spinner *ngIf="spinnerService.primary$ | async" class="main-spinner" color="accent"></mat-spinner>
    </div>
  </div>
</ng-container>
