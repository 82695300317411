<div
  class="inline-editable-due-date"
  [class.show-year]="showDueDateYear"
  (click)="openCalendar($event)"
  [class.disabled]="disabled">
  <mat-form-field appearance="outline" (click)="openCalendar($event)">
    <input
      #dueDateInput
      readonly="readonly"
      attr.data-cy="todos_due-date-{{ dueDate | date : 'mediumDate' }}"
      [class.red]="overDue"
      [class.muted]="muted"
      [class.orange]="dueToday"
      matInput
      [matDatepicker]="dueDatePicker"
      placeholder="Due Date"
      [ngModel]="dueDate"
      (dateChange)="onDueDateChange($event)"
      required
      (click)="openCalendar($event)"
      ninetyStopPropagation
      ninetyPreventDefault
      matTooltipPosition="above"
      matTooltip="Edit due date"
      [class.show-year]="showDueDateYear"
      aria-label="date picker" />
    <mat-datepicker-toggle *ngIf="!disabled" matSuffix [for]="dueDatePicker" [disableRipple]="true">
      <terra-icon matDatepickerToggleIcon icon="edit" class="due-date-icon" />
    </mat-datepicker-toggle>
    <mat-datepicker #dueDatePicker></mat-datepicker>
  </mat-form-field>
</div>
