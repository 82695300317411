import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';

// eslint-disable-next-line terra/enforce-terra-module-import
import { TerraBooleanAttributeModule, TerraIconComponent, TerraIconModule } from '@ninety/terra';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[ninety-cm-button]',
  standalone: true,
  imports: [CommonModule, TerraIconModule],
  templateUrl: './cm-button.component.html',
  styleUrls: ['./cm-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.ninety-cm-button--full-width]': 'fullWidth',
  },
})
export class NinetyCmButtonComponent implements AfterContentInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ContentChildren(TerraIconComponent) private _icons: QueryList<TerraIconComponent<any>>;

  @Input() get fullWidth(): boolean {
    return this._fullWidth;
  }

  set fullWidth(value: TerraBooleanAttributeModule) {
    this._fullWidth = coerceBooleanProperty(value);
  }
  protected _fullWidth = false;

  private _destroyed$ = new Subject<void>();

  ngAfterContentInit(): void {
    this._icons.changes.pipe(startWith(this._icons), takeUntil(this._destroyed$)).subscribe(() => {
      this._icons.forEach(icon => {
        icon.size = '20';
      });
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
