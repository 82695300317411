import { CommonModule } from '@angular/common';
import { Component, DoCheck, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LetDirective } from '@ngrx/component';
import { exhaustMap, Subscription } from 'rxjs';

import { TerraCheckboxModule, TerraFormFieldModule, TerraIconModule, TerraTextInputModule } from '@ninety/terra';

import { ButtonComponent } from '../../../_components/buttons/button/button.component';
import { ButtonRowComponent } from '../../../_components/buttons/button-row/button-row.component';
import { IntlTelInputComponent } from '../../../_components/inputs/intl-tel-input/intl-tel-input.component';
import { AuthService } from '../../../_core/services/auth.service';
import { CognitoMfaType, IdentityProviderService } from '../../../_core/services/identity-provider.service';
import { PersonService } from '../../../_core/services/person.service';
import { SpinnerService } from '../../../_core/services/spinner.service';
import { StateService } from '../../../_core/services/state.service';

export interface ConfirmIdentityDialogData {
  email?: string;
  password?: string;
  phone?: string;
  personId?: string; // for when we haven't completed full login init, like capturing tel number before proceeding init
  status: ConfirmIdentityDialogStatus;
  showCloseButton?: boolean;
}

export enum ConfirmIdentityDialogStatus {
  VerifyEmail = 'VERIFY_EMAIL',
  ChallengeMFA = 'CHALLENGE_MFA',
  UpdateNumber = 'UPDATE_NUMBER',
  InitNumber = 'INIT_NUMBER',
  ConfirmNumber = 'CONFIRM_NUMBER',
  ConfirmMFA = 'CONFIRM_MFA',
}

@Component({
  selector: 'ninety-confirm-identity',
  templateUrl: './confirm-identity-dialog.component.html',
  styleUrls: ['./confirm-identity-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LetDirective,
    IntlTelInputComponent,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    ButtonComponent,
    ButtonRowComponent,
    TerraIconModule,
    TerraCheckboxModule,
    TerraTextInputModule,
    TerraFormFieldModule,
  ],
})
export class ConfirmIdentityDialogComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('ngxIntlTelInput') ngxIntlTelInputRef: ElementRef<HTMLElement>;
  expandContentHeight = false;

  title: string;
  subtitle: string;
  code = new FormControl('', [Validators.required, Validators.pattern(/^\d{6}$/)]);
  statuses = ConfirmIdentityDialogStatus;
  subscriptions = new Subscription();
  phoneForm = new FormGroup({
    phone: new FormControl<string | null>(null, [Validators.required]),
    sms_consent: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    public dialogRef: MatDialogRef<ConfirmIdentityDialogComponent>,
    private authService: AuthService,
    public idpService: IdentityProviderService,
    public personService: PersonService,
    public stateService: StateService,
    public snackBar: MatSnackBar,
    public spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmIdentityDialogData
  ) {}

  ngOnInit() {
    switch (this.data.status) {
      case ConfirmIdentityDialogStatus.UpdateNumber:
        this.phoneForm.controls['phone'].setValue(this.data.phone);
        break;
      case ConfirmIdentityDialogStatus.ConfirmMFA:
      case ConfirmIdentityDialogStatus.ConfirmNumber:
        this.idpService.verifyTelNumber().subscribe({
          // eslint-disable-next-line
          error: (err: any) => {
            //in case user sent too many sms codes
            this.snackBar.open(err.message, null, { duration: 5000 });
          },
        });
        break;
      default:
        break;
    }
  }

  ngDoCheck() {
    if (this.data.status === this.statuses.UpdateNumber) {
      this.expandContentHeight = !!this.ngxIntlTelInputRef?.nativeElement?.querySelector('.show');
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  clear(): void {
    this.code.reset();
  }

  closeFromButton(): void {
    this.spinnerService.stop();
    this.dialogRef.close({ closedFromBtn: true });
  }

  onUpdateNumberToggle() {
    this.data.status = this.statuses.UpdateNumber;
    this.clear();
  }

  handleError(err: any): void {
    switch (err.code) {
      case 'CodeMismatchException':
        console.log('CodeMismatchException - The code you entered was incorrect. Please try again');
        this.snackBar.open('The code you entered was incorrect. Please try again.', null, { duration: 5000 });
        this.clear();
        break;
      case 'ExpiredCodeException':
        console.log('ExpiredCodeException - The code you entered has expired. Please request a new code');
        this.snackBar.open('The code you entered has expired. Please request a new code.', null, { duration: 5000 });
        this.clear();
        break;
      default:
        console.log('UknownIDPError', err);
        this.snackBar.open('An error occurred, Please try again.', null, { duration: 5000 });
        this.clear();
        break;
    }
  }

  resendEmail(): void {
    this.idpService.resendSignup(this.data.email).subscribe({
      next: () => this.snackBar.open('Email sent', null, { duration: 5000 }),
      error: () => this.snackBar.open('Could not send email', null, { duration: 5000 }),
    });
  }

  onResendChallengeMfa() {
    this.spinnerService.start();
    // MFA challenge resend feature doesn't exist for sign-in
    // requires a relogin hack https://github.com/aws-amplify/amplify-js/issues/6676
    this.authService.loginToIdp({ email: this.data.email, password: this.data.password }).subscribe({
      // this scenario is an unlikely edge case of no longer needing MFA
      next: () => {
        this.spinnerService.stop();
        // just close the dialog to relogin normally
        this.dialogRef.close();
      },
      // eslint-disable-next-line
      error: (err: any) => {
        this.spinnerService.stop();
        if (err?.message === 'SMS_MFA') {
          this.snackBar.open('New verification code sent via SMS', null, { duration: 5000 });
        } else {
          //in case user sent too many sms codes
          this.snackBar.open(err.message || err, null, { duration: 5000 });
        }
      },
    });
  }

  onResendSendSmsCode() {
    this.idpService.resendSmsCode().subscribe({
      next: () => {
        console.log('User requested new SMS verification code');
        this.snackBar.open('New verification code sent via SMS', null, { duration: 5000 });
      },
      // eslint-disable-next-line
      error: (err: any) => {
        //in case user sent too many sms codes
        this.snackBar.open(err.message || err, null, { duration: 5000 });
      },
    });
  }

  updatePhoneNumber() {
    const phoneControl = this.phoneForm.controls['phone'];
    if (phoneControl.invalid) return;
    const newNumber = phoneControl.value;
    const personId = this.data.personId ? this.data.personId : this.stateService.currentUser$?.value?.personId;

    this.personService
      .updatePerson({ primaryPhoneNumber: newNumber }, personId)
      .pipe(
        exhaustMap(() => this.idpService.updateTelNumber(newNumber)),
        exhaustMap(() => this.idpService.verifyTelNumber())
      )
      .subscribe({
        next: () => {
          this.clear();
          this.data.status = ConfirmIdentityDialogStatus.ConfirmNumber;
          this.spinnerService.stop();
        },
        // eslint-disable-next-line
        error: (err: any) => {
          this.snackBar.open(err.message, null, { duration: 5000 });
          this.spinnerService.stop();
        },
      });
    // don't close modal, user still needs to enter code
  }

  submitCode(): void {
    if (!this.code.valid) return;

    const code = this.code.value.trim();
    this.spinnerService.start();

    switch (this.data.status) {
      case ConfirmIdentityDialogStatus.ConfirmNumber:
        this.idpService
          .submitVerifyTelNumber(code)
          .pipe(exhaustMap(() => this.idpService.setMfaType(CognitoMfaType.SMS)))
          .subscribe({
            next: () => {
              this.spinnerService.stop();
              this.snackBar.open('Mobile Device Number Verified', null, { duration: 20000 });
              this.dialogRef.close(ConfirmIdentityDialogStatus.ConfirmNumber);
            },
            error: (err: unknown) => {
              this.spinnerService.stop();
              this.handleError(err);
            },
          });
        break;

      case ConfirmIdentityDialogStatus.ConfirmMFA:
        this.idpService
          .submitVerifyTelNumber(code)
          .pipe(exhaustMap(() => this.idpService.setMfaType(CognitoMfaType.SMS)))
          .subscribe({
            next: () => {
              this.spinnerService.stop();
              this.snackBar.open('MFA Confirmed', null, { duration: 2000 });
              this.dialogRef.close(ConfirmIdentityDialogStatus.ConfirmMFA);
            },
            error: (err: unknown) => {
              console.error('ConfirmMFA - Error occurred confirming MFA', err);
              this.spinnerService.stop();
              this.handleError(err);
            },
          });
        break;

      case ConfirmIdentityDialogStatus.ChallengeMFA:
        this.idpService.confirmSignin(code).subscribe({
          next: resp => {
            this.spinnerService.stop();
            this.snackBar.open('MFA Confirmed', null, { duration: 2000 });
            this.dialogRef.close(resp);
          },
          error: (err: unknown) => {
            console.error('ChallengeMFA - Error occurred confirming MFA', err);
            this.spinnerService.stop();
            this.handleError(err);
          },
        });
        break;

      case ConfirmIdentityDialogStatus.VerifyEmail:
        this.idpService.confirmSignup(this.data.email, code).subscribe({
          next: () => {
            this.spinnerService.stop();
            this.dialogRef.close(ConfirmIdentityDialogStatus.VerifyEmail);
          },
          error: (err: unknown) => {
            this.spinnerService.stop();
            this.handleError(err);
          },
        });
        break;
    }
  }
}
