import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { startWith, Subject, takeUntil } from 'rxjs';

import { TerraSizing } from '../../models';
import { TerraTheme } from '../../models/terra-component-models';
import { TerraInputBoolean } from '../../models/terra-input-boolean.models';
import { TerraBadgeComponent } from '../terra-badge/terra-badge.component';
import { TerraIconModule } from '../terra-icon';

export type TerraAvatarSize = 'tiny' | 'very-small' | 'small' | 'medium' | 'large' | 'xl' | 'xxl';

@Component({
  selector: 'terra-avatar',
  standalone: true,
  exportAs: 'terraAvatar',
  imports: [CommonModule, TerraIconModule],
  templateUrl: './terra-avatar.component.html',
  styleUrls: ['./terra-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerraAvatarComponent implements OnInit, AfterContentInit, OnDestroy {
  /**
   * Reference to the wrapping div element where classes will be added to
   */
  @ViewChild('avatar', { static: true }) private _avatar!: ElementRef<HTMLElement>;

  @ContentChildren(TerraBadgeComponent) private _badges!: QueryList<TerraBadgeComponent>;
  private _destroyed$ = new Subject<void>();

  /**
   * Size of the avatar
   * @default large
   */
  @Input() get size(): TerraAvatarSize {
    return this._size;
  }
  set size(value: TerraAvatarSize) {
    this._removeClass('size', this._size);
    this._size = value;
    this._addClass('size', this._size);
    this._updateDisplayedInitials();
    this._changeDetectorRef.markForCheck();
  }
  private _size: TerraAvatarSize = 'large';

  /**
   * Image source for the avatar image
   */
  @Input() get src(): string {
    return this._src;
  }
  set src(value: string) {
    this._src = value;
    this._changeDetectorRef.markForCheck();
  }
  private _src = '';

  /**
   * Initials to display on the avatar if image is not available
   */
  @Input() get initials(): string {
    return this._initials;
  }
  set initials(value: string) {
    this._initials = value;
    this._updateDisplayedInitials();
    this._changeDetectorRef.markForCheck();
  }
  private _initials = '';
  protected _displayedInitials = '';

  /**
   * Optional alternative text to visually describe the avatar image (accessibility)
   */
  @Input() get altText(): string {
    return this._altText;
  }
  set altText(value: string) {
    this._altText = value;
    this._changeDetectorRef.markForCheck();
  }
  private _altText = '';

  /**
   * Set whether to show the avatar in the inactive state
   * @default false
   */
  @Input() get inactive(): boolean {
    return this._inactive;
  }
  set inactive(value: TerraInputBoolean) {
    this._inactive = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _inactive = false;

  /**
   * Set whether the avatar's hover states are enabled or disabled
   * @default false
   */
  @Input() get interactive(): boolean {
    return this._interactive;
  }
  set interactive(value: TerraInputBoolean) {
    this._interactive = coerceBooleanProperty(value);
    this._changeDetectorRef.markForCheck();
  }
  private _interactive = false;

  /**
   * Theme of the avatar
   * @default light
   */
  @Input() get theme(): TerraTheme {
    return this._theme;
  }
  set theme(value: TerraTheme) {
    this._removeClass('theme', this._theme);
    this._theme = value;
    this._addClass('theme', this._theme);
    this._changeDetectorRef.markForCheck();
  }
  private _theme: TerraTheme = 'light';

  constructor(private readonly _changeDetectorRef: ChangeDetectorRef, private readonly _renderer: Renderer2) {}

  ngOnInit() {
    this._addClass('size', this.size);
    this._addClass('theme', this.theme);
  }

  ngAfterContentInit(): void {
    this._badges.changes.pipe(startWith(this._badges), takeUntil(this._destroyed$)).subscribe(() => {
      this._badges.forEach(badge => {
        badge.size = 'small';
        badge.stroke = 'true';
        badge._detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  // Logic for shortening initials on smaller avatar sizes
  private _updateDisplayedInitials(): void {
    if (this._size === 'tiny' || this._size === 'very-small') {
      this._displayedInitials = this.initials.charAt(0);
    } else {
      this._displayedInitials = this.initials;
    }
  }

  protected _getIconSize(): TerraSizing {
    let userIconSize: TerraSizing;

    switch (this.size) {
      case 'tiny':
        userIconSize = 8;
        break;
      case 'very-small':
        userIconSize = 12;
        break;
      case 'small':
        userIconSize = 16;
        break;
      case 'medium':
      case 'large':
        userIconSize = 20;
        break;
      case 'xl':
        userIconSize = 32;
        break;
      case 'xxl':
        userIconSize = 40;
        break;
      default:
        userIconSize = 20;
    }

    return userIconSize;
  }

  /**
   * Helper function for adding proper styles to the avatar
   */
  private _addClass(modifier: string, value: string): void {
    if (this._avatar) {
      this._renderer.addClass(this._avatar.nativeElement, `terra-avatar--${modifier}-${value}`);
    }
  }

  /**
   * Helper function to remove previously added classes from the avatar
   */
  private _removeClass(modifier: string, value: string): void {
    if (this._avatar) {
      this._renderer.removeClass(this._avatar.nativeElement, `terra-avatar--${modifier}-${value}`);
    }
  }
}
