<div class="spinner-div">
  <mat-spinner color="accent" *ngIf="spinner" />
</div>

<ng-container
  *ngrxLet="{
    isMinRole: isMinRole$
  } as vm"
  [ngSwitch]="template">
  <ng-container *ngSwitchCase="'primary'">
    <div *ngIf="item?._id" class="attachments" [class.orange]="orangeHeader">
      <h4 [class.muted]="muted">Attachments</h4>
      <button
        type="button"
        mat-icon-button
        class="orange-on-hover"
        (click)="fileInput.click()"
        *ngIf="!cascaded && vm.isMinRole"
        matTooltip="Upload Attachment"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip">
        <terra-icon icon="add-circle" />
      </button>
      <form hidden>
        <input data-cy="attachments_upload-attachment" type="file" #fileInput (change)="uploadFile(fileInput)" />
      </form>
    </div>

    <ng-container *ngIf="item.attachments?.length">
      <mat-list
        class="ninety-list attachment-list"
        cdkDropList
        [cdkDropListData]="item.attachments"
        [cdkDropListDisabled]="!vm.isMinRole"
        (cdkDropListDropped)="drop($event)">
        <mat-list-item
          class="list-item"
          *ngFor="let attachment of item.attachments | orderBy : 'ordinal'; let i = index"
          cdkDrag
          matRipple>
          <div class="drag-handle" cdkDragHandle fxHide.lt-sm *ngIf="vm.isMinRole">
            <terra-icon icon="drag" />
          </div>
          <div attr.data-cy="attachments_title-{{ attachment.fileName }}" class="attachment-title">
            {{ attachment.fileName }}
          </div>
          <button
            type="button"
            data-cy="attachments_download-attachment"
            mat-icon-button
            class="orange-on-hover attachment-btn"
            (click)="downloadAttachment(attachment)"
            matTooltip="Download Attachment"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="cloud-download" />
          </button>
          <button
            *ngIf="!cascaded && vm.isMinRole"
            type="button"
            data-cy="attachments_remove-attachment"
            mat-icon-button
            class="orange-on-hover attachment-btn"
            (click)="removeAttachment(attachment, i)"
            matTooltip="Delete Attachment"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="x" />
          </button>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'secondary'">
    <div class="attachments" [class.orange]="orangeHeader">
      <h4 [class.muted]="muted">Attachments</h4>
      <button
        *ngIf="!cascaded && vm.isMinRole"
        type="button"
        mat-icon-button
        class="orange-on-hover"
        (click)="fileInput.click()"
        matTooltip="Upload Attachment"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip">
        <terra-icon icon="add-circle" />
      </button>
      <form hidden>
        <span data-cy="attachments_add-multiple-attachments">
          <input multiple type="file" #fileInput (change)="addFileAttachment(fileInput)" />
        </span>
      </form>
    </div>

    <ng-container *ngIf="fileAttachments?.length">
      <mat-list class="ninety-list attachment-list">
        <mat-list-item class="list-item" *ngFor="let file of fileAttachments; let i = index">
          <div attr.data-cy="attachments_item-file-{{ file.name }}" class="attachment-title">{{ file.name }}</div>
          <button
            *ngIf="!cascaded && vm.isMinRole"
            type="button"
            data-cy="attachments_remove-attachment"
            mat-icon-button
            class="orange-on-hover attachment-btn"
            (click)="removeFileAttachment(i)"
            matTooltip="Delete Attachment"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="x" />
          </button>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="'create'">
    <div class="attachments-header">
      <ng-content select="[createAttachmentTitle]"></ng-content>
      <ninety-button
        *ngIf="!cascaded && vm.isMinRole && canEdit"
        icon
        flat
        class="upload-button no-padding"
        (click)="fileInput.click()"
        matTooltip="Upload Attachment"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip">
        <terra-icon icon="add-circle" />
      </ninety-button>
      <form hidden>
        <input multiple type="file" #fileInput (change)="addFileAttachment(fileInput)" />
      </form>
    </div>

    <ng-container *ngIf="fileAttachments?.length; else emptyMessage">
      <mat-list class="ninety-list">
        <mat-list-item class="list-item" *ngFor="let file of fileAttachments; let i = index">
          <div class="attachment-title">{{ file.name }}</div>
          <ninety-button
            *ngIf="canEdit"
            type="button"
            negative
            icon
            flat
            class="attachment-btn"
            (click)="removeFileAttachment(i)"
            matTooltip="Delete Attachment"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="delete" />
          </ninety-button>
        </mat-list-item>
      </mat-list>
    </ng-container>
    <ng-template #emptyMessage>
      <div class="no-attachments-message">No attachments have been added yet.</div>
    </ng-template>
  </ng-container>

  <ng-container *ngSwitchCase="'update'">
    <div *ngIf="item?._id" class="attachments-header">
      <!-- Projected Title -->
      <ng-content select="[updateAttachmentTitle]"></ng-content>
      <!-- Upload Attachment Button -->
      <ninety-button
        *ngIf="canEdit && !cascaded && vm.isMinRole"
        type="button"
        icon
        flat
        class="upload-button no-padding"
        (click)="fileInput.click()"
        matTooltip="Upload Attachment"
        matTooltipPosition="above"
        matTooltipClass="ninety-tooltip">
        <terra-icon icon="add-circle" />
      </ninety-button>
      <form hidden>
        <input type="file" #fileInput (change)="uploadFile(fileInput)" />
      </form>
    </div>

    <ng-container *ngIf="item.attachments?.length; else emptyMessage">
      <mat-list
        class="ninety-list"
        cdkDropList
        [cdkDropListData]="item.attachments"
        [cdkDropListDisabled]="!vm.isMinRole || item.attachments.length < 2 || !canEdit"
        (cdkDropListDropped)="drop($event)">
        <mat-list-item
          class="list-item"
          *ngFor="let attachment of item.attachments | orderBy : 'ordinal'; let i = index"
          cdkDrag
          matRipple>
          <!-- Drag Handle -->
          <div
            class="drag-handle"
            cdkDragHandle
            fxHide.lt-sm
            *ngIf="vm.isMinRole && item.attachments.length > 1 && canEdit">
            <terra-icon icon="drag" />
          </div>

          <!-- Attachment Title -->
          <div class="attachment-title">{{ attachment.fileName }}</div>

          <!-- Download Button -->
          <ninety-button
            icon
            flat
            class="attachment-btn"
            (click)="downloadAttachment(attachment)"
            matTooltip="Download Attachment"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="cloud-download" />
          </ninety-button>

          <!-- Delete Button -->
          <ninety-button
            *ngIf="canEdit && !cascaded && vm.isMinRole"
            (click)="removeAttachment(attachment, i)"
            flat
            icon
            class="attachment-btn"
            matTooltip="Delete Attachment"
            matTooltipPosition="above"
            matTooltipClass="ninety-tooltip">
            <terra-icon icon="x" />
          </ninety-button>
        </mat-list-item>
      </mat-list>
    </ng-container>
    <ng-template #emptyMessage>
      <div class="no-attachments-message">No attachments have been added yet.</div>
    </ng-template>
  </ng-container>
</ng-container>
