import { createActionGroup, props } from '@ngrx/store';

import { SeatDetailComponentViewType } from '@ninety/ui/legacy/shared/models/accountability-chart/seat-detail.model';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';

interface CommonOrgChartAnalyticsProps {
  chartId: string;
  seatId: string;
}

export const OrgChartAnalyticsActions = createActionGroup({
  source: 'Responsibilities - Analytics',
  events: {
    'Track Create Seat': props<{
      params: CommonOrgChartAnalyticsProps & {
        rolesCount: number;
        rolesWithDescriptionCount: number;
        from: 'create' | 'clone';
      };
    }>(),

    'Track Update Seat': props<{
      params: CommonOrgChartAnalyticsProps & {
        changedProperties: (keyof SeatModel)[];
        currentState: {
          rolesCount: number;
          rolesWithDescriptionCount: number;
        };
      };
    }>(),

    'Track Seat Reports To Change': props<{ params: CommonOrgChartAnalyticsProps & { source: 'legacy' | 'detail' } }>(),

    'Track Seat Attachment Event': props<{
      params: CommonOrgChartAnalyticsProps & { action: 'create' | 'reorder' | 'delete' };
    }>(),

    'Track Launch Seat Details': props<{ params: { mode: SeatDetailComponentViewType } }>(),
  },
});
