<ng-template #popover>
  <div class="cm-popover" (mouseenter)="_mouseEntered()" (mouseleave)="_mouseLeft()">
    <div class="cm-popover__title">
      <ng-content select="[headline]" />
    </div>
    <div class="cm-popover__body">
      <ng-content select="[description]" />
    </div>
    <ng-content slect="button[ninety-cm-button]" />
  </div>
</ng-template>
