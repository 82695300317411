// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --configuration production` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// This file is loaded by the default build configuration `ng build`.

// Interface must be defined in a separate file for fileReplacements to work
import { updateEnvironmentBasedOnHost } from './_utils';
import { NinetyFrontEndEnvironment } from './environment.interface';

let rootEnvironment: NinetyFrontEndEnvironment = {
  hostedEnvironment: window.location.hostname,
  production: false,
  host: window.location.host,
  apiOrigin: '',
  dashboardOrigin: '',
  mazApiOrigin: '',
  ngOrigin: '',
  refreshCookieName: 'DEVELOPMENT-.NINETY.IO-REFRESH-TOKEN',
  xsrfCookieName: 'DEVELOPMENT-.NINETY.IO-XSRF-TOKEN',
  stripePublishableKey: 'pk_test_PQOFD0pQwefNtBNDOu6ctoVq',
  froalaApiKey: 'iTB2xC5B3B3C2E2E1A1pZGCTRSAPJWTLPLZHTQQb1JGZxE2F2G2D1B10B2B3E6D1B1==',
  flatfileKey: '0b5957ab-cfb4-429c-a88c-e159fd9a4c89',
  appVersion: '1.0.0-dev',

  //cognito
  authenticationFlowType: 'USER_SRP_AUTH',
  cognitoDomain: 'ninety-dev1.auth.us-east-1.amazoncognito.com',
  redirectProtocol: 'https',
  region: 'us-east-1',
  userPoolId: 'us-east-1_0yFtMZvnR',
  userPoolWebClientId: 'kaflbn71uiubkmvhgqkbu1cgh',

  segmentApiKey: window.location.host.endsWith('.business')
    ? 'yw4MGAtYY53zzJeoQZUavqv8wW3wGBlg'
    : '0ynGaCw2SjPDISNlA4luS0fYw09CD9yx',
  pubnubPublishKey: 'pub-c-014ebe4e-8b5a-40f2-82a3-1fe2408bb9a5',
  pubnubSubscribeKey: 'sub-c-078ee240-33f9-11eb-bb60-1afa7ba42f78',
  pubnubPresenceExpiration: 150,

  //stripe
  monthlyPrice: 'price_1KFqhbCTlW8ESIfRMGorREaw',
  annualPrice: 'price_1KGUD0CTlW8ESIfRhJnn9Zxc',

  //paddle
  paddleAnnualPlanId: 7980,
  paddleMonthlyPlanId: 7977,
  paddleWeeks4PlanId: 33046,

  // partner config ids
  pinnacleTemplateId: '61853175e45f7d0009139688',
  fireproofTemplateId: '', // don't know what this is yet, but stubbing it in

  // Datadog
  dataDogClientToken: 'pubc24bd6a5616e3fa28ac2226f62a3b188',
  service: 'ng-ninety',
  env: 'dev',

  // Launch Darkly
  launchDarklySdkKey: '63371bfa8b368611950df785',

  // Google Recaptcha
  NINETY_GOOGLE_RECAPTCHA_SITEKEY: '6LdJ0HAmAAAAAFNAE8U6IKsmqS1Hoe50yKGUG1Wz',

  // Zapier API
  zapierClientId: 'UqojEVlS2HUnsfMxTwkhZKThPvKyfv8jxdjdCRr2',

  AG_GRID_LICENSE:
    // eslint-disable-next-line max-len
    'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-054984}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Humalytix}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Ninety}_only_for_{10}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Ninety}_need_to_be_licensed___{Ninety}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{30_March_2025}____[v3]_[0102]_MTc0MzI5MjgwMDAwMA==de755b5a15605fcb6d57986b51df462c',

  ACTIVE_REPORTS_KEY:
    // eslint-disable-next-line max-len
    '*.ninety.io,161249381814764#B1rnjotSeRBnSrpXZFFzboFDb6dWdrFkYSRHb8smWzIkYBpGZ4YXcF34L7dzdCVncplUd6RGavcjNol7UIJFd65We7gDWzcHTpR7KlJ7SsRzMURTZJZHNjtESERWSZZ4VZNUZVpEVvklVEZGU8M7ZTJ6YhFFe9F5aNVGb4oXTEVnUvgUTvNkS6sWY9pkU0RFO9cjdtplerADahJ5Ky2ETOBFOJ94L6NUe4xmW7l4LvNlQrU4aEpEZHBDWLp6VBNDeEZmajBlQrlGV9pVUSB5N7QHOih4bwo4ZWBnN0FVcoRnVIdzb78mby8WMKNWUiojITJCLiIkMxMTR4YkMiojIIJCL9IDMyEDMxkDO0IicfJye&Qf35VfiQlRWllI0IyQiwiI4YFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsIiM5kDMwEDI6ATOwMjMwIjI0ICdyNkIsIybp9Se4Vmbp9mLqIiOiMXbEJCLikHdl9WaOJiOiEmTDJCLiQjN7QTM8EDOzkDNyEjNxIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVzdQp6d8JTT4lVRPN5QLt6MjBXRox6THp6dyhFcyRHWYFDR5tUeLRWVkh7b4QlWHl5R7kHVzFUZXNWTUVlYGBHZONDc8c4d4cjV5kVcBpUS944R9d6S7d6RyNXRZ3ya7pXTlJWMCz5D0',

  HUBSPOT_API_KEY: '21646928',

  IMPACT_REFERRAL_PROGRAM_ID: '24474',

  // OneSchema
  oneSchemaClientId: '9ac48a3a-48b5-47a9-bfdf-0caf9457341e',
  openTelemetryCollectorUrl: 'otel.nonprod.90srv.com',
};

rootEnvironment = updateEnvironmentBasedOnHost(rootEnvironment);

export const environment = rootEnvironment;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
