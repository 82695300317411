// span.service.ts
import { Injectable, Inject } from '@angular/core';
import { Span, SpanStatusCode } from '@opentelemetry/api';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

import { OT_TRACER_PROVIDER } from './open-telemetry.tokens';

@Injectable()
export class SpanService {
  private readonly tracer = this.tracerProvider.getTracer('default');

  constructor(@Inject(OT_TRACER_PROVIDER) private readonly tracerProvider: WebTracerProvider) {}

  startSpan(spanName: string, attributes: Record<string, any> = {}): Span {
    const span = this.tracer.startSpan(spanName, { attributes });
    span.setStatus({ code: SpanStatusCode.OK });
    return span;
  }

  closeSpan(span: Span, isError = false): void {
    if (isError) {
      span.setStatus({ code: SpanStatusCode.ERROR, message: 'An error occurred during operation' });
    } else {
      span.setStatus({ code: SpanStatusCode.OK });
    }
    span.end();
  }
}
