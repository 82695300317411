import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';

import { SignupStateActions } from '@ninety/login/_state/sign-up/signup-state.actions';
import { ReferralService } from '@ninety/ui/legacy/core/services/referral.service';

import { ReferralActions } from './referral.actions';

@Injectable()
export class ReferralEffects {
  checkUserReferralStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupStateActions.checkEmail),
      switchMap(() => {
        return this.referralService.getReferralCode().then(referralCode => {
          if (!referralCode) {
            return ReferralActions.userHasNoReferral();
          }
          return ReferralActions.userHasReferral({ referralCode: referralCode });
        });
      })
    )
  );

  userHasReferral$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralActions.userHasReferral),
      map(({ referralCode }) => {
        return SignupStateActions.setReferralCode({ referralCode: referralCode });
      })
    )
  );

  constructor(private actions$: Actions, private referralService: ReferralService) {}
}
