import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { concatLatestFrom } from '@ngrx/effects';
import { createEntityAdapter, EntityState, Update } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';

import {
  AccountabilityDragDropEvent,
  AccountabilityListItem,
  AccountabilityListItemTextChange,
  AccountabilityListItemUpdate,
  AccountabilityRemoveEvent,
  Identifiable,
} from '@ninety/accountability-chart/models/accountabilities';
import { CreateSeatModel } from '@ninety/accountability-chart/models/seat.model';
import { AccountabilityApiService } from '@ninety/accountability-chart/services/accountability-api.service';
import { AccountabilityChartService } from '@ninety/accountability-chart/services/accountability-chart.service';
import { SeatApiService } from '@ninety/accountability-chart/services/seat-api.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { LibraryService } from '@ninety/ui/legacy/core/services/library.service';
import { AttachmentEvent } from '@ninety/ui/legacy/shared/models/_shared/attachment-event';
import { Accountability } from '@ninety/ui/legacy/shared/models/accountability-chart/accountability';
import { SeatModel } from '@ninety/ui/legacy/shared/models/accountability-chart/seat.model';
import { CompanyLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';

import { mapAccountabilityToListItem } from './mappers/map-accountability-to-list-item';
import { mapAttachmentsToListItem } from './mappers/map-attachment-to-list-item';
import { AttachmentListItem, SeatDetailsDialogData, SeatDetailsDialogMode, SeatDetailsDialogState } from './models';
import type { SeatDetailsDialogComponent } from './seat-details-dialog.component';
import { cardTitleFromDialogMode } from './utils/card-title-from-dialog-mode';
import { createId } from './utils/create-id';

export function initSeatDetailsDialogStoreState(params?: Partial<SeatDetailsDialogState>) {
  const state: SeatDetailsDialogState = {
    accountabilityListItems: accountabilitiesAdapter.getInitialState(),
    actionBtnText: '',
    attachmentFiles: [],
    attachmentListItems: attachmentsAdapter.getInitialState(),
    canEditChart: false,
    cardTitle: '',
    chartId: '',
    changedAccountabilityOrdinals: false,
    companyId: '',
    dialogData: null,
    isLoading: false,
    language: null,
    mode: null,
    ordinal: 0,
    parentSeatId: '',
    seatId: null,
    seatName: '',
    touched: false,
    ...params,
  };

  return state;
}

//==================
// Accountabilities
//==================
const accountabilitiesAdapter = createEntityAdapter<AccountabilityListItem>({
  selectId: item => item.id,
  sortComparer: (a, b) => a.accountability.ordinal - b.accountability.ordinal,
});

//==================
// Attachments
//==================
export const attachmentsAdapter = createEntityAdapter<AttachmentListItem>({
  selectId: item => item.id,
});

/**
 * Store for the seat details dialog
 * @deprecated
 */
@Injectable()
export class SeatDetailsDialogStore extends ComponentStore<SeatDetailsDialogState> {
  public readonly accountabilitiesSelectors = accountabilitiesAdapter.getSelectors();
  public selectVisibleAccountabilities = (state: EntityState<AccountabilityListItem>) =>
    this.accountabilitiesSelectors.selectAll(state).filter(item => !item.markedForRemoval);

  public readonly attachmentsSelectors = attachmentsAdapter.getSelectors();
  public selectVisibleAttachments = (state: EntityState<AttachmentListItem>) =>
    this.attachmentsSelectors.selectAll(state).filter(item => !item.markedForRemoval);

  public actionBtnText$ = this.select(state => state.actionBtnText);
  public accountabilities$ = this.select(state => this.selectVisibleAccountabilities(state.accountabilityListItems));
  public accountabilityListErrorMessage$ = this.select(state => {
    const accountabilityListItems = this.selectVisibleAccountabilities(state.accountabilityListItems);
    const hasEmptyAccountabilities = accountabilityListItems.some(item => !item.accountability.name?.length);
    const message = 'Every role & responsibility requires a name.';

    return hasEmptyAccountabilities ? message : null;
  });
  public attachments$ = this.select(state =>
    this.selectVisibleAttachments(state.attachmentListItems).map(item => item.attachment)
  );
  public attachmentFileList$ = this.select(state => state.attachmentFiles);
  public canSave$ = this.select(state => this.canSave(state));
  public canEditChart$ = this.select(state => state.canEditChart);
  public cardTitle$ = this.select(state => state.cardTitle);
  public isLoading$ = this.select(state => state.isLoading);
  public isCreateMode$ = this.select(state => state.mode === SeatDetailsDialogMode.Create);
  public isEditMode$ = this.select(state => state.mode === SeatDetailsDialogMode.Edit);
  public language$ = this.select(state => state.language);
  public seatId$ = this.select(state => state.seatId);
  public seatName$ = this.select(state => state.seatName);

  constructor(
    private accountabilityChartService: AccountabilityChartService,
    private errorService: ErrorService,
    private libraryService: LibraryService,
    private seatApiService: SeatApiService,
    private store: Store,
    public dialogRef: MatDialogRef<SeatDetailsDialogComponent>,
    private accountabilityApiService: AccountabilityApiService
  ) {
    super(initSeatDetailsDialogStoreState());
  }

  private logError(message: string, error: unknown) {
    this.errorService.notify(error, message);
  }

  /**
   * Determines whether the save/create primary action button is enabled or disabled.
   */
  canSave(state: SeatDetailsDialogState): boolean {
    // User hasn't interacted with the dialog
    if (!state.touched || state.isLoading || !state.seatName?.length) {
      return false;
    }

    const accountabilities = this.accountabilitiesSelectors.selectAll(state.accountabilityListItems);

    const hasNameForEveryAcc = accountabilities?.every(item => !!item.accountability?.name?.length);
    if (!hasNameForEveryAcc) return false;

    const hasOpenEditors = accountabilities.some(item => item.isEditing);
    if (hasOpenEditors) return false;

    // If the user has changed any of these, then they can save
    const hasChangedSeatName = state.seatName !== state.dialogData.seatName;

    // Will capture whether an accountability was added, updated, or marked for removal
    const accountabilityListItems = this.accountabilitiesSelectors.selectAll(state.accountabilityListItems);
    const hasChangedAccountabilities = accountabilityListItems.some(
      item => item.markedForCreate || item.markedForUpdate || item.markedForRemoval
    );

    // Will capture whether an attachment has been uploaded or removed
    const hasChangedAttachments = this.attachmentsSelectors
      .selectAll(state.attachmentListItems)
      .some(att => att.markedForUpload || att.markedForRemoval);

    const hasChanged = hasChangedSeatName || hasChangedAccountabilities || hasChangedAttachments;

    return hasChanged;
  }

  init = this.effect((dialogData$: Observable<SeatDetailsDialogData>) =>
    dialogData$.pipe(
      concatLatestFrom(() => [this.store.select(selectLanguage), this.state$]),
      tap(([dialogData, language, state]: [SeatDetailsDialogData, CompanyLanguage, SeatDetailsDialogState]) => {
        try {
          const accEntities = mapAccountabilityToListItem(dialogData.accountabilities);
          const accountabilityListItems = accountabilitiesAdapter.setAll(accEntities, state.accountabilityListItems);

          const attachmentEntities = mapAttachmentsToListItem(dialogData.attachments);
          const attachmentListItems = attachmentsAdapter.setAll(attachmentEntities, state.attachmentListItems);

          const newState: SeatDetailsDialogState = initSeatDetailsDialogStoreState({
            accountabilityListItems,
            actionBtnText: dialogData.mode === SeatDetailsDialogMode.Create ? 'Create' : 'Save',
            attachmentListItems,
            canEditChart: dialogData.canEditChart,
            cardTitle: cardTitleFromDialogMode(language, dialogData.mode),
            chartId: dialogData.chartId,
            companyId: dialogData.companyId,
            dialogData,
            language,
            mode: dialogData.mode,
            ordinal: dialogData.ordinal,
            parentSeatId: dialogData.parentSeatId,
            seatId: dialogData.seatId,
            seatName: dialogData.seatName,
          });

          this.patchState(newState);
        } catch (error: unknown) {
          this.logError('Error during init', error);
        }
      })
    )
  );

  updateSeatName = this.updater<string>(
    (state, name: string): SeatDetailsDialogState => ({
      ...state,
      seatName: name,
      touched: true,
    })
  );

  //====================
  // Accountabilities
  //====================

  /**
   * Pushes a new accountability to the list of accountabilities and opens it for editing.
   */
  addAccountabilityToList = this.effect((void$: Observable<void>) =>
    void$.pipe(
      concatLatestFrom(() => [this.state$]),
      tap(([_, state]: [void, SeatDetailsDialogState]) => {
        const entity: AccountabilityListItem = {
          id: createId(),
          accountability: {
            _id: null,
            chartId: state.chartId,
            companyId: state.companyId,
            deleted: false,
            description: '',
            name: '',
            ordinal: this.accountabilitiesSelectors.selectTotal(state.accountabilityListItems),
            seatId: state.mode === 'edit' ? state.seatId : null,
          },
          isEditing: true,
          markedForCreate: true,
          markedForRemoval: false,
          markedForUpdate: false,
        };

        const accountabilityListItems = accountabilitiesAdapter.addOne(entity, state.accountabilityListItems);

        this.patchState({ accountabilityListItems, touched: true });
      })
    )
  );

  removeAccountability = this.effect((event$: Observable<AccountabilityRemoveEvent>) =>
    event$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([event, state]: [AccountabilityRemoveEvent, SeatDetailsDialogState]) => {
        try {
          let updatedAccState: EntityState<AccountabilityListItem>;

          if (!event.item.accountability._id) {
            // If the item was only created client side, we can discard it on removal, no server updates requred
            updatedAccState = accountabilitiesAdapter.removeOne(event.item.id, state.accountabilityListItems);
          } else {
            // Need to update server that item was deleted
            const update: AccountabilityListItemUpdate = {
              id: event.item.id,
              changes: {
                ...state.accountabilityListItems[event.item.id],
                isEditing: false,
                markedForUpdate: false,
                markedForRemoval: true,
              },
            };
            updatedAccState = accountabilitiesAdapter.updateOne(update, state.accountabilityListItems);
          }

          this.patchState({
            accountabilityListItems: updatedAccState,
            touched: true,
          });
        } catch (error: unknown) {
          this.logError('Error removing accountability', error);
        }
      })
    )
  );

  enterEditAccountability = this.effect((void$: Observable<Identifiable>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([event, state]: [Identifiable, SeatDetailsDialogState]) => {
        try {
          const update: AccountabilityListItemUpdate = {
            id: event.id,
            changes: {
              ...state.accountabilityListItems[event.id],
              isEditing: true,
            },
          };
          const newState = accountabilitiesAdapter.updateOne(update, state.accountabilityListItems);

          this.patchState({ accountabilityListItems: newState });
        } catch (error: unknown) {
          this.logError('Error entering edit for accountability', error);
        }
      })
    )
  );

  exitEditAccountability = this.effect((void$: Observable<Identifiable>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([event, state]: [Identifiable, SeatDetailsDialogState]) => {
        try {
          const item = state.accountabilityListItems.entities[event.id];
          const cancelingOnCreate = item.markedForCreate && !item.accountability._id;

          let updatedState: EntityState<AccountabilityListItem>;

          if (cancelingOnCreate) {
            // We do this so there's not a nameless accountability in the list
            updatedState = accountabilitiesAdapter.removeOne(event.id, state.accountabilityListItems);
          } else {
            const update: AccountabilityListItemUpdate = {
              id: event.id,
              changes: { ...item, isEditing: false },
            };

            updatedState = accountabilitiesAdapter.updateOne(update, state.accountabilityListItems);
          }

          this.patchState({ accountabilityListItems: updatedState });
        } catch (error: unknown) {
          this.logError('Encountered error exiting accountability edit mode', error);
        }
      })
    )
  );

  moveAccountability = this.effect((event$: Observable<AccountabilityDragDropEvent>) =>
    event$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([event, state]: [AccountabilityDragDropEvent, SeatDetailsDialogState]) => {
        try {
          const minIndex = Math.min(event.previousIndex, event.currentIndex);
          const maxIndex = Math.max(event.previousIndex, event.currentIndex);

          // Only visible items can be moved
          const copy = this.selectVisibleAccountabilities(state.accountabilityListItems);

          // Update the array
          moveItemInArray(copy, event.previousIndex, event.currentIndex);

          // .slice so we only update affected items
          const updates: AccountabilityListItemUpdate[] = copy.slice(minIndex, maxIndex + 1).map((item, index) => ({
            id: item.id,
            changes: {
              accountability: {
                ...item.accountability,
                ordinal: minIndex + index,
              },
              markedForUpdate: true,
            },
          }));
          const newState = accountabilitiesAdapter.updateMany(updates, state.accountabilityListItems);

          this.patchState({
            accountabilityListItems: newState,
            changedAccountabilityOrdinals: true,
            touched: true,
          });
        } catch (error: unknown) {
          this.logError('Failed to move accountability', error);
        }
      })
    )
  );

  saveAccountability = this.effect((event$: Observable<AccountabilityListItemTextChange>) =>
    event$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([event, state]: [AccountabilityListItemTextChange, SeatDetailsDialogState]) => {
        try {
          const item = state.accountabilityListItems.entities[event.id];
          const update: AccountabilityListItemUpdate = {
            id: event.id,
            changes: {
              accountability: {
                ...item.accountability,
                name: event.name,
                description: event.description,
              },
              isEditing: false,
              // Don't flag as an update if the change is to a newly created accountability that doesn't exist on the server yet.
              markedForUpdate: !item.markedForCreate,
            },
          };
          const accountabilityListItems = accountabilitiesAdapter.updateOne(update, state.accountabilityListItems);

          this.patchState({ accountabilityListItems, touched: true });
        } catch (error: unknown) {
          this.logError('Encountered error saving accountability', error);
        }
      })
    )
  );

  //====================
  // End Accountabilities
  //====================

  //====================
  // Attachments
  //====================

  onAttachmentChange = this.effect((void$: Observable<AttachmentEvent>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([event, state]: [AttachmentEvent, SeatDetailsDialogState]) => {
        if (event.type === 'upload') {
          const item: AttachmentListItem = {
            id: createId(),
            attachment: event.attachment,
            markedForRemoval: false,
            markedForUpload: true,
          };
          const attachmentState = attachmentsAdapter.addOne(item, state.attachmentListItems);

          this.patchState({ attachmentListItems: attachmentState, touched: true });
        } else if (event.type === 'remove') {
          const item = this.attachmentsSelectors
            .selectAll(state.attachmentListItems)
            .find(attItem => attItem.attachment._id === event.attachment._id);
          const update: Update<AttachmentListItem> = {
            id: item.id,
            changes: {
              attachment: {
                ...event.attachment,
                isDeleted: true,
              },
              markedForRemoval: true,
            },
          };
          const attachmentState = attachmentsAdapter.updateOne(update, state.attachmentListItems);

          this.patchState({ attachmentListItems: attachmentState, touched: true });
        }
      })
    )
  );

  //====================
  // End Attachments
  //====================

  //====================
  // Dialog Actions
  //====================

  /**
   * On create, returns the entire SeatModel with embedded accountabilties & attachments
   * On edit, only returns the fields that can change.
   */
  closeDialog(response?: SeatModel | Pick<SeatModel, '_id' | 'accountabilities' | 'attachments' | 'name'> | null) {
    this.dialogRef.close(response);
  }

  cancel = this.effect((void$: Observable<void>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([_, state]: [void, SeatDetailsDialogState]) => {
        if (state.mode === SeatDetailsDialogMode.Create) {
          this.closeDialog();
          return;
        }

        // Attachment component uploads attachments without clicking save, need to patch client side model to match db state
        const attachments = this.attachmentsSelectors
          .selectAll(state.attachmentListItems)
          .filter(item => !item.markedForRemoval)
          .map(item => item.attachment);

        this.closeDialog({
          _id: state.seatId,
          name: state.dialogData.seatName,
          accountabilities: state.dialogData.accountabilities,
          attachments,
        });
      })
    )
  );

  /**
   * Given the current API setup, the create seat flow requires sequencing API calls that depend API created content.
   *  eg. Attachments need a seatId.
   *
   * Flow:
   *  - Call create seat endpoint
   *  - With the response, call upload on all in-memory files and use the created seat._id
   *  - Patch the attachment(s) responses to the seat so it's in memory
   *    - This is essential to handle the case where the user creates a seat then re-opens it to edit it.
   *    - Without doing so, attachments would not have _ids for download/remove
   */
  createSeat = this.effect((void$: Observable<void>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      map(([_, state]) => state),
      switchMap(state => {
        // Show spinner
        this.patchState({ isLoading: true });

        const dto: CreateSeatModel = {
          chartId: state.chartId,
          companyId: state.companyId,
          name: state.seatName,
          ordinal: state.ordinal,
          parentSeatId: state.parentSeatId,
          accountabilities: this.accountabilitiesSelectors
            .selectAll(state.accountabilityListItems)
            .map(item => item.accountability),
        };

        return this.seatApiService.createSeatModel(dto).pipe(
          switchMap((createdSeat: SeatModel) => {
            if (!state.attachmentFiles.length) {
              return of(createdSeat);
            }

            const requests = [
              ...this.libraryService.uploadFiles(createdSeat._id, 'Seat', state.attachmentFiles, createdSeat),
            ];

            return forkJoin(requests).pipe(
              // Patch attachment dtos on seat model
              map(attachments => ({ ...createdSeat, attachments })),
              catchError((err: unknown) => {
                this.logError('Failed to upload attachment(s)', err);

                return EMPTY;
              })
            );
          }),
          tap(createdSeat => {
            this.patchState({ isLoading: false });
            this.closeDialog(createdSeat);
          }),
          catchError((err: unknown) => {
            this.logError('Encountered an unknown error when trying to create a seat', err);

            this.patchState({ isLoading: false });

            return EMPTY;
          })
        );
      })
    )
  );

  updateSeatNameRequest = (seatId: string, seatName: string) => {
    const request = this.seatApiService
      .updateSeatModel({
        id: seatId,
        changes: { name: seatName },
      })
      .pipe(
        catchError((error: unknown) => {
          this.logError('Failed to update seat name', error);
          this.patchState({ isLoading: false });

          return EMPTY;
        })
      );

    return request;
  };

  /**
   * Currently updating a seat requires performing various combinations of CRUD HTTP requests on for multiple collections.
   * Might be a good idea to create a central endpoint to handle this.
   *
   * Possible changes:
   *  - Seat model:
   *    - Updating a seat name
   *  - Accountabilities
   *    - Create/update/(soft) delete & update ordinals
   *  - Attachments
   *    - Currently handled by v1 attachments component
   */
  updateSeat = this.effect((void$: Observable<void>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      map(([_, state]) => state),
      // Show spinner
      tap(() => this.patchState({ isLoading: true })),
      // Create accountabilities and patch their generated ids back into state
      switchMap(state => {
        const createdItems = this.accountabilitiesSelectors
          .selectAll(state.accountabilityListItems)
          .filter(item => item.markedForCreate);

        if (createdItems.length === 0) {
          return of(state);
        }

        // return this.accountabilityChartService
        //   .createAccountabilities(createdItems.map(item => item.accountability))
        return this.accountabilityApiService
          .createMany(
            state.seatId,
            createdItems.map(item => item.accountability)
          )
          .pipe(
            tap(createdAccountabilities => {
              const updates: Update<AccountabilityListItem>[] = createdAccountabilities.map((acc, index) => ({
                id: createdItems[index].id,
                changes: {
                  accountability: {
                    ...createdItems[index].accountability,
                    _id: acc._id,
                  },
                },
              }));
              const updatedState = accountabilitiesAdapter.updateMany(updates, state.accountabilityListItems);

              this.patchState({ accountabilityListItems: updatedState });
            }),
            // Re-pull state to have created accountabilities with their mongo ids embedded
            concatLatestFrom(() => this.state$),
            map(([_, state]) => state),
            catchError((err: unknown) => {
              this.logError('Failed to create accountabilties', err);

              this.patchState({ isLoading: false });

              return EMPTY;
            })
          );
      }),
      switchMap(state => {
        // Setup requests
        const requests: Observable<unknown>[] = [];

        // Get list of accountabilities to update, to delete, and the final list with order and ordinals set and deleted items omitted
        const { updated, deleted, final } = this.accountabilitiesSelectors
          .selectAll(state.accountabilityListItems)
          .reduce<{
            deleted: string[];
            final: Accountability[];
            updated: Accountability[];
          }>(
            (acc, item) => {
              if (item.markedForRemoval) {
                acc.deleted.push(item.accountability._id);
              } else {
                if (item.markedForUpdate) acc.updated.push(item.accountability);
                acc.final.push(item.accountability);
              }

              return acc;
            },
            { updated: [], deleted: [], final: [] }
          );

        // Update accountabilities
        if (updated.length) {
          requests.push(
            this.accountabilityChartService.updateAccountabilities(updated).pipe(
              catchError((_: unknown) => {
                // Log already handled in service, avoid logging twice

                this.patchState({ isLoading: false });

                return EMPTY;
              })
            )
          );
        }

        // Delete accountabilities
        if (deleted.length) {
          requests.push(
            this.accountabilityChartService.deleteAccountabilities(deleted).pipe(
              catchError((err: unknown) => {
                this.logError('Failed to delete accountabilties', err);

                this.patchState({ isLoading: false });

                return EMPTY;
              })
            )
          );
        }

        // Update ordinals
        if (state.changedAccountabilityOrdinals) {
          requests.push(this.accountabilityChartService.updateAccountabilityOrdinals(final));
        }

        // Update the seat name
        if (state.seatName !== state.dialogData.seatName) {
          requests.push(this.updateSeatNameRequest(state.seatId, state.seatName));
        }

        const response$ = requests.length ? forkJoin(requests) : of(null);

        return response$.pipe(
          tap(() => {
            this.patchState({ isLoading: false });

            this.closeDialog({
              _id: state.seatId,
              name: state.seatName,
              accountabilities: final,
              attachments: this.attachmentsSelectors.selectAll(state.attachmentListItems).map(item => item.attachment),
            });
          }),
          catchError((err: unknown) => {
            this.logError('Unknown error', err);

            this.patchState({ isLoading: false });

            return EMPTY;
          })
        );
      })
    )
  );

  /** Effect that delegates to another effect based off whether it's in create/edit mode */
  onPrimaryAction = this.effect((void$: Observable<void>) =>
    void$.pipe(
      concatLatestFrom(() => this.state$),
      tap(([_, state]: [void, SeatDetailsDialogState]) => {
        state.mode === SeatDetailsDialogMode.Create ? this.createSeat() : this.updateSeat();
      })
    )
  );

  //====================
  // End Dialog Actions
  //====================
}
