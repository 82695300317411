<!--TODO Use new components like button and card, remove references to dialog -->
<h1 mat-dialog-title>Move {{ language.acChart.seat }}</h1>
<mat-dialog-content>
  <div class="legacy-material-card-subtitle">
    Select a new manager {{ language.acChart.seat }} for: {{ nameOfSeatToMove }}
  </div>
  <mat-form-field class="move-seat-panel">
    <mat-label>New Manager {{ language.acChart.seat }}</mat-label>
    <mat-select [(value)]="selectedSeatId" [required]="true" data-cy="seat-move_new-manager-dropdown">
      <mat-option
        *ngFor="let seat of seats"
        [value]="seat.seatId"
        id="seat-option-{{ seat.seatId }}"
        attr.data-cy="seat-move_new-manager-option-{{ seat.seatName }}">
        <div class="seat-name">{{ seat.seatName }}</div>
        <div class="seatholder-names">
          <div *ngFor="let seatHolder of seat.seatHolderFullNames" class="seatholder-name">
            {{ seatHolder }}
          </div>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <ninety-button stroked class="cancel-button" (click)="cancel.emit()">Cancel</ninety-button>
  <ninety-button
    filled
    primary
    class="save-button"
    (click)="move.emit(selectedSeatId)"
    [disabled]="!selectedSeatId"
    data-cy="seat-move_save-button">
    Save
  </ninety-button>
</mat-dialog-actions>
