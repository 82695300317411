import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OneSchemaModule } from '@oneschema/angular';

import { DataImportAnalyticsEffects } from '@ninety/data-import/_state/effects/data-import-analytics.effects';
import { IssueImportEffects } from '@ninety/data-import/_state/effects/issue-import.effects';
import { RockImportEffects } from '@ninety/data-import/_state/effects/rock-import.effects';
import { TodoImportEffects } from '@ninety/data-import/_state/effects/todo-import.effects';
import { UserImportEffects } from '@ninety/data-import/_state/effects/user-import.effects';
import { UserImportCardComponent } from '@ninety/data-import/components/user-import-card/user-import-card.component';
import { EmailCellComponent } from '@ninety/data-import/components/user-import-preview-table/email-cell/email-cell.component';
import { RoleCellComponent } from '@ninety/data-import/components/user-import-preview-table/role-cell/role-cell.component';
import { TeamsCellComponent } from '@ninety/data-import/components/user-import-preview-table/teams-cell/teams-cell.component';
import { UserImportPreviewTableComponent } from '@ninety/data-import/components/user-import-preview-table/user-import-preview-table.component';
import { DataImportRoutingModule } from '@ninety/data-import/data-import-routing.module';
import { UserImportConfirmDialogComponent } from '@ninety/data-import/dialogs/user-import-confirm-dialog/user-import-confirm-dialog.component';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { DataImportComponent } from '@ninety/data-import/pages/data-import/data-import.component';
import { EditableInputComponent } from '@ninety/data-import/selects/editable-input/editable-input.component';
import { InlineEditableInputComponent } from '@ninety/data-import/selects/inline-editable-input/inline-editable-input.component';
import { InlineTeamSelectFormComponent } from '@ninety/data-import/selects/inline-team-select-form/inline-team-select-form.component';
import { DirectoryTableActionComponent } from '@ninety/directory-v2/components/directory-table-action/directory-table-action.component';
import { RoleInfoOverlayComponent } from '@ninety/directory-v2/components/role-info-overlay/role-info-overlay.component';
import { InlineRoleSelectComponent } from '@ninety/directory-v2/selects/inline-role-select/inline-role-select.component';
import { InlineTeamsSelectComponent } from '@ninety/directory-v2/selects/inline-teams-select/inline-teams-select.component';
import { TerraCheckboxModule, TerraIconModule } from '@ninety/terra';
import { AlertComponent } from '@ninety/ui/legacy/components/alerts/alert.component';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { ErrorOverlayComponent } from '@ninety/ui/legacy/components/error-overlay/error-overlay.component';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/inputs/quick-filter/quick-filter.component';
import { PaginationComponent } from '@ninety/ui/legacy/components/pagination/pagination.component';
import { PopoverOriginDirective } from '@ninety/ui/legacy/components/popover/popover-origin.directive';
import { PopoverComponent } from '@ninety/ui/legacy/components/popover/popover.component';
import { ActionBarComponent } from '@ninety/ui/legacy/components/toolbars/action-bar/action-bar.component';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { ChipTeamSelectBoxComponent } from '@ninety/ui/legacy/recipes/ninety-recipes-inputs/chip-select/implementations/chip-team-select-box/chip-team-select-box.component';
import { SharedModule } from '@ninety/ui/legacy/shared/shared.module';
import { environment } from '@ninety/ui/web/environments';

import { DataImportStateKey } from './_state/data-import.model';
import { dataImportReducer } from './_state/data-import.reducer';
import { DataImportEffects } from './_state/effects/data-import.effects';
import { DataImportUserStepOneComponent } from './components/info-cards/data-import-user-step-one.component';
import { DataImportUserStepThreeComponent } from './components/info-cards/data-import-user-step-three.component';
import { DataImportUserStepTwoComponent } from './components/info-cards/data-import-user-step-two.component';
import { OneSchemaImportComponent } from './components/one-schema-import/one-schema-import.component';

@NgModule({
  declarations: [
    DataImportComponent,
    OneSchemaImportComponent,
    UserImportConfirmDialogComponent,
    UserImportCardComponent,
    UserImportPreviewTableComponent,
    TeamsCellComponent,
    RoleCellComponent,
    EmailCellComponent,
    DataImportUserStepOneComponent,
    DataImportUserStepTwoComponent,
    DataImportUserStepThreeComponent,
    DataImportComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    DataImportRoutingModule,

    // FlexLayoutModule,
    ButtonComponent,
    CardModule,
    TerraIconModule,
    StoreModule.forFeature(DataImportStateKey, dataImportReducer),
    EffectsModule.forFeature([
      DataImportEffects,
      DataImportAnalyticsEffects,
      IssueImportEffects,
      RockImportEffects,
      TodoImportEffects,
      UserImportEffects,
    ]),
    OneSchemaModule.forRoot({
      manageDOM: false,
      clientId: environment.oneSchemaClientId,
      templateKey: OneSchemaTemplateKey.users,
      styles: {
        width: '99.8%',
        height: 'calc(100vh - 260px)',
        border: 'none',
      },
      devMode: !environment.production,
      saveSession: false,
      /** figure out how to get this to work */
      // eventWebhookKeys: ['fileUploaded'],
    }),
    MatTabsModule,
    LetDirective,
    ActionBarComponent,
    MatFormFieldModule,
    MatInputModule,
    NinetyTooltipDirective,
    PushPipe,
    QuickFilterComponent,
    ChipTeamSelectBoxComponent,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TerraCheckboxModule,
    PaginationComponent,
    DirectoryTableActionComponent,
    MatSortModule,
    MatTableModule,
    PopoverComponent,
    PopoverOriginDirective,
    InlineRoleSelectComponent,
    InlineTeamsSelectComponent,
    RoleInfoOverlayComponent,
    InlineEditableInputComponent,
    ErrorOverlayComponent,
    EditableInputComponent,
    InlineTeamSelectFormComponent,
    AlertComponent,
  ],
})
export class DataImportModule {}
