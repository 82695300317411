import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, tap } from 'rxjs';

import { DetailServiceSelectors } from '@ninety/detail-view/_state/detail-view.selectors';
import { GuideSelectors } from '@ninety/getting-started/guide/_state/guide.selectors';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { MainLayoutIntercomActions } from '@ninety/ui/legacy/state/app-global/intercom/intercom-state.actions';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { FeatureFlagKeys, selectFeatureFlag } from '@ninety/ui/legacy/state/index';
import { selectIsV2DetailRoute } from '@ninety/ui/legacy/state/route.selectors';

@Component({
  selector: 'ninety-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss', './main-layout.terrafy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent implements OnInit {
  /** Overflow property to use on the main content wrappers. */
  protected overflowType: 'hidden' | 'clip' | 'auto';

  @Input() inMeeting: boolean;
  @Input() inFeedback: boolean;

  language$ = this.store.select(selectLanguage);

  /**
   * TODO: Remove this selector and apply the v2 "detail-card-v2-wrp" styles directly to the detail-wrapper in main-layout.component
   * once all detail cards have been updated to the new style.
   */
  isV2Detail$ = this.store.select(selectIsV2DetailRoute).pipe(
    tap(value => {
      if (value) {
        this.store.dispatch(MainLayoutIntercomActions.hideIntercom());
      } else {
        this.store.dispatch(MainLayoutIntercomActions.showIntercom());
      }
    })
  );
  disableScrollbar$ = this.store.select(DetailServiceSelectors.selectDisableScrollbar);

  guideEnabled$ = this.store.select(GuideSelectors.selectIsGuideEnabled);
  showGettingStartedGuide$ = this.store.select(GuideSelectors.selectIsGuideOpened);

  enableTerrafyToolbarsFeatureFlag$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.terrafyToolbars));

  // Used by ScrollSpy directive to get access to the main windows scrollable content.
  @ViewChild('pageContent') pageContentRef: ElementRef;

  open$ = new BehaviorSubject<boolean>(false);

  constructor(public spinnerService: SpinnerService, private store: Store) {}

  ngOnInit(): void {
    // See https://traxion.atlassian.net/browse/DEV-8296 for why we only use `hidden` for Firefox
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    this.overflowType = isFirefox ? 'hidden' : 'auto';
  }

  onActivateDetail() {
    this.open$.next(true);
  }

  onDeactivateDetail() {
    this.open$.next(false);
  }
}
