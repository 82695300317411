import { CommonModule } from '@angular/common';
import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, OnDestroy } from '@angular/core';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';

import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { ReferralService } from '@ninety/ui/legacy/core/services/referral.service';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectIsTrialSubscription } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';

declare global {
  interface Window {
    impactToken: any;
  }
}

@Component({
  selector: 'ninety-referral',
  standalone: true,
  imports: [CommonModule, LetDirective],
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReferralComponent implements OnInit, OnDestroy {
  protected impactToken: string | null = null;
  protected isWidgetReady = false;
  protected readonly enableReferralProgram$ = this.featureFlags.getFlag(FeatureFlagKeys.referralProgram);
  protected readonly isTrialSubscription$ = this.store.select(selectIsTrialSubscription);
  private destroy$ = new Subject<void>();

  constructor(
    private referralService: ReferralService,
    private filterService: FilterService,
    private featureFlags: FeatureFlagFacade,
    private store: Store
  ) {
    this.filterService.setOptions({ filtersToolbar: false });
  }

  ngOnInit() {
    this.referralService
      .getReferralJwt()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.impactToken = res;
        window.impactToken = this.impactToken;
        this.waitForWidget().then(() => {
          this.isWidgetReady = true;
        });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
  async waitForWidget() {
    while (!customElements.get('impact-embed')) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  }
}
