<ng-container *ngIf="state$ | async as state">
  <ninety-main-layout [inMeeting]="state.inMeeting" [inFeedback]="state.inFeedback">
    <ng-container page-banner>
      <ninety-notification-banner></ninety-notification-banner>
    </ng-container>
    <ng-container page-sidebar [ngSwitch]="state.layoutType">
      <ninety-meeting-sidenav
        data-cy="home_ninety-meeting-sidenav"
        *ngSwitchCase="layouts.meeting"
        [showNav]="state.showSideNav"
        [isMobile]="state.isMobile"
        [showNotes]="state.showNotes"></ninety-meeting-sidenav>
      <ninety-conversation-sidenav
        *ngSwitchCase="layouts.conversation"
        [showNav]="state.showSideNav"
        [isMobile]="state.isMobile"
        [showQuarterlyQuestions]="state.showQuarterlyQuestions"></ninety-conversation-sidenav>
      <ng-container *ngSwitchCase="'normal'">
        <ninety-main-sidebar [isMobile]="state.isMobile"></ninety-main-sidebar>
      </ng-container>
    </ng-container>
    <ng-container page-content>
      <router-outlet></router-outlet>
      <ninety-meeting-notes *ngIf="state.showNotes" [class.nav-expanded]="state.showSideNav"></ninety-meeting-notes>
    </ng-container>
  </ninety-main-layout>
</ng-container>
