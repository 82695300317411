<ninety-card
  *ngrxLet="{
    accountabilities: store.accountabilities$ | async,
    accountabilityListErrorMessage: store.accountabilityListErrorMessage$ | async,
    actionBtnText: store.actionBtnText$ | async,
    attachments: store.attachments$ | async,
    attachmentFileList: store.attachmentFileList$ | async,
    canEditChart: store.canEditChart$ | async,
    cardTitle: store.cardTitle$ | async,
    canSave: store.canSave$ | async,
    isLoading: store.isLoading$ | async,
    isCreateMode: store.isCreateMode$ | async,
    language: store.language$ | async,
    seatId: store.seatId$ | async,
    seatName: store.seatName$ | async,
  } as vm">
  <!-- Header -->
  <ninety-card-header header>
    <ninety-card-title-with-count [title]="vm.cardTitle" />
  </ninety-card-header>

  <!-- Content -->
  <mat-dialog-content class="card-content">
    <mat-spinner *ngIf="vm.isLoading" color="accent" />

    <!-- Seat Name -->
    <section class="seat-title">
      <mat-label class="terra-body seat-title__title">Title</mat-label>
      <mat-form-field appearance="outline">
        <input
          #name="ngModel"
          matInput
          required
          type="text"
          name="name"
          data-cy="seat-details-add-seat-name"
          [disabled]="!vm.canEditChart && !vm.isLoading"
          [ngModel]="vm.seatName"
          (ngModelChange)="updateSeatName($event)"
          [attr.aria-label]="vm.language?.acChart.seat + ' name'" />
      </mat-form-field>
      <!-- Template variable exposes input states for "touched" & "valid" -->
      <small *ngIf="!vm.isLoading && name.touched && !name.valid" class="seat-dialog-error mat-error">
        A {{ vm.language?.acChart.seat | lowercase }} name is required
      </small>
    </section>

    <!-- Accountabilities -->
    <section class="seat-accountabilities" *ngIf="!!vm.accountabilities">
      <header class="flex-align-center">
        <h3 class="section-title">{{ vm.language.acChart.rolesAndResponsibilities }}</h3>
        <ninety-button
          *ngIf="vm.canEditChart"
          icon
          flat
          data-cy="seat-details-add-seat-roles-and-responsibilities"
          [disabled]="vm.isLoading"
          class="no-padding"
          (click)="addAccountability()">
          <terra-icon icon="add-circle" />
        </ninety-button>
      </header>

      <small *ngIf="vm.accountabilityListErrorMessage" class="seat-dialog-error mat-error">
        {{ vm.accountabilityListErrorMessage }}
      </small>

      <ninety-accountability-list
        [accountabilities]="vm.accountabilities"
        [canEdit]="vm.canEditChart"
        (clickedRemove)="removeAccountability($event)"
        (dragDrop)="dragDroppedAccountability($event)"
        (enterEditAccountability)="enterEditAccountability($event)"
        (exitEditAccountability)="exitEditAccountability($event)"
        (save)="saveAccountability($event)" />
    </section>

    <!-- Attachments -->
    <section class="seat-attachments flex-layout-column">
      <!-- Create Attachments -->
      <ng-container *ngIf="vm.isCreateMode; else editAttachments">
        <ninety-attachments
          template="create"
          [canEdit]="vm.canEditChart"
          [fileAttachments]="vm.attachmentFileList"
          [hideForManagees]="vm.canEditChart">
          <h3 createAttachmentTitle class="section-title">Attachments</h3>
        </ninety-attachments>
      </ng-container>
      <!-- Edit Attachments -->
      <ng-template #editAttachments>
        <ninety-attachments
          template="update"
          [canEdit]="vm.canEditChart"
          [hideForManagees]="vm.canEditChart"
          [item]="$any({ _id: vm.seatId, attachments: vm.attachments })"
          attachmentParentType="Seat"
          (afterUploadOrRemove)="onAttachmentChange($event)">
          <h3 updateAttachmentTitle class="section-title">Attachments</h3>
        </ninety-attachments>
      </ng-template>
    </section>
  </mat-dialog-content>

  <!-- Footer -->
  <ninety-card-footer footer class="seat-dialog-action-group">
    <ninety-button
      secondary
      stroked
      (click)="cancel()"
      [disabled]="vm.isLoading"
      data-cy="seat-details-cancel-button"
      id="dialog-cancel-button">
      {{ vm.canEditChart ? 'Cancel' : 'Close ' }}
    </ninety-button>
    <ninety-button
      *ngIf="vm.canEditChart"
      primary
      filled
      (click)="onPrimaryAction()"
      [disabled]="!vm.canSave"
      data-cy="seat-details-save"
      id="dialog-save-button">
      {{ vm.actionBtnText }}
    </ninety-button>
  </ninety-card-footer>
</ninety-card>
