import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, merge, Observable, of, Subject, switchMap } from 'rxjs';

import * as TopToolbarSelectors from '@ninety/_layouts/_state/top-toolbar/top-toolbar-state.selectors';
import { CreateDialogService } from '@ninety/_layouts/services/create-dialog.service';
import { selectShouldShowInsightsInNavigation } from '@ninety/insights/_shared/_state/insights.selectors';
import { GridLayoutSelectors } from '@ninety/layouts/grid-layout/_state/grid-layout-state.selectors';
import { MeetingService } from '@ninety/meeting/_shared/services/meeting.service';
import { MeetingsStateSelectors } from '@ninety/pages/meetings/_state/meetings.selectors';
import { AuthService } from '@ninety/ui/legacy/core/services/auth.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { UserService } from '@ninety/ui/legacy/core/services/user.service';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import {
  selectCurrentUser,
  selectCurrentUserIsManageeOrAbove,
  selectCurrentUserIsObserver,
  selectUserAvatarInfo,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectAuthShouldHaveAccess } from '@ninety/ui/legacy/state/app-global/auth/auth.selectors';
import { selectCompanySettings } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectCurrentPerson } from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectDoesUserHaveAccessToOrgChart } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';
import { CurrentUserSelectors, selectConversationChannel, selectRouteParam } from '@ninety/ui/legacy/state/index';
import { environment } from '@ninety/ui/web/environments';

import { HelpDialogComponent } from '../help-dialog/help-dialog.component';

@Component({
  selector: 'ninety-top-toolbar',
  templateUrl: './top-toolbar.component.html',
  styleUrls: ['./top-toolbar.component.scss', './top-toolbar.terrafy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopToolbarComponent implements OnInit, OnDestroy {
  @Input() meetingStarted?: boolean;

  @Output() menuClick = new EventEmitter<any>();

  pageTitle$: Observable<string>;
  participants$: Observable<{ userIds: string[]; usernames: string[]; isObserver: boolean[] }>;
  isEditing$ = this.store.select(GridLayoutSelectors.selectIsLayoutModeEnabled);

  environment = environment; // just for checking in settings link
  contextMenuPosition = { x: '0px', y: '0px' };
  enableLearningModule$ = this.featureFlags.getFlag(FeatureFlagKeys.enableLearningModule);
  enableTerrafyToolbarsFeatureFlag$ = this.featureFlags.getFlag(FeatureFlagKeys.terrafyToolbars);

  protected readonly companySettings$ = this.store.select(selectCompanySettings);
  protected readonly currentPerson$ = this.store.select(selectCurrentPerson);
  protected readonly disableNavLinks$ = this.store
    .select(selectAuthShouldHaveAccess)
    .pipe(map(shouldHaveAccess => !shouldHaveAccess));
  protected readonly enableSurveys$ = this.featureFlags.getFlag(FeatureFlagKeys.enableAssessments);
  protected readonly isManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);
  protected readonly isObserver$ = this.store.select(selectCurrentUserIsObserver);
  protected readonly language$ = this.store.select(selectLanguage);
  protected readonly mainToolbarHasShadow$ = this.store.select(TopToolbarSelectors.selectMainToolbarHasShadow);
  protected readonly mainToolbarHidden$ = this.store.select(TopToolbarSelectors.selectMainToolbarHidden);
  protected readonly canAccessInsights$ = this.store.select(selectShouldShowInsightsInNavigation);
  protected readonly canAccessOrgChart$ = this.store.select(selectDoesUserHaveAccessToOrgChart);
  protected readonly userTeams$ = this.store.select(CurrentUserSelectors.selectTeams);
  protected readonly currentMeeting$ = this.store.select(MeetingsStateSelectors.selectCurrentMeeting);
  protected readonly currentConversationMeeting$ = this.store.select(selectRouteParam('id')).pipe(
    filter(id => !!id),
    switchMap(id => this.store.select(selectConversationChannel(id)))
  );
  protected readonly rocksV3$ = this.featureFlags.getFlag(FeatureFlagKeys.webRocksV3);

  // Scorecard Beta
  protected readonly scorecardBeta$ = this.featureFlags.getFlag(FeatureFlagKeys.scorecardBeta);

  protected readonly directoryV2$ = this.featureFlags.getFlag(FeatureFlagKeys.enableUserManagementEnhancements);

  protected readonly canAccessMeetingsV2$ = this.featureFlags.getFlag(FeatureFlagKeys.meetingsV2);

  selectUserAvatarInfo = selectUserAvatarInfo;

  chipMessage$ = this.store.select(TopToolbarSelectors.selectChipMessage);

  @ViewChild(MatMenuTrigger) presenceMenu: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) responsiveMenuTrigger: MatMenuTrigger;
  private readonly destroy$: Subject<void> = new Subject<void>();
  constructor(
    public filterService: FilterService,
    public legacyDialog: MatLegacyDialog,
    public meetingService: MeetingService,
    public authService: AuthService,
    public spinnerService: SpinnerService,
    public stateService: StateService,
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    public createDialogService: CreateDialogService,
    private featureFlags: FeatureFlagFacade,
    public store: Store
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.pageTitle$ = this.stateService.pageTitle$.pipe(
      switchMap(title => {
        if (this.meetingStarted && title.toLowerCase().includes('data')) return of(title.replace('Data', 'Scorecard'));

        if (title.toLowerCase().includes('v/to')) return of('Vision/Traction Organizer™');

        return of(title);
      })
    );

    this.participants$ = merge(
      this.breakpointObserver.observe(['(min-width: 375px)', '(min-width: 768px)', '(min-width: 1024px)']).pipe(
        switchMap((result: BreakpointState) => {
          const { breakpoints } = result;

          if (breakpoints['(min-width: 1024px)']) {
            return of(4);
          }

          if (breakpoints['(min-width: 768px)']) {
            return of(1);
          }

          return of(0);
        })
      ),
      this.meetingService.presenceChanged$.pipe(
        switchMap(() => {
          if (this.breakpointObserver.isMatched(['(min-width: 1024px)'])) {
            return of(4);
          }

          if (this.breakpointObserver.isMatched(['(min-width: 768px)'])) {
            return of(1);
          }

          return of(0);
        })
      )
    ).pipe(
      concatLatestFrom(() => [
        this.store.select(MeetingsStateSelectors.selectCurrentMeeting),
        this.store.select(selectCurrentUser),
      ]),
      filter(([, meeting]) => meeting?.inProgress),
      map(([maxAvatarCount, meeting, currentUser]) => {
        if (maxAvatarCount === 0) {
          return { userIds: null, usernames: null, isObserver: null };
        } else {
          const presentUserIds = meeting?.presentUsers?.filter(id => id !== currentUser._id) ?? [];

          //TODO: Refactor this
          const userIds =
            presentUserIds.length >= maxAvatarCount
              ? presentUserIds.length == maxAvatarCount
                ? presentUserIds.splice(0, maxAvatarCount)
                : presentUserIds.splice(0, maxAvatarCount - 1)
              : presentUserIds.splice(0, presentUserIds.length);

          const usernames = presentUserIds.map(id => this.getUsername(id)).filter(n => !!n);
          const isObserver = userIds.map(id => this.isObserver(id));

          return { userIds, usernames, isObserver };
        }
      })
    );
  }

  logout() {
    this.authService.logout();
  }

  getUsername(id: string) {
    const user = this.userService.users.find(u => u._id === id);
    if (!user) return '';

    const { name } = user.metadata;
    if (name?.first || name?.last) return `${name?.first} ${name?.last}`.trim();

    if (user.emailAddresses?.length) return user.emailAddresses[0].email;

    return 'Unknown User';
  }

  isObserver(id: string) {
    const user = this.userService.users.find(u => u._id === id);
    if (!user) return true; // Unknown user treated as observer

    return user.roleCode === RoleCode.observer;
  }

  openUniversalCreateModal(): void {
    this.createDialogService.open().subscribe();
  }

  openHelpModal() {
    this.legacyDialog.open(HelpDialogComponent, {
      autoFocus: false,
    });
  }

  openContextMenu(event: MouseEvent, userId: string, index: number, isObserver: boolean) {
    if (
      isObserver ||
      event.ctrlKey ||
      event.shiftKey ||
      (this.meetingService.currentMeeting &&
        this.meetingService.currentMeeting.presenterUserId !== this.stateService.currentUser._id)
    )
      return;
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.presenceMenu.menuData = { userId, index };
    this.presenceMenu.openMenu();
  }

  handleSettingLinkClicked() {
    this.responsiveMenuTrigger?.closeMenu();
  }

  protected _getPageDescription(inPageTitle: string): string {
    let defaultLang;

    this.language$.subscribe(lang => {
      const entries = Object.entries(lang);
      const tool = entries.find(tool => (tool[1].route || tool[1].items) === inPageTitle);
      defaultLang = tool ? tool[0] : undefined;
    });

    let description;
    switch (defaultLang) {
      case 'my90':
        description = 'A central workspace to manage your To-Dos, Scorecard KPIs, and Rocks.';
        break;
      case 'insights':
        description = 'Gain insights into your business by tracking your most important numbers.';
        break;
      case 'scorecard':
        description = 'Your key metrics, streamlined for strategic success.';
        break;
      case 'rock':
        description = 'Set and track quarterly goals in Ninety to help your team consistently hit their targets. ';
        break;
      case 'todo':
        description =
          'A tool to communicate what needs to be done, assign owners, and track deadlines for critical tasks.';
        break;
      case 'issue':
        description = 'Raise, Discuss, and Resolve (RDR) your most pressing team Issues.';
        break;
      case 'meeting':
        description = 'Run better meetings by prioritizing your agenda and tracking your time.';
        break;
      case 'headline':
        description = ' Easily share important announcements with your team during meetings.';
        break;
      case 'vto':
        description = 'Communicate and share your Vision at a company, departmental, and individual level.';
        break;
      case 'acChart':
        description = 'Map out the roles and responsibilities for each seat in your business.';
        break;
      case 'feedback':
        description = 'Conduct team member reviews through a comprehensive, data-centered, two-way conversation.';
        break;
      case 'process':
        description = 'Document your critical processes and ensure consistency and transparency.';
        break;
      case 'directory':
        description = 'Find colleagues and their contact info with ease.';
        break;
      case 'mastery':
        description = 'A central hub containing resources on our concepts, tools, and disciplines.';
        break;
      case 'survey':
        description =
          'Leverage carefully designed assessments to gauge the health of your team and your progress as a business.';
        break;
      case 'measurable':
        description = 'This is measurable description';
        break;
      default:
        description = '';
    }

    // Special overrides for internal pages
    if (!defaultLang) {
      if (inPageTitle === 'Insights') {
        description = 'Explore, analyze, and act on insights to drive data-driven decisions across your organization.';
      }
      // if (inPageTitle === 'Referred Companies') {
      //   description = 'TERRATODO - PARTNER HUB DESCRIPTION TBD';
      // }
    }
    return description;
  }
}
