import { Route } from '@angular/router';

import { DefaultTieredPageComponent } from '@ninety/_commercial-model/components/_default-tiered-page/default-tiered-page.component';
import { IssuesTieredPageComponent } from '@ninety/_commercial-model/components/issues-tiered-page/issues-tiered-page.component';
import { RocksTieredPageComponent } from '@ninety/_commercial-model/components/rocks-tiered-page/rocks-tiered-page.component';
import { TodosTieredPageComponent } from '@ninety/_commercial-model/components/todos-tiered-page/todos-tiered-page.component';
import { NinetyFeatures } from '@ninety/ui/legacy/shared/models/company/company-pricing-tiers';

export const COMMERCIAL_MODEL_ROUTES: Route[] = [
  /** default tiered pages (no access on current tier) */
  {
    path: 'no-access',
    children: [
      {
        path: NinetyFeatures.issues,
        component: IssuesTieredPageComponent,
        data: { route: 'issue' },
      },
      {
        path: NinetyFeatures.rocks,
        component: RocksTieredPageComponent,
        data: { route: 'rock' },
      },
      {
        path: NinetyFeatures.todos,
        component: TodosTieredPageComponent,
        data: { route: 'todo' },
      },
      {
        path: ':feature',
        component: DefaultTieredPageComponent,
        data: { title: 'No Access' },
      },
      { path: '', component: DefaultTieredPageComponent, data: { title: 'No Access' } },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];
