import { createReducer, on } from '@ngrx/store';
import _cloneDeep from 'lodash/cloneDeep';

import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { GuideState, INITIAL_GUIDE_STATE } from '@ninety/getting-started/guide/_state/guide.model';

export const GuideReducer = createReducer(
  INITIAL_GUIDE_STATE,
  on(
    GuideActions.toggleGuide,
    GuideActions.closeMazAndOpenIntercom,
    (state): GuideState => ({
      ...state,
      guideOpened: !state.guideOpened,
      selectedPhase: null,
    })
  ),
  on(GuideActions.forceOpenGuide, (state): GuideState => ({ ...state, guideOpened: true, selectedPhase: null })),
  on(GuideActions.closeGuide, (state): GuideState => ({ ...state, guideOpened: false, selectedPhase: null })),
  on(
    GuideActions.setGuideInfo,
    (state, { guideInfo: { title, subtitle } }): GuideState => ({
      ...state,
      title,
      subtitle,
    })
  ),
  on(
    GuideActions.setPhasesAndCompletionPercentage,
    (state, { phases, completionPercentage }): GuideState => ({
      ...state,
      phases: _cloneDeep(phases),
      completionPercentage,
    })
  ),
  on(GuideActions.selectPhase, (state, { phase }): GuideState => ({ ...state, selectedPhase: _cloneDeep(phase) })),
  on(GuideActions.closePhaseDetails, (state): GuideState => ({ ...state, selectedPhase: null }))
);
