<ng-container
  *ngrxLet="{
    language: language$,
    companySettings: companySettings$,
    currentPerson: currentPerson$,
    isManageeOrAbove: isManageeOrAbove$,
    disableNavLinks: disableNavLinks$,
    enableLearningModule: enableLearningModule$,
    enableSurveys: enableSurveys$,
    canAccessInsights: canAccessInsights$,
    scorecardBeta: scorecardBeta$,
    canAccessOrgChart: canAccessOrgChart$,
    directoryV2: directoryV2$,
    canAccessMeetingsV2: canAccessMeetingsV2$,
    rocksV3: rocksV3$,
  } as data">
  <ninety-button
    flat
    icon
    data-cy="top-toolbar_hamburger-menu-button"
    #mobileNavMenuTrigger
    class="menu-button-hamburger"
    [matMenuTriggerFor]="mobileNavMenu">
    <terra-icon icon="list" />
  </ninety-button>

  <mat-menu #mobileNavMenu="matMenu">
    <ng-template matMenuContent>
      <div class="mobile-nav-menu">
        <ninety-company-menu [menuItem]="true"></ninety-company-menu>
        <button *ngIf="data.currentPerson?.partnerType" mat-menu-item (click)="routeTo('partner-hub')">
          <terra-icon icon="users" />
          Partner Hub
        </button>
        <a
          *ngIf="data.companySettings.dashboard"
          mat-menu-item
          href="/dashboard"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="dashboard" />
          {{ data.language.dashboard.route }}
        </a>
        <button mat-menu-item (click)="routeTo('home')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="my-90" />
          {{ data.language.my90.route }}
        </button>
        <button
          *ngIf="data.canAccessInsights"
          mat-menu-item
          (click)="routeTo('insights')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="lightbulb" />
          <!-- TODO: Custom Language -->
          Insights
        </button>
        <!-- Scorecard Route -->
        <div class="beta-menu-item">
          <button
            mat-menu-item
            iconKey="data"
            data-cy="mobile-nav-menu_data"
            [attr.data-product]="
              data.scorecardBeta ? 'mobile-nav-menu__scorecard-beta-link' : 'mobile-nav-menu__scorecard'
            "
            [disabled]="data.disableNavLinks"
            [class.disabled]="data.disableNavLinks"
            (click)="routeTo(data.scorecardBeta ? '/data-v2' : '/data')">
            <terra-icon icon="data" />
            {{ data.language.scorecard.route }}
            <ninety-chip *ngIf="data.scorecardBeta" class="beta-chip" color="brand">Beta</ninety-chip>
          </button>
        </div>
        <button
          mat-menu-item
          (click)="routeTo(data.rocksV3 ? 'rocks-v3' : 'rocks')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="rocks" />
          {{ data.language.rock.route }}
        </button>
        <button mat-menu-item (click)="routeTo('todos')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="to-dos" />
          {{ data.language.todo.route }}
        </button>
        <button mat-menu-item (click)="routeTo('issues')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="issues" />
          {{ data.language.issue.route }}
        </button>
        <button
          mat-menu-item
          (click)="data.canAccessMeetingsV2 ? routeTo('meetings-v2') : routeTo('meetings')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="meetings" />
          {{ data.language.meeting.route }}
        </button>
        <button mat-menu-item (click)="routeTo('headlines')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="headlines" />
          {{ data.language.headline.items }}
        </button>
        <button mat-menu-item (click)="routeTo('vision')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="vision" />
          {{ data.language.vto.route }}
        </button>
        <button
          *ngIf="data.canAccessOrgChart"
          mat-menu-item
          (click)="routeTo('chart')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="responsibilities" />
          {{ data.language.acChart.route }}
        </button>
        <button
          *ngIf="data.isManageeOrAbove"
          mat-menu-item
          (click)="routeTo('1-on-1')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon ninetyTooltip="Feedback" icon="one-on-one" />
          {{ data.language.feedback.route }}
        </button>
        <button mat-menu-item (click)="routeTo('process')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="process" ninetyTooltip="Process" />
          {{ data.language.process.route }}
        </button>
        <button mat-menu-item (click)="routeTo('directory')" [class.disabled]="data.disableNavLinks">
          <terra-icon icon="directory" />
          {{ data.language.directory.route }}
        </button>
        <button
          *ngIf="data.directoryV2"
          mat-menu-item
          (click)="routeTo('directory-v2')"
          data-product="mobile-nav__directory-v2-link"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="directory" />
          {{ data.language.directory.route }} V2
        </button>
        <button
          *ngIf="data.companySettings?.mastery.enabled"
          mat-menu-item
          (click)="routeTo('mastery')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="mastery" />
          {{ data.language.mastery.route }}
        </button>
        <button
          *ngIf="data.enableLearningModule"
          mat-menu-item
          (click)="routeTo('knowledge')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="learn" />
          Knowledge
        </button>
        <button
          *ngIf="data.enableSurveys"
          mat-menu-item
          (click)="routeTo('assessments')"
          [class.disabled]="data.disableNavLinks">
          <terra-icon icon="surveys" />
          {{ data.language.survey.route }}
        </button>
        <terra-divider />

        <ninety-settings-menu class="settings-menu-extended" (settingLinkClicked)="handleSettingLinkClicked()" />

        <button mat-menu-item (click)="logout()">
          <terra-icon icon="sign-out" />
          Log Out
        </button>
      </div>
    </ng-template>
  </mat-menu>
</ng-container>
