import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TerraCounterModule } from '../terra-counter';
import { TerraIconModule } from '../terra-icon';
import { TerraSelectModule } from '../terra-select';

import { TerraQuickFilterComponent } from './terra-quick-filter.component';

@NgModule({
  imports: [CommonModule, TerraQuickFilterComponent, TerraSelectModule, TerraIconModule, TerraCounterModule],
  exports: [TerraQuickFilterComponent, TerraSelectModule, TerraIconModule, TerraCounterModule],
})
export class TerraQuickFilterModule {}
