import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { MazMessage } from '@ninety/getting-started/maz/models/maz-message';
import { MazResponse } from '@ninety/getting-started/maz/models/maz-response';

import { MazFeedbackFlag } from '../models/maz-feedback-flag.enum';

export const MazActions = createActionGroup({
  source: 'Maz',
  events: {
    Init: emptyProps(),
    'Get Conversation History Success': props<{ mazMessages: MazMessage[] }>(),
    'Toggle Maz Chat': emptyProps(),
    'Send Message To Maz': props<{ userInput: string }>(),
    'Waiting For Maz Reply': emptyProps(),
    'Receive Message From Maz': props<{ response: MazResponse }>(),
    'Failed To Receive Message From Maz': props<{ error: unknown; message: string }>(),

    'Toggle Maz Feedback Flag': props<{ messageId: string; mazFeedbackFlag: MazFeedbackFlag }>(),

    /** User can opt in/out of Maz chat */
    'Toggle User Maz Status': props<{ enabled: boolean }>(),
  },
});
