import { Sort } from '@angular/material/sort';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

// eslint-disable-next-line max-len
import { UpdateDialogOptions } from '@ninety/pages/meetings/meetings-page/meeting-schedules/update-meeting-schedule-dialog/update-meeting-schedule-dialog.component';
import { QuickFilterOption } from '@ninety/ui/legacy/components/inputs/quick-filter/models/quick-filter-item.model';
import { PrintMeetingAgendaSortOptions } from '@ninety/ui/legacy/core/services/print.service';
import { Team, TeamMeetingAgendaUpdateByType } from '@ninety/ui/legacy/shared/models/_shared/team';
import { Meeting } from '@ninety/ui/legacy/shared/models/meetings/meeting';
import { MeetingAgenda } from '@ninety/ui/legacy/shared/models/meetings/meeting-agenda';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { PastMeetingsV2Response } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';

import { AddMeetingScheduleModel } from '../_models/add-meeting-schedule.model';
import { MeetingSchedule } from '../_models/meeting-schedule.model';

export const MeetingsPageActions = createActionGroup({
  source: 'Meetings Page',
  events: {
    Reset: emptyProps(),
    'Select Team': props<{ team: Team }>(),
    'Open Print Agenda Dialog': emptyProps(),
    'Print Weekly Meeting Agenda': emptyProps(),

    'Load Past Meetings': emptyProps(),
    'Load Past Meetings Success': props<{ pastMeetingsResponse: PastMeetingsV2Response }>(),
    'Load Past Meetings Failure': props<{ error: unknown }>(),

    'Get Active Meeting Info': emptyProps(),
    'Get Active Meeting Info Success': props<{ meeting: Meeting }>(),
    'Get Active Meeting Info Failure': props<{ error: unknown }>(),

    'Get Meeting': emptyProps(),
    'Get Meeting Success': props<{ meeting: Meeting }>(),
    'Get Meeting Failure': props<{ error: unknown }>(),

    'Resume Meeting': emptyProps(),
    'Resume Meeting Success': props<{ meeting: Meeting }>(),
    'Resume Meeting Failure': props<{ error: unknown }>(),

    'Open Delete Meeting Dialog': emptyProps(),
    'Cancel Delete Active Meeting': emptyProps(),

    'Delete Active Meeting': emptyProps(),
    'Delete Active Meeting Success': props<{ meetingId: string }>(),
    'Delete Active Meeting Failure': props<{ error: unknown }>(),

    'Delete Meeting': props<{ meeting: Meeting }>(),
    'Delete Meeting Success': emptyProps(),
    'Delete Meeting Failure': props<{ error: unknown }>(),

    'Pagination Change': props<{ index: number; size: number }>(),
    'Sort Change': props<{ sort: Sort }>(),

    'Select Meeting': props<{ meeting: Meeting }>(),

    'Update Meeting': props<{ _id: string; update: Partial<Meeting> }>(),
    'Update Meeting Success': emptyProps(),
    'Update Meeting Failure': props<{ error: unknown }>(),

    'Update Meeting In Store': props<{ _id: string; update: Partial<Meeting> }>(),
    'Open Universal Create Modal': emptyProps(),
  },
});

export const PastMeetingsPageActions = createActionGroup({
  source: 'Past Meetings Page',
  events: {
    'Select Team': props<{ team: Team }>(),
    'Select Meeting Type': props<{ meetingAgenda: QuickFilterOption<MeetingAgenda> }>(),
    'Download Excel': emptyProps(),
    'Download Excel Success': emptyProps(),
    'Download Excel Failure': emptyProps(),
  },
});

export const MeetingsTabsActions = createActionGroup({
  source: 'Meetings Tabs',
  events: {
    Init: emptyProps(),
    Reset: emptyProps(),
    Navigate: props<{ route: string }>(),
  },
});

export const MeetingConcludeActions = createActionGroup({
  source: 'Meetings Conclude Page',
  events: {
    'Update Meeting Settings': props<{
      teamId: string;
      meetingType: MeetingType;
      autoArchive: boolean;
      shouldSendRecap: boolean;
      acceptPendingAgreementBasedTodos: boolean;
      showIssueDescOnRecapEmail: boolean;
    }>(),
    'Update Meeting Settings Success': emptyProps(),
    'Update Meeting Settings Failure': props<{ error: unknown }>(),

    'Meeting Concluded': props<{ meetingType: MeetingType }>(),
  },
});

export const MeetingDialogActions = createActionGroup({
  source: 'Meetings Dialog Actions',
  events: {
    'Open Start Meeting Dialog': emptyProps(),

    'Create Meeting': props<{ meetingType: MeetingType; agendaId?: string }>(),
    'Create Meeting Success': props<{ meeting: Meeting }>(),
    'Create Meeting Failure': props<{ error: unknown }>(),
    'Print Meeting Agenda': props<{
      meetingType: MeetingType;
      sortOptions: PrintMeetingAgendaSortOptions | null;
      customAgendaId?: string;
    }>(),
  },
});

export const MeetingStateActions = createActionGroup({
  source: 'Meetings Actions',
  events: {
    'Update Current Meeting': props<{ update: Partial<Meeting> }>(),

    /** Remove this action and the location where it's called once all meetings are initiated from the meetings V2 UI */
    'Track Meeting Creation': props<{ meeting: Meeting }>(),

    'Suspend Meeting': props<{ meetingId: string }>(),
  },
});

export const MeetingRealTimeActions = createActionGroup({
  source: 'Meetings Real Time Actions',
  events: {
    'Meeting Presence Change': props<{ userId: string; action: string }>(),
    'Set Meeting Presence': props<{ attendees: string[] }>(),
    'Authorize Channel Success': emptyProps(),
    'Meeting State Change': emptyProps(),
    'Check Meeting Presenter Exists': props<{ meeting: Meeting }>(),
  },
});

export const MeetingsAgendasV2Actions = createActionGroup({
  source: 'Meetings Agendas V2 Actions',
  events: {
    'Select Team': props<{ team: Team }>(),
    'Trigger Save New Custom Agenda': emptyProps(),
    'Save New Custom Agenda': props<{ update: TeamMeetingAgendaUpdateByType }>(),
    'Save New Custom Agenda Success': props<{ agenda: MeetingAgenda }>(),
    'Save New Custom Agenda Failure': props<{ error: unknown }>(),

    'Trigger Update Agenda': emptyProps(),
    'Update Agenda': props<{ update: TeamMeetingAgendaUpdateByType }>(),
    'Update Agenda Success': emptyProps(),
    'Update Agenda Failure': props<{ error: unknown }>(),

    'Trigger Delete Custom Agenda': emptyProps(),
    'Delete Custom Agenda': props<{ teamId: string; agendaId: string }>(),
    'Delete Custom Agenda Success': emptyProps(),
    'Delete Custom Agenda Failure': props<{ error: unknown }>(),

    'Trigger Discard Changes': emptyProps(),
    'Discard Changes': emptyProps(),

    'Trigger Reset Agenda': emptyProps(),
  },
});

export const MeetingSchedulingActions = createActionGroup({
  source: 'Meeting Scheduling Actions',
  events: {
    'Open Schedule Meeting Dialog': emptyProps(),
    'Add Meeting Schedule': props<{ schedule: Omit<AddMeetingScheduleModel, 'teamId' | 'companyId'> }>(),
    'Add Meeting Schedule Success': emptyProps(),
    'Add Meeting Schedule Failure': props<{ error: unknown; message: string }>(),

    'Get Meeting Schedules': emptyProps(),
    'Get Meeting Schedules Success': props<{ schedules: MeetingSchedule[] }>(),
    'Get Meeting Schedules Failure': props<{ error: unknown; message: string }>(),

    'Open Delete Meeting Schedule Dialog': props<{ schedule: MeetingSchedule }>(),

    'Delete Meeting Schedule': props<{
      scheduleId: string;
      deleteAll: boolean;
      isRepeating: boolean;
      canceledForDate?: Date; //only when deleting a specific date/period for a repeating schedule
    }>(),
    'Delete Meeting Schedule Success': emptyProps(),
    'Delete Meeting Schedule Failure': props<{ error: unknown; message: string }>(),

    'Open Update Meeting Schedule Dialog': props<{ schedule: MeetingSchedule }>(),
    'Update Meeting Schedule': props<{ updateOptions: UpdateDialogOptions }>(),
    'Update Meeting Schedule Success': emptyProps(),
    'Update Meeting Schedule Failure': props<{ error: unknown; message: string }>(),
  },
});
