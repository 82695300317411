import { inject, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { selectFeatureEnabled } from '../../_state/';
import { NinetyFeatures } from '../models/company/company-pricing-tiers';

@Pipe({
  name: 'isFeatureAvailable',
  standalone: true,
})
export class IsFeatureAvailablePipe implements PipeTransform {
  private readonly store = inject(Store);

  transform(feature: NinetyFeatures): Observable<boolean> {
    if (!feature) return of(false);
    return this.store.select(selectFeatureEnabled(feature));
  }
}
