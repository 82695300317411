import { Attachment } from '../_shared/attachment';

import { SeatModel } from './seat.model';

export type ISeatDetailAttachmentPick = Pick<SeatModel, 'attachments' | '_id'>;

export class SeatDetailAttachmentPick implements ISeatDetailAttachmentPick {
  constructor(public _id: string | null = null, public attachments: Attachment[] = []) {}
}

/** The different template states of the {@link SeatDetailComponent} */
export enum SeatDetailComponentViewType {
  loading = 'loading',
  view = 'view',
  create = 'create',
  edit = 'edit',
  error = 'error',
}

// TODO consolidate with ../seat-detail-form/seat-detail-form.component.model.ts under DEV-10825
