import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const WeeklyActions = createActionGroup({
  source: 'Weekly',
  events: {
    Initialize: emptyProps(),
    getTeams: emptyProps(),
    getTeamsWithUsersSuccess: props<{ teams: any }>(),
    setWeeklyConversationId: props<{ id: string }>(),
    clearWeeklyConversationId: emptyProps(),
    hideAlert: emptyProps(),
    hideAlertSuccess: emptyProps(),
  },
});

export const FilterActions = createActionGroup({
  source: 'Filter Actions',
  events: {
    'Filter Updated': props<{ filter: string }>(),
  },
});
