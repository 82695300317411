import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';

import { MeetingSchedule, Cadence, TimePeriod } from '@ninety/pages/meetings/_models';
import { TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/index';
import { MeetingLanguage } from '@ninety/ui/legacy/shared/index';

export type UpdateDialogOptions = {
  scheduleId: string;
  isRepeating: boolean;
  updateAll: boolean;
  update: Pick<MeetingSchedule, 'scheduledDate' | 'presenterId' | 'scribeId'>;
};

export type UpdateMeetingScheduleDialogData = {
  meetingLanguage: MeetingLanguage;
  schedule: MeetingSchedule;
  users: { value: string; name: string }[];
};

@Component({
  selector: 'ninety-update-meeting-schedule-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    FormsModule,
    ButtonComponent,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    TerraIconModule,
    TerraDividerModule,
    MatRadioModule,
  ],
  templateUrl: './update-meeting-schedule-dialog.component.html',
  styleUrls: ['./update-meeting-schedule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateMeetingScheduleDialogComponent implements OnInit {
  store = inject(Store);

  form: FormGroup;
  Cadence = Cadence;
  TimePeriod = TimePeriod;

  readonly dialogRef = inject(MatDialogRef<UpdateMeetingScheduleDialogComponent, UpdateDialogOptions>);
  readonly data = inject<UpdateMeetingScheduleDialogData>(MAT_DIALOG_DATA);

  ngOnInit(): void {
    const scheduledDate = new Date(this.data.schedule.scheduledDate);
    const { presenterId, scribeId } = this.data.schedule;
    this.form = new FormGroup({
      date: new FormControl(scheduledDate, Validators.required),
      hours: new FormControl(format(scheduledDate, 'hh'), Validators.required),
      minutes: new FormControl(format(scheduledDate, 'mm'), Validators.required),
      period: new FormControl(format(scheduledDate, 'aaaa'), Validators.required),
      presenterId: new FormControl(presenterId, Validators.required),
      scribeId: new FormControl(scribeId),
      ...(this.data.schedule.cadence !== Cadence.none
        ? {
            updateAll: new FormControl<boolean>(null, Validators.required),
          }
        : {}),
    });
  }

  onUpdate() {
    const { date, hours, minutes, period, presenterId, scribeId, updateAll } = this.form.value;

    const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const parsedHours = Number(hours);
    const adjustedHours =
      period === TimePeriod.PM ? (parsedHours === 12 ? 12 : parsedHours + 12) : parsedHours === 12 ? 0 : parsedHours;

    const scheduledDate = new Date(dateWithoutTime.setHours(adjustedHours, minutes));
    const result: UpdateDialogOptions = {
      updateAll: !!updateAll,
      scheduleId: this.data.schedule._id,
      isRepeating: this.data.schedule.cadence !== Cadence.none,
      update: {
        scheduledDate,
        presenterId,
        scribeId: scribeId || null,
      },
    };

    this.dialogRef.close(result);
  }
}
