import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { MeetingRoutes } from '@ninety/ui/legacy/shared/models/meetings/meeting-routes';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { DateDistancePipe } from '@ninety/ui/legacy/shared/pipes/dates/date-distance.pipe';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { FeatureFlagKeys, selectFeatureFlag } from '@ninety/ui/legacy/state/index';

import { MeetingDialogActions, MeetingsTabsActions } from '../../_state/meetings.actions';
import { MeetingsStateSelectors } from '../../_state/meetings.selectors';

@Component({
  selector: 'ninety-start-meeting-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TerraDividerModule,
    TerraIconModule,
    LetDirective,
    ButtonComponent,
    DateDistancePipe,
    MatDialogModule,
  ],
  templateUrl: './start-meeting-dialog.component.html',
  styles: [
    `
      @use 'terra';
      :host {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 441px;
      }
      .menu-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        height: 36px;
      }
      .header {
        padding-left: 4px;
      }
      .text-light,
      .header {
        color: terra.color('text', 'light');
      }
      terra-icon {
        color: terra.color('text', 'lightest');
      }
      mat-dialog-content {
        padding: 16px !important;
      }
      mat-dialog-actions {
        box-shadow: 0px -1px 2px 1px terra.color('shadow', 'light');
        padding: 2px 16px !important;
        margin-top: -2px !important;
      }
    `,
  ],
})
export class StartMeetingDialogComponent {
  private readonly store = inject(Store);
  private readonly dialogRef = inject(MatDialogRef<StartMeetingDialogComponent>);

  readonly recentMeetings$ = this.store.select(MeetingsStateSelectors.selectRecentMeetingsForStartDialog);
  readonly defaultMeetings$ = this.store.select(MeetingsStateSelectors.selectDefaultMeetingOptions);
  readonly showAgendasV2$ = this.store.select(selectFeatureFlag(FeatureFlagKeys.meetingAgendasV2));

  customMeetings$ = this.store.select(MeetingsStateSelectors.selectCustomMeetingSettings);
  language$ = this.store.select(selectLanguage);
  meetingType = MeetingType;

  createMeeting(meetingType: MeetingType, agendaId?: string) {
    this.dialogRef.close();
    this.store.dispatch(MeetingDialogActions.createMeeting({ meetingType, agendaId }));
  }

  manageAgendas(agendasV2 = false) {
    this.dialogRef.close();

    this.store.dispatch(
      MeetingsTabsActions.navigate({ route: agendasV2 ? MeetingRoutes.agendasV2 : MeetingRoutes.agendas })
    );
  }
}
