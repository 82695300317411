import { createReducer, on } from '@ngrx/store';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportModel, initialDataImportState } from '@ninety/data-import/_state/data-import.model';

export const dataImportReducer = createReducer(
  initialDataImportState,
  on(DataImportActions.showUsersImportConfirmDialog, state => ({
    ...state,
    showOneSchemaIframe: false,
    showPreviewTable: false,
    showedConfirmDialog: true,
  })),
  on(DataImportActions.confirmedReadyToProceed, DataImportActions.getOneSchemaToken, state => ({
    ...state,
    showOneSchemaIframe: true,
    showPreviewTable: false,
  })),
  /** make sure to set iframe before launching oneschema */
  on(
    DataImportActions.getOneSchemaTokenSuccess,
    (state, { userJwt }): DataImportModel => ({
      ...state,
      userJwt,
    })
  ),
  on(
    DataImportActions.setInitialImportType,
    DataImportActions.selectImportType,
    (state, { option }): DataImportModel => ({
      ...state,
      selectedImportType: option,
    })
  ),
  on(
    DataImportActions.selectTeam,
    (state, { teamId }): DataImportModel => ({
      ...state,
      selectedTeamId: teamId,
    })
  ),
  on(
    DataImportActions.showUsersPreviewTable,
    (state): DataImportModel => ({
      ...state,
      showOneSchemaIframe: false,
      showPreviewTable: true,
    })
  ),
  on(DataImportActions.cancel, DataImportActions.success, state => ({
    ...state,
    showOneSchemaIframe: true,
    showPreviewTable: false,
  }))
);
