export const InsightsTopRoute = 'insights';
const InsightsTopRouteTitle = 'Insights';

export enum InsightsRoutes {
  Insights = '',
  feedback = 'feedback',
  rocks = 'rocks',
}

export namespace InsightsFullRoutes {
  export const insights = InsightsTopRoute;
  export const feedback = from(InsightsRoutes.feedback);
  export const rocks = from(InsightsRoutes.rocks);

  export function from(route: InsightsRoutes): string {
    return `${InsightsTopRoute}/${route}`;
  }
}

export interface InsightsChildRouteData {
  tab: InsightsRoutes;
  title: string;
}

export const feedbackInsightsRouteData: InsightsChildRouteData = {
  tab: InsightsRoutes.feedback,
  title: InsightsTopRouteTitle,
};

export const rocksInsightsRouteData: InsightsChildRouteData = {
  tab: InsightsRoutes.rocks,
  title: InsightsTopRouteTitle,
};
