import { registerLocaleData } from '@angular/common';
import en_CA from '@angular/common/locales/en-CA';
import en_GB from '@angular/common/locales/en-GB';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import { BehaviorSubject, ReplaySubject, combineLatest, filter, map } from 'rxjs';

// ToDo: Remove after all references have been removed - DEV-7248, DEV-7249, DEV-7250
import { Person } from '../../_shared/models/_shared/person';
import { RoleCode } from '../../_shared/models/_shared/role-code';
import { RoleName } from '../../_shared/models/_shared/role-name';
import { User } from '../../_shared/models/_shared/user';
import { Locale } from '../../_shared/models/_shared/user-settings';
import { Company } from '../../_shared/models/company/company';
import { CompanyUser } from '../../_shared/models/company/company-user';
import { AppName } from '../../_shared/models/constants/app-name';
import { ItemType } from '../../_shared/models/enums/item-type';
import { CustomLanguage } from '../../_shared/models/language/custom-language';
import { MeetingType } from '../../_shared/models/meetings/meeting-type.enum';
import { PeriodInterval } from '../../_shared/models/scorecard/period-interval.enum';
import { TeamSelectors } from '../../_state/app-entities/team-list/team-list-state.selectors';
import {
  selectCurrentUserIsLiteUser,
  selectRawCurrentUser,
} from '../../_state/app-entities/users/users-state.selectors';
import { CurrentPersonStateActions } from '../../_state/app-global/current-person/current-person.actions';
import { selectCurrentPerson } from '../../_state/app-global/current-person/current-person.selectors';
import { selectBosDefaultLanguage, selectLanguage } from '../../_state/app-global/language/language.selectors';

import { AppLoadService } from './app-load.service';
import { HelperService } from './helper.service';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  isDevelopment = /localhost|test|staging|dev1|dev2/.test(location.hostname);
  appName = AppName.NINETY;
  domain = 'ninety.io';
  /** @deprecated: use this.store.select(selectLanguage) */
  language: CustomLanguage;
  /** @deprecated: use this.store.select(selectDefaultLanguage) */
  defaultLanguage$ = this.store.select(selectBosDefaultLanguage);
  private _defaultBosLanguage: CustomLanguage;
  /** @deprecated: use this.store.select(selectDefaultLanguage) */
  set defaultBosLanguage(l: CustomLanguage) {
    this._defaultBosLanguage = l;
  }
  /** @deprecated: use this.store.select(selectDefaultLanguage) */
  get defaultBosLanguage() {
    return _cloneDeep(this._defaultBosLanguage);
  }

  /** @deprecated: use this.store.select(selectCompany) */
  company: Company;
  /** @deprecated: use this.store.select(selectCompanyId) */
  companyId: string;
  /** @deprecated: use this.store.select(selectCurrentUserId) */
  companyUserId: string;
  hideToolbar = false;
  itemType = ItemType.issue;
  isPersonal = false;
  isCompanyRock = false;
  isDepartmentRock = false;
  stripeStatusActive = true;
  isPaddle = false;
  meetingType: MeetingType = MeetingType.weekly;
  meetingIsAnnual: boolean;
  subHeader: string;
  periodInterval: PeriodInterval;

  /** @deprecated: use this.store.select(selectCurrentPerson) */
  currentPerson$ = new BehaviorSubject<Person>(null);
  /** @deprecated: use this.store.select(selectCurrentUser) */
  currentCompanyUser$: BehaviorSubject<CompanyUser> = new BehaviorSubject(null);
  // Deprecate once currentCompanyUser is moved to store
  currentCompanyUser: CompanyUser;
  /** @deprecated: use this.store.select(selectCurrentUser) */
  currentUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  /** @deprecated: use this.store.select(selectCurrentUser) */
  currentUser: User;
  // Todo: Move to selector/computed property in CurrentUser
  currentUserBelongsToSlt$ = new ReplaySubject<boolean>(1);

  isShortTerm$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  pageTitle$: BehaviorSubject<string> = new BehaviorSubject('');
  localeChange$: BehaviorSubject<Locale>; // used for date pickers
  locale: Locale; // easier to use with all the date pipes
  dateFormat: 'MMM d' | 'd MMM';
  toggleMeetingSideNavB$ = new BehaviorSubject<boolean>(true);
  showListViewToggle: boolean;
  isSmallOrTouchScreen: boolean;
  enableDragAndDrop: boolean;
  userHasTeams: boolean;
  isObserver: boolean;
  isLiteUser: boolean;
  showNavLinks: boolean;

  isHelpfulActual: boolean;

  // main routes that vary between rubicon and ninety
  routeChange$ = new ReplaySubject<Data>(1);
  scorecardTrailingTabToggle$ = new BehaviorSubject<boolean>(false);

  // Min Role Group
  isOwner: boolean;
  isAdminOrOwner: boolean;
  isManagerOrAbove: boolean;
  isManageeOrAbove: boolean;

  //////////////////////////////////////////////////////////////////////////////////////////////////
  /////////This service should only pull in the AppLoadService and no other custom services/////////
  //////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private appLoadService: AppLoadService, private store: Store) {}

  init() {
    this.store.select(selectLanguage).subscribe(l => (this.language = l));
    this.defaultLanguage$.subscribe({ next: l => (this.defaultBosLanguage = l) });

    this.localeChange$ = new BehaviorSubject<Locale>(Locale.default);

    this.store
      .select(selectCurrentPerson)
      .pipe(map(p => _cloneDeep(p)))
      .subscribe(p => {
        /** this feels more about the person's identity and would belong with the current person in the ngrx store so not
         * including this with the helpful permissions for now
         * */
        this.isHelpfulActual = p?._id === '58f64a444b58c10010d09d38';
        this.currentPerson$.next(p);
      });

    combineLatest([
      this.store.select(selectRawCurrentUser),
      this.store.select(TeamSelectors.selectAll),
      // ToDo: Switch to store DEV-5974
      this.appLoadService.initialCompanyUser$,
    ])
      .pipe(
        filter(([u, t, cu]) => !!u && !!t && !!cu),
        map(([cu, ct, coU]) => ({
          currentUser: _cloneDeep(cu),
          companyTeams: _cloneDeep(ct),
          companyUser: _cloneDeep(coU),
        }))
      )
      .subscribe(data => {
        this.currentUser$.next(data.currentUser);
        this.changeLocale(data.currentUser?.settings?.preferredLocale);

        const roleName = RoleService.roleName(data.currentUser?.roleCode) as RoleName;
        this.isObserver = this.currentUser?.roleCode === RoleCode.observer;
        this.isManageeOrAbove = RoleService.minRoleName.managee(roleName);

        // Depends on both currentUser and userTeams
        // Teams on directory users don't have an _id, need to use currentUser.team.teamId
        const userTeamIds = new Set(data.currentUser.teams.map(t => t.teamId));
        const userTeams = data.companyTeams.filter(ct => userTeamIds.has(ct._id));
        this.userHasTeams = RoleService.minRoleName.admin(roleName) || userTeams?.length !== 0;
        this.setShowNavLinks(this.isObserver, this.userHasTeams);

        const sltId = data.companyUser.company.seniorLeadershipTeamId;
        this.currentUserBelongsToSlt$.next(userTeams.some(ut => ut._id === sltId));
      });

    this.store.select(selectCurrentUserIsLiteUser).subscribe(lu => (this.isLiteUser = lu));
    this.currentUser$.subscribe(u => (this.currentUser = u));

    // ToDo: Switch to store - DEV-7249
    this.appLoadService.initialCompanyUser$
      .pipe(
        filter(cu => !!cu),
        concatLatestFrom(() => this.store.select(selectCurrentPerson))
      )
      .subscribe(([cu, p]) => {
        this.companyUserId = cu._id;
        this.currentCompanyUser = cu;
        this.currentCompanyUser$.next(cu);
        this.stripeStatusActive =
          cu?.company?.accountStatus !== 'inactive' || cu?.company?.implementerFree || p?.isImplementer;
      });

    this.currentCompanyUser$
      .pipe(
        filter(c => !!c),
        concatLatestFrom(() => this.store.select(selectCurrentPerson))
      )
      .subscribe(([companyUser, person]) => {
        this.currentCompanyUser = companyUser;
        this.company = _cloneDeep(companyUser.company);
        this.companyId = companyUser.company._id;
        this.stripeStatusActive =
          this.company?.accountStatus !== 'inactive' || this.company?.implementerFree || person?.isImplementer;

        RoleService.setRolePermissionsGroup(companyUser.roleCode, this);

        if (this.company.settings.scorecardTrailing) {
          this.scorecardTrailingTabToggle$.next(this.company.settings.scorecardTrailing);
        }
      });

    // TODO return to this in DEV-4592, need a better approach here.
    this.isSmallOrTouchScreen = HelperService.isSmallOrTouchScreen();
    this.enableDragAndDrop = !this.isSmallOrTouchScreen;
  }

  setShowNavLinks(isObserver: boolean, userHasTeams: boolean): void {
    if (isObserver === true && userHasTeams === true) {
      this.showNavLinks = false;
    } else if (isObserver === true && userHasTeams === false) {
      this.showNavLinks = true;
    } else if (userHasTeams === true && isObserver === false) {
      this.showNavLinks = false;
    } else if (userHasTeams === false) {
      this.showNavLinks = true;
    }
  }

  onLogout(): void {
    this.currentUser$.next(null);
    this.store.dispatch(CurrentPersonStateActions.appLogout());
    this.currentCompanyUser$.next(null);
  }

  toggleMeetingSideNav(value: boolean) {
    this.toggleMeetingSideNavB$.next(value);
  }

  onRouteChange(data: Data): void {
    if (data.hasOwnProperty('route')) {
      this.setTitle(this.language[data.route].route);
    } else if (data.hasOwnProperty('title')) {
      if (data.title === 'headlines') {
        //TODO NEXT: add page title to language for headlines(like todos and issues) see DEV-8297
        //bos defaults and company scripts using .items as default DEV-8297
        //the remove if (data.title === 'headlines') and update meeting routing
        this.setTitle(this.language.headline.items);
      } else {
        this.setTitle(data.title);
      }
    }

    if (data.hasOwnProperty('meetingType')) {
      this.meetingType = data.meetingType;
      this.meetingIsAnnual =
        data.meetingType === MeetingType.annualDayOne || data.meetingType === MeetingType.annualDayTwo;
    }

    this.showListViewToggle = data.hasOwnProperty('showListViewToggle') ? data.showListViewToggle : true;
    this.itemType = data.hasOwnProperty('itemType') ? data.itemType : ItemType.issue;
    this.isPersonal = data.hasOwnProperty('isPersonal') ? data.isPersonal : false;
    this.isShortTerm$.next(data.hasOwnProperty('isShortTerm') ? data.isShortTerm : true);
    this.isCompanyRock = data.hasOwnProperty('isCompanyRock') ? data.isCompanyRock : false;
    this.isDepartmentRock = data.hasOwnProperty('isDepartmentRock') ? data.isDepartmentRock : false;
    this.hideToolbar = data.hasOwnProperty('hideToolbar') ? data.hideToolbar : false;
    this.subHeader = data.hasOwnProperty('subHeader') ? data.subHeader : null;
    this.periodInterval = data.hasOwnProperty('periodInterval') ? data.periodInterval : PeriodInterval.weekly;

    this.routeChange$.next(data);
  }

  setTitle(title): void {
    this.pageTitle$.next(title || '');
    document.title = `${title} | ${this.appName}`;
  }

  get isInternalCompany(): boolean {
    // ninety and acme
    return ['589348d38ebcc2000ea17f07', '5ab03c1f7b5ab7000e9b23c5'].includes(this.companyId);
  }

  changeLocale(preferredLocale = this.currentUser?.settings?.preferredLocale) {
    switch (preferredLocale) {
      case Locale.greatBritain:
        registerLocaleData(en_GB);
        this.dateFormat = 'd MMM';
        break;
      case Locale.canada:
        registerLocaleData(en_CA);
        this.dateFormat = 'MMM d';
        break;
      case Locale.default:
      default:
        this.dateFormat = 'MMM d';
        break;
    }
    this.localeChange$.next(preferredLocale);
    this.locale = preferredLocale;
  }
}
