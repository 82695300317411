import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { DetailService } from '@ninety/detail-view/_services/detail.service';
import { RocksActions } from '@ninety/rocks/_state/rocks.actions';
import * as rockSelectors from '@ninety/rocks-v2/_state/rocks-v2-state.selectors';
import { ChannelService } from '@ninety/ui/legacy/core/services/channel.service';
import { ErrorService } from '@ninety/ui/legacy/core/services/error.service';
import { FilterService } from '@ninety/ui/legacy/core/services/filter.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { NotifyService } from '@ninety/ui/legacy/core/services/notify.service';
import { ItemPrintParams, PrintApi, PrintOptions, PrintService } from '@ninety/ui/legacy/core/services/print.service';
import { QueryParamsService } from '@ninety/ui/legacy/core/services/query-params.service';
import { SpinnerService } from '@ninety/ui/legacy/core/services/spinner.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { Item } from '@ninety/ui/legacy/shared/models/_shared/item';
import {
  OrdinalOrUserOrdinalUpdate,
  OrdinalUpdateWithSort,
} from '@ninety/ui/legacy/shared/models/_shared/ordinal-or-user-ordinal-update';
import { PagedResponse } from '@ninety/ui/legacy/shared/models/_shared/paged-response';
import { ItemType } from '@ninety/ui/legacy/shared/models/enums/item-type';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { FromLinkedItem } from '@ninety/ui/legacy/shared/models/linked-items/linked-item-type-enum';
import { ListDropMessage } from '@ninety/ui/legacy/shared/models/meetings/list-drop-message';
import { ListSortMessage } from '@ninety/ui/legacy/shared/models/meetings/list-sort-message';
import { MoveListMessage } from '@ninety/ui/legacy/shared/models/meetings/move-list-message';
import type {
  RealtimeMessage,
  ReceivedRealtimeMessage,
} from '@ninety/ui/legacy/shared/models/meetings/realtime-message';
import { ArchivedRockResponse } from '@ninety/ui/legacy/shared/models/rocks/archived-rock-response';
import { GetRocksQueryParams } from '@ninety/ui/legacy/shared/models/rocks/get-rocks-query-params';
import { Milestone } from '@ninety/ui/legacy/shared/models/rocks/milestone';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { RockLevelCode } from '@ninety/ui/legacy/shared/models/rocks/rock-level-code';
import { RockMessageType } from '@ninety/ui/legacy/shared/models/rocks/rock-message-type';
import { RockOrdinalType } from '@ninety/ui/legacy/shared/models/rocks/rock-ordinal-type';
import { RockSort } from '@ninety/ui/legacy/shared/models/rocks/rock-sort';
import { RockSortField } from '@ninety/ui/legacy/shared/models/rocks/rock-sort-field';
import { RockStatusCode } from '@ninety/ui/legacy/shared/models/rocks/rock-status-code';
import { RocksAndMilestonesResponse } from '@ninety/ui/legacy/shared/models/rocks/rocks-and-milestones-response';
import { TeamSelectors } from '@ninety/ui/legacy/state/index';
import { extractValueFromStore } from '@ninety/ui/legacy/state/state-util';

@Injectable({
  providedIn: 'root',
})
export class RockService {
  private rocksUrl = '/api/v4/Rocks';
  newRock$ = new Subject<{ rock: Rock; fromBroadcast: boolean }>();
  deletedRock$ = new Subject<{ rock: Rock; fromBroadcast: boolean }>();
  updatedRock$ = new Subject<Rock>();
  unarchiveRock$ = new Subject<Rock>();
  moveList$ = new Subject<MoveListMessage>();
  sortListRock$ = new Subject<ListSortMessage>();
  dropListRock$ = new Subject<ListDropMessage>();

  newCurrentUserRock$ = new Subject<Rock>();

  addMilestoneToRock$ = new Subject<Milestone>();
  updateMilestoneOnRock$ = new Subject<Milestone>();
  archiveAllStatuses$ = new Subject();
  focusOnInlineAddRock$ = new BehaviorSubject<boolean>(false);
  focusOnInlineAddRockNew$ = new BehaviorSubject<boolean>(false);

  companyRocks: Rock[];
  companyRocksCopy: Rock[];
  departmentRocks: Rock[];
  departmentRocksCopy: Rock[];
  retrievedRocks = false;

  teamRocksPaginated$ = new BehaviorSubject<PagedResponse<Rock>>(null);

  channelId: string;
  shouldBroadcast: boolean;

  messageSubscription = new Subscription();

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
    private filterService: FilterService,
    public stateService: StateService,
    private helperService: HelperService,
    private errorService: ErrorService,
    private notifyService: NotifyService,
    private printService: PrintService,
    private detailService: DetailService<Rock>,
    private channelService: ChannelService,
    private store: Store
  ) {
    this.detailService.rockMilestoneUpdate$.subscribe({
      next: (changes: Partial<Milestone>) => {
        this.milestoneUpdate(changes);
      },
    });

    this.detailService.milestoneUpdate$.subscribe({
      next: (changes: Partial<Milestone>) => {
        this.milestoneUpdate(changes);
      },
    });
  }

  archiveAllCompleted(rocks: Rock[]): Observable<boolean> {
    return this.confirmArchiveCompletedDialog().pipe(
      mergeMap((confirmation: boolean) => {
        if (confirmation) {
          this.spinnerService.start();
          const rockIds = rocks.reduce((res, r) => {
            if (r.statusCode === RockStatusCode.complete) res.push(r._id);
            return res;
          }, []);

          return this.archiveRocksByIds(rockIds).pipe(
            map(() => {
              this.spinnerService.stop();

              from(rocks.filter(r => rockIds.some(x => x === r._id)))
                .pipe(
                  mergeMap((r: Rock) =>
                    this.broadcastMessage({
                      messageType: RockMessageType.rock,
                      document: { ...r, ...{ archived: true } },
                    })
                  )
                )
                .subscribe();

              return confirmation;
            })
          );
        }
        return of(confirmation);
      })
    );
  }

  archiveRocksByIds(rockIds: string[]): Observable<void> {
    return this.http
      .put<void>(`${this.rocksUrl}/Archive`, rockIds)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not archive all completed ${this.stateService.language.rock.items}. Please try again.`
          )
        )
      );
  }

  archiveMyCompletedRocks(): Observable<void> {
    return this.http
      .patch<void>(`${this.rocksUrl}/ArchiveMyCompleted`, {})
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not archive all completed ${this.stateService.language.rock.items}. Please try again.`
          )
        )
      );
  }

  confirmArchiveCompletedDialog(): Observable<boolean> {
    const confirmDeleteDialogRef = this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
      data: {
        title: 'Archive Completed?',
        message: `All completed ${this.stateService.language.rock.items} will be archived.`,
        confirmButtonText: 'Archive',
      },
    });
    return confirmDeleteDialogRef.afterClosed();
  }

  archiveAllStatuses(rockIds: string[]) {
    this.spinnerService.start();

    this.http.put<string[]>(`${this.rocksUrl}/Archive`, rockIds).subscribe({
      next: () => {
        const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
        this.broadcastMessage({
          messageType: RockMessageType.archiveAllStatuses,
          document: { currentTeamId: selectedTeamId },
        }).subscribe();
        this.spinnerService.stop();
      },
      error: (e: unknown) =>
        this.errorService.notify(
          e,
          `Could not archive all ${this.stateService.language.rock.items}. Please try again.`
        ),
    });
  }

  create(
    rock: Item,
    userIds: string[],
    createdFrom?: FromLinkedItem,
    addCreatorToFollowersList = true
  ): Observable<Rock[]> {
    rock.companyId = this.stateService.companyId;
    rock.dueDate = HelperService.fixDateForDb(rock.dueDate);
    return this.http.post<Rock[]>(this.rocksUrl, { rock, userIds, from: createdFrom, addCreatorToFollowersList }).pipe(
      tap((rocks: Rock[]) => {
        const rocksV3 = extractValueFromStore(this.store, rockSelectors.selectRocksV3);
        rocks.map(newRock => {
          if (newRock.userId === this.stateService.currentUser._id) {
            this.newCurrentUserRock$.next(newRock);
            this.store.dispatch(RocksActions.createRock({ rock: _cloneDeep(newRock) }));
          }
          if (!rocksV3) {
            const selectedTeamId = extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId);
            if (newRock.teamId === selectedTeamId || newRock.additionalTeamIds?.includes(selectedTeamId)) {
              if (
                newRock.levelCode === RockLevelCode.companyAndDepartment ||
                newRock.levelCode === RockLevelCode.company
              ) {
                this.companyRocks = [...(this.companyRocks || []), newRock];
                this.companyRocksCopy = _cloneDeep(this.companyRocks);
              }
              if (
                newRock.levelCode === RockLevelCode.department ||
                newRock.levelCode === RockLevelCode.companyAndDepartment
              ) {
                this.departmentRocks = [...(this.departmentRocks || []), newRock];
                this.departmentRocksCopy = _cloneDeep(this.departmentRocks);
              }
              this.newRock$.next({ rock: newRock, fromBroadcast: false });
            }
          } else {
            this.newRock$.next({ rock: newRock, fromBroadcast: false });
          }
        });
        this.broadcastMessage({
          messageType: RockMessageType.newMulti,
          document: rocks,
        }).subscribe();

        this.focusOnInlineAddRock$.next(true);
        this.focusOnInlineAddRockNew$.next(true);
        this.spinnerService.stop();
      }),
      catchError((e: unknown) => {
        this.focusOnInlineAddRock$.next(false);
        this.focusOnInlineAddRockNew$.next(false);
        return this.errorService.notify(
          e,
          `Could not create ${this.stateService.language.rock.item}.  Please try again.`
        );
      })
    );
  }

  delete(rock: Rock) {
    return this.http.patch<any>(`${this.rocksUrl}/${rock._id}`, { deleted: true }).pipe(
      tap(_ => {
        this.deletedRock$.next({ rock: { ...rock, deleted: true }, fromBroadcast: false });
        this.broadcastMessage({
          messageType: RockMessageType.delete,
          document: { ...rock, deleted: true },
        }).subscribe();
      }),
      catchError((e: unknown) =>
        this.errorService.notify(e, `Could not delete ${this.stateService.language.rock.item}.  Please try again.`)
      )
    );
  }

  getCompletedDate(completed?: boolean | RockStatusCode): Date | null {
    if (completed === true || RockStatusCode.complete === completed) return new Date();
    else return null;
  }

  getArchivedRocks(
    page: number,
    pageSize: number,
    sortField: RockSortField,
    sortDirection: SortDirection
  ): Observable<ArchivedRockResponse> {
    this.spinnerService.start();
    const params = QueryParamsService.build({
      teamId: extractValueFromStore(this.store, TeamSelectors.selectFilterBarTeamId),
      page,
      pageSize,
      sortField,
      sortDirection,
      statusCode: this.filterService.selectedRockStatusCode$.value,
      searchText: this.filterService.searchText$.value.trim(),
    });
    return this.http.get<ArchivedRockResponse>(`${this.rocksUrl}/Archive`, { params }).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get archived ${this.stateService.language.rock.items}.
       Please try refreshing the page.`
        )
      )
    );
  }

  getRockById(id: string, skipLocal = true): Observable<Rock> {
    if (skipLocal) {
      return this.http
        .get<Rock>(`${this.rocksUrl}/${id}`)
        .pipe(
          catchError((e: unknown) =>
            this.errorService.notify(e, `Could not get ${this.stateService.language.rock.item}.  Please try again.`)
          )
        );
    }

    return this.teamRocksPaginated$.pipe(
      map(resp => resp?.items?.find(r => r._id === id) ?? this.companyRocks?.find(r => r._id === id)),
      switchMap(rock => {
        if (!!rock) return of(_cloneDeep(rock));

        return this.getRockById(id);
      })
    );
  }

  getRocksByTeamIdV2(params: GetRocksQueryParams): Observable<{ [teamId: string]: Rock[] }> {
    const compiledParams = QueryParamsService.build(params, true);

    return this.http.get<{ [teamId: string]: Rock[] }>(`${this.rocksUrl}`, { params: compiledParams }).pipe(
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get ${this.stateService.language.rock.items}.
          Please try refreshing the page.`
        )
      )
    );
  }
  getRocksByTeamId(teamId: string, vtoRocks = true): Observable<{ [teamId: string]: Rock[] }> {
    return this.http.get<{ [teamId: string]: Rock[] }>(`${this.rocksUrl}?teamId=${teamId}`).pipe(
      tap(resp => {
        if (vtoRocks) {
          this.companyRocks = resp[teamId].filter(
            rock => rock.levelCode === RockLevelCode.companyAndDepartment || rock.levelCode === RockLevelCode.company
          );
          this.companyRocksCopy = _cloneDeep(this.companyRocks);
        }

        this.teamRocksPaginated$.next({
          items: resp[teamId],
          totalCount: resp[teamId].length,
        });
      }),
      catchError((e: unknown) =>
        this.errorService.notify(
          e,
          `Could not get ${this.stateService.language.rock.items}.
          Please try refreshing the page.`
        )
      )
    );
  }

  getRocksAndMilestonesV2(params: GetRocksQueryParams): Observable<RocksAndMilestonesResponse> {
    const compiledParams = QueryParamsService.build(params, true);
    return this.http
      .get<RocksAndMilestonesResponse>(`${this.rocksUrl}/V2/RocksAndMilestones`, { params: compiledParams })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.rock.items} and ${this.stateService.language.milestone.items}.
          Please try refreshing the page.`
          )
        )
      );
  }

  toggleArchive(rock: Rock, primary = false) {
    primary ? this.spinnerService.start() : this.spinnerService.startAuxiliary();

    rock.archived = !rock.archived;
    rock.archivedDate = rock.archived ? new Date() : null;

    //NOTE: looks like this broadcastMessage is not needed, only time this is called is from rock.component,
    //can a single rock be in a meeting?
    this.broadcastMessage({
      messageType: rock.archived ? RockMessageType.rock : RockMessageType.new,
      document: rock,
    }).subscribe();

    return this.http
      .patch<any>(`${this.rocksUrl}/${rock._id}`, {
        archived: rock.archived,
        archivedDate: rock.archivedDate,
      })
      .pipe(
        tap(() => {
          this.spinnerService.stop();
          this.notifyService.notify(
            `${this.stateService.language.rock.item} ${rock.archived ? 'archived' : 'unarchived'}.`,
            3000,
            undefined
          );
        }),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.rock.item}.  Please try again.`)
        )
      );
  }

  updateUser(rockId: string, userId: string): Observable<Rock> {
    return this.update(rockId, { userId });
  }

  updateV2(rockId: string, update: Partial<Rock>): Observable<Rock> {
    const keys = Object.keys(update);
    return this.http
      .patch<Rock>(`${this.rocksUrl}/${rockId}`, {
        ...update,
        //without this the day of the date ends up being different, fix in a future refactor
        ...(keys.includes('dueDate') ? { dueDate: HelperService.fixDateForDb(update.dueDate) } : null),
      })
      .pipe(
        tap(rock => {
          this.spinnerService.stop();

          if (keys.includes('statusCode')) {
            this.store.dispatch(RocksActions.updateRockStatus({ rock: _cloneDeep(rock) }));
          }
          this.broadcastMessage({
            messageType: RockMessageType.rock,
            document: rock,
          }).subscribe();
        }),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.rock.item}.  Please try again.`)
        )
      );
  }

  update(rockId: string, update: Partial<Rock>): Observable<Rock> {
    this.updateLocal(rockId, update);

    const keys = Object.keys(update);
    return this.http
      .patch<Rock>(`${this.rocksUrl}/${rockId}`, {
        ...update,
        //without this the day of the date ends up being different, fix in a future refactor
        ...(keys.includes('dueDate') ? { dueDate: HelperService.fixDateForDb(update.dueDate) } : null),
      })
      .pipe(
        tap(rock => {
          this.spinnerService.stop();

          if (keys.includes('statusCode')) {
            this.store.dispatch(RocksActions.updateRockStatus({ rock: _cloneDeep(rock) }));
          }
          this.broadcastMessage({
            messageType: RockMessageType.rock,
            document: rock,
          }).subscribe();
        }),
        catchError((e: unknown) =>
          this.errorService.notify(e, `Could not update ${this.stateService.language.rock.item}.  Please try again.`)
        )
      );
  }

  updateOrdinals(rocks: Rock[], prop: 'userOrdinal' | 'ordinal' = 'userOrdinal'): void {
    if (!rocks || !rocks.length) return;
    const rocksV3 = extractValueFromStore(this.store, rockSelectors.selectRocksV3);
    let models: OrdinalOrUserOrdinalUpdate[];
    if (rocksV3) {
      models = rocks.map((t: Rock) => new OrdinalOrUserOrdinalUpdate(t._id, t[prop], prop));
    } else {
      models = rocks.map((t: Rock, i: number) => new OrdinalOrUserOrdinalUpdate(t._id, i, prop));
    }
    this.http
      .put<any>(`${this.rocksUrl}/Ordinals?property=${prop}`, { models })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Could not update order.  Please try again.`)))
      .subscribe();
  }

  updateOrdinalsV2(
    models: OrdinalOrUserOrdinalUpdate[],
    ordinalKey: RockOrdinalType = 'userOrdinal',
    //TODO NEXT: not currently used/not using on My90(should be used on the Rocks v2 depending on design)
    // teamId?: string,
    userId?: string,
    sort?: {
      direction: SortDirection;
      field: RockSort;
    }
  ) {
    this.http
      .put<OrdinalUpdateWithSort>(`${this.rocksUrl}/OrdinalsV2`, {
        // ...(teamId ? { teamId } : null),  // not currently used
        ...(sort.field ? { sort } : null),
        ...(userId ? { userId } : null),
        ordinalKey,
        models,
      })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, `Could not update the order. Please try again.`)))
      .subscribe();
  }

  getRocksForUserByDateRange(userId: string, startDate: Date, endDate: Date): Observable<Rock[]> {
    return this.http
      .get<Rock[]>(`${this.rocksUrl}/DateRange?userId=${userId}&startDate=${startDate}&endDate=${endDate}`)
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(
            e,
            `Could not get ${this.stateService.language.rock.items}.  Please try refreshing the page.`
          )
        )
      );
  }

  printToPDF(rockId: string) {
    this.printService
      .openPdf<ItemPrintParams>(PrintApi.item, {
        id: rockId,
        type: ItemType.rock,
        printOptions: new PrintOptions(),
      })
      .subscribe();
  }

  updateLocal(rockId: string, update: Partial<Rock>): void {
    let onTeamPaginated = false;
    let r = this.teamRocksPaginated$.value?.items.find(i => i._id === rockId);
    if (r) onTeamPaginated = true;
    else r = this.companyRocks?.find(r => r._id === rockId);

    if (r) {
      Object.assign(r, update);

      if (onTeamPaginated) {
        this.teamRocksPaginated$.next({
          items: this.teamRocksPaginated$.value?.items,
          totalCount: this.teamRocksPaginated$.value?.items.length,
        });
      }
    }
  }

  broadcastMessage(message: RealtimeMessage): Observable<any> {
    if (this.shouldBroadcast) {
      this.sanitizeMessage(message);
      return this.channelService.sendMessage(this.channelId, message);
    } else {
      return of({});
    }
  }

  subscribeToRockChannel(teamId: string) {
    this.channelId = `rock-${this.stateService.companyId}-${teamId}`;
    this.shouldBroadcast = true;
    this.subscribeToMessages();
  }

  sanitizeMessage(message: RealtimeMessage) {
    // I could not find a reason to send milestones in the pubnub request
    // milestones have their own pubnub events
    switch (message.messageType) {
      case RockMessageType.rock:
      case RockMessageType.new:
        const rock = message.document as Rock;
        if (rock.milestones) delete rock.milestones;
        break;
    }
  }

  subscribeToMessages() {
    const rocksV3 = extractValueFromStore(this.store, rockSelectors.selectRocksV3);
    this.messageSubscription = this.channelService.messageReceived$.subscribe({
      next: message => {
        switch (message.messageType) {
          case RockMessageType.rock:
            this.updatedRock$.next(message.document as Rock);
            break;
          case RockMessageType.newMulti: {
            const rocks = message.document as Rock[];
            rocks.forEach(newRock => {
              if (!rocksV3 && newRock.levelCode === RockLevelCode.companyAndDepartment) {
                this.companyRocks = [...(this.companyRocks || []), newRock];
                this.companyRocksCopy = _cloneDeep(this.companyRocks);
              }
              this.newRock$.next({ rock: newRock, fromBroadcast: true });
            });
            break;
          }
          case RockMessageType.new: {
            const newRock = message.document as Rock;
            if (!rocksV3 && newRock.levelCode === RockLevelCode.companyAndDepartment) {
              this.companyRocks = [...(this.companyRocks || []), newRock];
              this.companyRocksCopy = _cloneDeep(this.companyRocks);
            }
            this.newRock$.next({ rock: newRock, fromBroadcast: true });
            break;
          }
          case RockMessageType.delete:
            this.deletedRock$.next({ rock: message.document as Rock, fromBroadcast: true });
            break;
          case RockMessageType.unarchive:
            this.executeUnarchiveRockMessage(message);
            break;
          case RockMessageType.moveList:
            this.moveList$.next(message.document);
            break;
          case RockMessageType.drop:
            this.dropListRock$.next(message.document);
            break;
          case RockMessageType.sort:
            this.sortListRock$.next(message.document);
            break;
          case RockMessageType.fetch:
            this.handleMessageWithApiGet(message);
            break;
          case RockMessageType.milestone:
            this.updateMilestoneOnRock$.next(message.document);
            break;
          case RockMessageType.newMilestone:
            this.addMilestoneToRock$.next(message.document);
            break;
          case RockMessageType.archiveAllStatuses:
            this.archiveAllStatuses$.next(message.document);
            break;
        }
      },
      error: (err: unknown) => console.error(err),
    });
  }

  destroyRockChannel() {
    this.messageSubscription.unsubscribe();
  }

  executeUnarchiveRockMessage(message: ReceivedRealtimeMessage) {
    this.getRockById((message.document as Rock)._id).subscribe({
      next: (rock: Rock) => {
        this.unarchiveRock$.next(rock);
      },
    });
  }

  handleMessageWithApiGet(message: ReceivedRealtimeMessage): void {
    // Get the required object from API and treat as a pubnub message
    switch (message.originalMessageType) {
      case RockMessageType.rock:
      case RockMessageType.new:
        this.getRockById((message.document as Rock)._id).subscribe({
          next: (rock: Rock) => {
            this.channelService.messageReceived$.next({
              messageType: message.originalMessageType,
              document: rock,
            } as ReceivedRealtimeMessage);
          },
        });
        break;
    }
  }

  downloadExcel(): Observable<ArrayBuffer> {
    this.spinnerService.start();
    return this.http
      .get(
        `${this.rocksUrl}/Excel?teamId=${extractValueFromStore(
          this.store,
          TeamSelectors.selectFilterBarTeamId
        )}&archived=${this.filterService.showArchived$.value}`,
        {
          headers: {
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .pipe(
        tap(() => this.spinnerService.stop()),
        catchError((e: unknown) =>
          this.errorService.notify(e, `There was a problem creating this Excel file.  Please try again.`)
        )
      );
  }

  private milestoneUpdate(changes: Partial<Milestone>) {
    [...(this.teamRocksPaginated$.value?.items || []), ...(this.companyRocks || [])].forEach(r => {
      const milestone = r?.milestones?.find(m => m._id === changes._id);
      if (milestone) {
        Object.assign(milestone, changes);

        const rockUpdate: Partial<Rock> = {
          milestones: r.milestones,
        };
        this.updateLocal(r._id, rockUpdate);
      }
    });
  }
}

export interface RockResponse {
  clientError: any;
  errors: any;
  rock: Rock;
  route: string;
}
