import { Color } from '../_shared/color.enum';
import { CompanyMeetingAgendas } from '../_shared/team';
import { AccountStatusV2 } from '../billingv2/account-status-v2.enum';
import type { Subscription } from '../billingv2/subscription.model';
import { AcceptableStandards } from '../feedback/acceptable-standards';
import { Commitment } from '../feedback/commitments';
import { CustomLanguage } from '../language/custom-language';
import { Mastery } from '../mastery/mastery';
import { PaddleCoupon } from '../paddle/coupons/paddle-coupon';
import { PaddleCurrency } from '../paddle-js/paddle-currency.enum';
import { PaddleStatus } from '../paddle-js/paddle-status.enum';
import { Vto } from '../vto/vto';

import { BillingTypes } from './billing-types.enum';
import { BusinessOperatingSystem } from './business-operating-system.enum';
import { ClearbitEmployeeRange, ClearbitIndustry } from './clearbit.model';
import { FeatureAccess } from './company-pricing-tiers';
import { CompanySettings } from './company-settings';
import { HowFamiliar } from './how-familiar.enum';

export interface Company {
  _id: string;
  acceptableStandards: AcceptableStandards;
  accountStatus?: AccountStatus | AccountStatusV2;
  trialingUntil?: string;
  affiliateCode: string;
  billing: Billing;
  companyId: string;
  countryCode?: null;
  deleted?: boolean;
  eosOtherType: string;
  eosType: string;
  implementerCode: string;
  implementerFree?: boolean;
  lastAccessed: string;
  logo: Logo;
  name: string;
  billingType?: BillingTypes;
  subscription: Partial<Subscription>;
  paddleArchive?: Partial<CompanyPaddle>;
  parentAffiliateCode: string;
  phoneNumber?: null;
  referralOtherType: string;
  referralType: string;
  seniorLeadershipTeamId: string;
  settings: CompanySettings;
  statuses: Statuses;
  stripe: Stripe;
  teams?: TeamsEntity[] | null;
  trial: Trial;
  trialingUserLimit: number;
  userLimit: number;
  partnerKey: string; // partner stack parent (partner) person _id
  createdByPersonId?: string;
  industry?: ClearbitIndustry;
  employeeRange?: ClearbitEmployeeRange;
  preventCoachControl?: boolean;
  bos?: BusinessOperatingSystem;
  labels?: CustomLanguage;
  vto?: Vto;
  meetingAgendas?: CompanyMeetingAgendas;
  leadershipCommitments?: Commitment[];
  coachingCommitments?: Commitment[];
  masteries?: Mastery[];
  howFamiliar?: HowFamiliar;
  guideAllowed?: boolean;
  isCoached?: boolean;
  featureAccess?: FeatureAccess[];
}

export class Logo {
  /** @deprecated */
  count?: number;
  url?: string | null;
}

export class MeasurableWeeklyStartDay {
  offset: -1 | 0 | 1 | 2 | 3 | 4 | 5;
  day: 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
}

export interface ClaimsEntity {
  scope?: string[] | null;
}

export interface MaxSeatLicenses {
  regular: number;
  lite: number;
  validUntil: string;
  lastUpdated: string;
}

export interface SeatLicenses {
  regular: number;
  lite: number;
  observers?: number;
  lastUpdated: string;
}

export interface CompanyPaddle {
  cancellationActiveUntil?: string;
  coupons?: PaddleCoupon[];
  created: Date;
  currency: PaddleCurrency;
  currentPrice: number;
  email: string;
  modifier?: string;
  productId: number;
  maxSeatLicenses?: MaxSeatLicenses;
  seatLicenses?: SeatLicenses;
  status: PaddleStatus;
  subscriptionId: number;
  trialEnd: Date;
}

export interface Stripe {
  monthly?: FreeOrTier1OrTier2OrTier3OrCombined;
  annual?: FreeOrTier1OrTier2OrTier3OrCombined;
  coupon?: string;
  customerId: string;
}

export interface FreeOrTier1OrTier2OrTier3OrCombined {
  plan: string;
  subscriptionId: string;
  trialEnd?: string;
}

export interface Statuses {
  inactiveDate?: null;
  active: boolean;
}

export interface Trial {
  active: boolean;
}

export interface TeamsEntity {
  _id: string;
  name: string;
  project: boolean;
  users?: UsersEntity[] | null;
}

export interface UsersEntity {
  _id: string;
  roleCode: string;
  teams?: TeamsEntity1[] | null;
  metadata: Metadata;
  $$hashKey?: string | null;
}

export interface TeamsEntity1 {
  teamId: string;
}

export interface Metadata {
  picture: Logo;
  name: Name;
}

export interface Name {
  first: string;
  last: string;
}

export interface Billing {
  card: Card;
  coupons?: CouponsEntity[] | null;
  trial: Trial1;
  quantity: Quantity;
}

export interface Card {
  brand?: null;
  last4?: null;
  expMonth?: null;
  expYear?: null;
}

export interface CouponsEntity {
  name: string;
  type: string;
  amount: string;
}

export interface Trial1 {
  expiration?: null;
  active: boolean;
}

export interface Quantity {
  free: number;
  tier1: number;
  tier2: number;
  tier3: number;
}

// TODO: Never instantiated. Convert to interface or expose
// props as params and use to create color branding objects
export class ColorBranding {
  primaryAccent?: string;
  secondaryAccent?: string;
  active?: boolean;

  constructor() {
    this.primaryAccent = Color.primaryAccent;
    this.secondaryAccent = Color.secondaryAccent;
    this.active = false;
  }
}

export enum AccountStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TRIALING = 'trialing',
  EOSI = 'eosi',
  // seem to be unused
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
}

export const AccountStatusText = {
  active: 'Active',
  past_due: 'Past Due',
  inactive: 'Inactive',
  trialing: 'Trialing',
  unpaid: 'Unpaid',
  eosi: 'Free',
};
