import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { MatListModule } from '@angular/material/list';

import {
  AccountabilityDragDropEvent,
  AccountabilityListItem,
  AccountabilityListItemTextChange,
  AccountabilityRemoveEvent,
  Identifiable,
} from '@ninety/accountability-chart/models/accountabilities';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';

import { AccountabilityListItemComponent } from '../accountability-list-item/accountability-list-item.component';

@Component({
  selector: 'ninety-accountability-list',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatListModule, DragDropModule, ButtonComponent, AccountabilityListItemComponent],
  template: `
    <div
      *ngIf="accountabilities?.length; else empty"
      cdkDropList
      [cdkDropListDisabled]="!canEdit || accountabilities.length === 1"
      [cdkDropListData]="accountabilities"
      (cdkDropListDropped)="emitOnDragDrop($event)"
      class="accountabilities-list">
      <ninety-accountability-list-item
        *ngFor="let item of accountabilities; trackBy: trackById"
        role="listitem"
        cdkDrag
        [cdkDragDisabled]="!canEdit || item.isEditing"
        [cdkDragBoundary]="elementRef.nativeElement"
        [item]="item"
        [canEdit]="canEdit"
        (clickedRemove)="onClickRemoveAccountability($event)"
        (enterEdit)="enterEditAccountability.emit({ id: item.id })"
        (exitEdit)="exitEditAccountability.emit({ id: item.id })"
        (save)="save.emit($event)" />
    </div>

    <!-- Empty State -->
    <ng-template #empty>
      <div class="empty">No accountabilities have been added yet.</div>
    </ng-template>
  `,
  styles: [
    `
      @use 'terra';
      @import 'abstracts';

      .accountabilities-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .empty {
        color: terra.color('text', 'light');

        @include terra.text(body);
      }
    `,
  ],
})
export class AccountabilityListComponent {
  @Input() accountabilities: AccountabilityListItem[];
  @Input() canEdit = true;

  @Output() clickedRemove = new EventEmitter<AccountabilityRemoveEvent>();
  @Output() dragDrop = new EventEmitter<AccountabilityDragDropEvent>();
  @Output() enterEditAccountability = new EventEmitter<Identifiable>();
  @Output() exitEditAccountability = new EventEmitter<Identifiable>();
  @Output() save = new EventEmitter<AccountabilityListItemTextChange>();

  constructor(
    /** Used to define the boundaries of the CDK drag drop list */
    protected elementRef: ElementRef
  ) {}

  /**
   * Notify parent that the user clicked delete on an accountability.
   */
  onClickRemoveAccountability(item: AccountabilityListItem) {
    this.clickedRemove.emit({ item });
  }

  emitOnDragDrop({ previousIndex, currentIndex }: CdkDragDrop<AccountabilityListItem[]>) {
    this.dragDrop.emit({ previousIndex, currentIndex });
  }

  trackById(_index: number, item: AccountabilityListItem): string {
    return item.id;
  }
}
