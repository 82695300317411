import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getType as getMimeType } from 'mime';
import { Observable, Subject, catchError, map, mergeMap, switchMap, tap } from 'rxjs';

import { Attachment } from '../../_shared/models/_shared/attachment';
import { AttachmentEvent, AttachmentEventParentType } from '../../_shared/models/_shared/attachment-event';
import { AttachmentInfo } from '../../_shared/models/_shared/attachment-info';
import { AttachmentMessageType } from '../../_shared/models/_shared/attachment-message-type';
import { AttachmentParentType } from '../../_shared/models/_shared/attachment-parent-type';
import { OrdinalUpdate } from '../../_shared/models/_shared/ordinal-update';
import { AvatarType } from '../../_shared/models/library/avatar-type.enum';
import { SignPutResponseDto } from '../../_shared/models/library/signed-put-response.dto';
import { Todo } from '../../_shared/models/todos/todo';

import { ErrorService } from './error.service';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  attachmentApi = '/api/v4/Library/Attachments';
  // TODO: Move to new service
  imagesApi = '/api/v4/Library/Avatars/get-signed-put-url';

  private attachmentEventB$ = new Subject<AttachmentEvent>();
  public attachmentEvent$ = this.attachmentEventB$.asObservable().pipe(
    map((attEvent: AttachmentEvent) => ({
      ...attEvent,
      // This is now defined once and adds the field on all events
      meetingMessageType: this.getMeetingMessageTypeFromAttachmentType(attEvent.attachment.parentType),
    }))
  );

  private attachmentDragAndDropEventB$ = new Subject<AttachmentEventParentType>();
  public attachmentDragAndDropEvent$ = this.attachmentDragAndDropEventB$.asObservable().pipe(
    map((attEvent: AttachmentEventParentType) => ({
      ...attEvent,
      // This is now defined once and adds the field on all events
      meetingMessageType: this.getMeetingMessageTypeFromAttachmentType(attEvent.attachments[0]?.parentType),
    }))
  );

  constructor(private http: HttpClient, private errorService: ErrorService, private fileService: FileService) {}

  /**
   * Extracted from @see LibraryService.getAttachmentUrl
   * Omits error notifications, leaving it up to consumer how to handle errors.
   */
  getAttachmentUrlBase(file: File, parentId: string, parentType: AttachmentParentType): Observable<AttachmentInfo> {
    const filename = this.fileService.sanitizeFilename(file.name);

    return this.http.post<AttachmentInfo>(`${this.attachmentApi}`, {
      fileName: filename,
      contentType: file.type !== '' ? file.type : getMimeType(filename),
      parentId,
      parentType,
    });
  }

  /**
   * Creates an attachment record in the DB and returns a signed PutObject url for AWS S3.
   */
  getAttachmentUrl(file: File, parentId: string, parentType: AttachmentParentType): Observable<AttachmentInfo> {
    return this.getAttachmentUrlBase(file, parentId, parentType).pipe(
      catchError((e: unknown) => this.errorService.notify(e, 'Could not upload attachment.  Please try again.'))
    );
  }

  /**
   * Uses signed PutObject URL to upload file object to S3.
   */
  uploadAttachment({
    attachmentInfo,
    data,
    parent,
  }: {
    attachmentInfo: AttachmentInfo;
    data: File;
    parent: AttachmentEventParentType;
  }): Observable<AttachmentInfo> {
    const headers = new HttpHeaders({
      'Content-Type': data.type,
      'Content-Disposition': `attachment; filename=${data.name}`,
    });

    return this.http.put<AttachmentInfo>(attachmentInfo.signedUploadUrl, data, { headers }).pipe(
      tap(_ => {
        this.attachmentEventB$.next({
          type: 'upload',
          attachment: attachmentInfo.attachment,
          parent,
        });
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not upload attachment.  Please try again.'))
    );
  }

  uploadAttachmentWithStatus(attachment: AttachmentInfo, file: File): Observable<HttpEvent<AttachmentInfo>> {
    const fileName = this.fileService.sanitizeFilename(file.name);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': file.type,
        'Content-Disposition': `attachment; filename=${fileName}`,
      }),
      observe: 'events',
      reportProgress: true,
    };
    const httpReq = new HttpRequest('put', attachment.signedUploadUrl, file, options);
    return this.http.request(httpReq);
  }

  uploadFiles(itemId: string, parentType: AttachmentParentType, files: File[], parent: AttachmentEventParentType) {
    return files.map((file: File) => {
      const fileWithMimeType = this.getFileWithMimeType(file);
      if (!fileWithMimeType) return;

      return this.getAttachmentUrl(fileWithMimeType, itemId, parentType).pipe(
        mergeMap((attachmentInfo: AttachmentInfo) =>
          this.uploadAttachment({ attachmentInfo, data: fileWithMimeType, parent }).pipe(
            map(() => attachmentInfo.attachment),
            mergeMap((attachment: Attachment) =>
              this.setUploadedAttachment(attachment).pipe(map(attachmentUp => attachmentUp))
            )
          )
        )
      );
    });
  }

  /**
   * Soft deletes the attachment record but DOES NOT delete the S3 object.
   */
  deleteAttachment(attachment: Attachment, parent: AttachmentEventParentType): Observable<Attachment> {
    return this.http.patch<Attachment>(`${this.attachmentApi}/${attachment._id}`, { isDeleted: true }).pipe(
      tap(_ => {
        this.attachmentEventB$.next({
          type: 'remove',
          attachment,
          parent,
        });
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not delete attachment.  Please try again.'))
    );
  }

  /**
   * Patches an attachment record with `uploaded: true`.
   */
  setUploadedAttachment(attachment: Attachment): Observable<Attachment> {
    return this.http
      .patch<Attachment>(`${this.attachmentApi}/${attachment._id}`, { uploaded: true })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, 'Failed to confirm upload was successful. Please try again.')
        )
      );
  }

  /**
   * Associates an already created attachment with a new parent. Useful for associating an uploaded attachment to a
   * newly created item.
   */
  associateAttachmentToItem(patch: Pick<Attachment, '_id' | 'parentId'>): Observable<Attachment> {
    return this.http
      .patch<Attachment>(`${this.attachmentApi}/${patch._id}`, { parentId: patch.parentId })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, 'Failed to confirm upload was successful. Please try again.')
        )
      );
  }

  /**
   * Retrieves a signed GetObject URL for the designated attachment object.
   */
  getAttachmentDownloadUrl(attachmentId: string): Observable<{ signedUrl: string }> {
    return this.http.get<{ signedUrl: string }>(`${this.attachmentApi}/${attachmentId}`);
  }

  /**
   * V1 - Opens attachment url deterministically
   * V2 - Makes a request for a signed url and then opens the signed url in a new tab
   */
  downloadAttachment(attachment: Attachment) {
    if (attachment.isV2) {
      // Will become the default with DEV-7525
      this.getAttachmentDownloadUrl(attachment._id)
        .pipe(switchMap(resp => this.fileService.openTab(resp.signedUrl)))
        .subscribe();
    } else {
      // TODO: Deprecate once all attachments are marked V2
      // Will be removed with DEV-7525
      window.open(
        `https://s3.amazonaws.com/${attachment.bucketName}/${attachment.companyId}/${
          attachment.directoryId || attachment._id
        }`
      );
    }
  }

  // ============= Avatars / Logos (Images) ==================
  // TODO: Separate avatars code into its own service
  getImageUploadUrl(avatarType: AvatarType, relevantId?: string): Observable<SignPutResponseDto> {
    let params = new HttpParams().set('avatarType', avatarType);
    if (relevantId) params = params.set('relevantId', relevantId);

    return this.http
      .get<SignPutResponseDto>(this.imagesApi, { params })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not upload image.  Please try again.')));
  }

  uploadImage(url: string, data: Blob): Observable<any> {
    // We need to get the actual file data from the string without the base64 prefix
    // const base64String = data.replace(/^data:image\/\w+;base64,/, '');

    const headers = new HttpHeaders({ 'Content-Type': 'image/png' });
    return this.http
      .put<any>(url, data, { headers })
      .pipe(catchError((e: unknown) => this.errorService.notify(e, 'Could not upload image.  Please try again.')));
  }

  // TODO: Move to file service
  getFileWithMimeType(file: File): File {
    try {
      const mimeType = getMimeType(this.fileService.sanitizeFilename(file.name));
      if (!mimeType) throw new Error('File MIME type missing.');
      return this.generateNewFile(file, mimeType);
    } catch (e) {
      this.errorService.notify(e, 'Not a recognized file type.  Please try again.');
    }
  }

  // TODO: Move to file service
  private generateNewFile(file: File, type: string): File {
    const filename = this.fileService.sanitizeFilename(file.name);

    try {
      const blob: Blob = new Blob([file], { type });
      blob['name'] = filename;
      blob['lastModifiedDate'] = file.lastModified;

      return <File>Object.assign(blob, {
        name: filename,
        lastModified: file.lastModified,
      });
    } catch (e) {
      this.errorService.notify(e, 'Error while preparing file for upload. Please try again.');
    }
  }

  updateAttachmentOrdinals(parent: AttachmentEventParentType): Observable<void> {
    const models: OrdinalUpdate[] = parent.attachments.map((a: Attachment, index: number) => ({
      _id: a._id,
      ordinal: index,
    }));

    return this.http.put<void>(`${this.attachmentApi}/Ordinals`, { models }).pipe(
      tap(_ => {
        this.attachmentDragAndDropEventB$.next(parent);
      }),
      catchError((e: unknown) => this.errorService.notify(e, 'Could not update attachment ordinals. Please try again.'))
    );
  }

  updateOrdinals(updatedOrdinals: OrdinalUpdate[]): Observable<void> {
    return this.http
      .put<void>(`${this.attachmentApi}/Ordinals`, { models: updatedOrdinals })
      .pipe(
        catchError((e: unknown) =>
          this.errorService.notify(e, 'Could not update attachment ordinals. Please try again.')
        )
      );
  }

  private getMeetingMessageTypeFromAttachmentType(parentType: AttachmentParentType): AttachmentMessageType {
    return AttachmentMessageType[parentType];
  }

  handleTodoFileUploads(item: Todo[], fileAttachments: File[]): Observable<Attachment>[] {
    const observables: Observable<Attachment>[] = [];
    item.forEach((i: Todo) => observables.push(...this.uploadFiles(i._id, 'To-Do', fileAttachments, i)));
    return observables;
  }
}
