import { createSelector } from '@ngrx/store';

import { QuickFilterOption } from '../../_components';
import { RoleCode, User } from '../../_shared';
import { Team } from '../../_shared/models/_shared/team';
import { EditedTeamSelectors, TeamSelectors } from '../app-entities/team-list/team-list-state.selectors';
import { CurrentUserSelectors, UserListSelectors } from '../app-entities/user-list/user-list-state.selectors';
import { selectActiveUsers, selectCurrentUser, UserSelectors } from '../app-entities/users/users-state.selectors';
import { selectCompanySltTeamId, selectIsOrgChartPrivate } from '../app-global/company/company-state.selectors';

export namespace TeamDetailSelectors {
  export const selectTeamUsers = createSelector(
    EditedTeamSelectors.selectTeam,
    UserListSelectors.selectAll,
    (team, users) => {
      if (team) {
        if (team.archived) {
          return team.removedUsers?.map(userId => users.find(user => user._id === userId));
        }
        return users.filter(user => user.teamIds.includes(team._id)).sort((a, b) => a.name.localeCompare(b.name));
      }
    }
  );

  export const selectCanEdit = createSelector(selectCurrentUser, EditedTeamSelectors.selectTeam, (user, team) => {
    if (!user || !team) {
      return false;
    }
    const canIgnoreCheck = team.private ? user.isOwner : user.isAdminOrOwner;
    const isManagerOrAboveOnTeam = user.isManagerOrAbove && user?.teams.filter(t => t.teamId === team._id)?.length;

    return (canIgnoreCheck || isManagerOrAboveOnTeam) && !team.archived;
  });
}

export const TeamUserSelectors = {
  selectTeamUsers: (teamId: string) =>
    createSelector(selectActiveUsers, users =>
      teamId ? users.filter(user => user.teams.some(team => team.teamId === teamId)) : []
    ),

  selectTeamUsersDictionary: (teamId: string) =>
    createSelector(selectActiveUsers, users =>
      users
        .filter(user => user.teams.some(team => team.teamId === teamId))
        .reduce((acc, user) => {
          acc[user._id] = user;
          return acc;
        })
    ),

  selectTeamUsersNotObservers: (teamId: string) =>
    createSelector(selectActiveUsers, users =>
      teamId
        ? users.filter(user => user.teams.some(team => team.teamId === teamId) && user.roleCode !== RoleCode.observer)
        : []
    ),
  get selectIsUserInTeam() {
    return (teamId: string, userId: string) =>
      createSelector(this.selectTeamUsers(teamId), (teamUsers: User[]) => teamUsers.some(user => user._id === userId));
  },
};

export const selectUsersForFilterBarTeam = createSelector(
  selectActiveUsers,
  TeamSelectors.selectFilterBarTeamId,
  (users, teamId) => users.filter(user => user.teams && user.teams.some(team => team.teamId === teamId))
);

export const selectTeamAndUsers = (teamId: string) => {
  return createSelector(TeamSelectors.selectById(teamId), TeamUserSelectors.selectTeamUsers(teamId), (team, users) => {
    return { team, users };
  });
};

/*
   Returns a list of all teams, with the Current Users teams sorted to the top of the list
*/
export const selectAllTeamsOrderByLoggedInUser = createSelector(
  selectCurrentUser,
  CurrentUserSelectors.selectTeams,
  (user, teams) => {
    const myTeams = teams.filter(team => user.teams.some(ut => ut.teamId === team._id));
    const otherTeams = teams.filter(team => !myTeams.includes(team));

    return [...myTeams, ...otherTeams];
  }
);

/*
   Returns a list of all teams, with the Current Users teams sorted to the top of the list and
   excluding the team with the given teamId.  This is used for Cascading messages that would not
   be sent to the originating team.
*/
export const selectAllTeamsOrderByLoggedInUserExcludingTeam = (teamId: string) =>
  createSelector(selectAllTeamsOrderByLoggedInUser, teams => {
    // logic taken from filter-out-team pipe
    if (!teams || !teamId || teams.length < 2) return teams;
    return teams.filter((t: Team) => t._id !== teamId);
  });

/*
   Returns a list of all teams, the teams assigned to the given userId are sorted to the top of the list
*/
export const selectAllTeamsOrderedByUserId = (userId: string) =>
  createSelector(UserSelectors.selectUserById(userId), CurrentUserSelectors.selectTeams, (user, teams) => {
    const myTeams = teams.filter(team => user.teams.some(ut => ut.teamId === team._id));
    const otherTeams = teams.filter(team => !myTeams.includes(team));

    return [...myTeams, ...otherTeams];
  });

/*
  Returns an object compatible with the quick filter component
*/
export const selectTeamFilterOptions = createSelector(
  CurrentUserSelectors.selectTeams,
  TeamSelectors.selectFilterBarTeamId,
  (teams, filterBarTeamId): QuickFilterOption<Team>[] => {
    if (filterBarTeamId)
      return teams.map(team => ({
        displayText: team.name,
        id: team._id,
        item: team,
        selected: team._id === filterBarTeamId,
      }));
  }
);
export const selectDoesUserHaveAccessToOrgChart = createSelector(
  selectIsOrgChartPrivate,
  selectCurrentUser,
  selectCompanySltTeamId,
  (isOrgChartPrivate, user, sltId) =>
    !isOrgChartPrivate || user.isAdminOrOwner || user.teams.some(t => t.teamId === sltId)
);
// Could be argued as a user state selector but since it depends on selectCompanySltTeamId I need it here
// If this is moved to user state, it would cause a circular dependency.

export const selectUserIsSLT = createSelector(selectCurrentUser, selectCompanySltTeamId, (user, sltTeamId) =>
  user?.teams?.some(team => team.teamId === sltTeamId)
);
