import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { DataImportType } from '@ninety/data-import/models/data-import-types';
import { OneSchemaData } from '@ninety/data-import/models/one-schma-data';
import { UserRecord } from '@ninety/data-import/models/records';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';
import { Issue } from '@ninety/ui/legacy/shared/models/issues/issue';
import { Rock } from '@ninety/ui/legacy/shared/models/rocks/rock';
import { Todo } from '@ninety/ui/legacy/shared/models/todos/todo';

export const DataImportActions = createActionGroup({
  source: 'Data Import',
  events: {
    Init: emptyProps(),
    Destroy: emptyProps(),
    'After Set Iframe': emptyProps(),
    'Set Initial Import Type': props<{ option: DataImportType }>(),
    'Get One Schema Token': emptyProps(),
    'Get One Schema Token Success': props<{ userJwt: string }>(),
    'Launch One Schema': emptyProps(),
    'Reset One Schema': emptyProps(),

    'Select Import Type': props<{ option: DataImportType }>(),
    'Select Team': props<{ teamId: string }>(),
    'Download CSV Template': emptyProps(),

    /** Data handed back from One Scheme */
    'Handle Data From OneSchema': props<{ data: OneSchemaData<any> }>(),

    /** User Import */
    'Show Users Import Confirm Dialog': emptyProps(),
    'Confirmed Ready To Proceed': emptyProps(),
    'Build Import User Form Array': props<{ records: UserRecord[] }>(),
    'Show Users Preview Table': emptyProps(),
    'Delete User': props<{ index: number }>(),
    'Open Cancel Confirm Dialog': emptyProps(),
    Cancel: emptyProps(),
    'Open Import Overview Dialog': emptyProps(),
    'Create Users': props<{ users: InviteUserPayload[] }>(),

    /** Other Helpful Only Import Types */
    'Create Issues': props<{ issues: Issue[] }>(),
    'Create Rocks': props<{ rocks: Rock[] }>(),
    'Create Todos': props<{ todos: Todo[] }>(),

    Success: props<{ key?: 'rock' | 'todo' | 'issue'; message?: string }>(),
    Error: props<{ error: unknown }>(),
  },
});
