import { createReducer, on } from '@ngrx/store';

import { FilterServiceActions } from '@ninety/ui/legacy/core/index';
import { Team } from '@ninety/ui/legacy/shared/index';

import { WeeklyActions } from './weekly.actions';

export interface WeeklyState {
  filter: {
    searchText: string;
    selectedUserId: string;
    showCompletedConversations: boolean;
    initFilterWithCurrentUser: boolean;
  };
  teams: Team[];
  inProgressWeeklyConversationId?: string;
}

export const initialWeeklyState: WeeklyState = {
  filter: {
    searchText: null,
    selectedUserId: 'all',
    showCompletedConversations: false,
    initFilterWithCurrentUser: true,
  },
  teams: [],
  inProgressWeeklyConversationId: null,
};

export const weeklyReducer = createReducer<WeeklyState>(
  initialWeeklyState,
  on(
    FilterServiceActions.showCompletedConversationsToggled,
    (state): WeeklyState => ({
      ...state,
      filter: {
        ...state.filter,
        showCompletedConversations: !state.filter.showCompletedConversations,
      },
    })
  ),
  on(
    WeeklyActions.getTeamsWithUsersSuccess,
    (state, { teams }): WeeklyState => ({
      ...state,
      teams: teams.filter((team: Team) => team.users.length === 2),
    })
  ),
  on(
    WeeklyActions.setWeeklyConversationId,
    (state, { id }): WeeklyState => ({
      ...state,
      inProgressWeeklyConversationId: id,
    })
  ),
  on(
    WeeklyActions.clearWeeklyConversationId,
    (state): WeeklyState => ({
      ...state,
      inProgressWeeklyConversationId: null,
    })
  )
);
