import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NinetyBreakpointNames } from '@ninety/ui/legacy/state/app-global/viewport/viewport.model';
import { selectLayoutFactory } from '@ninety/ui/legacy/state/app-global/viewport/viewport.selectors';

import { GRID_LAYOUT_STATE_KEY, GridLayoutStateModel } from './grid-layout-state.model';

export namespace GridLayoutSelectors {
  export const selectGridLayoutState = createFeatureSelector(GRID_LAYOUT_STATE_KEY);

  /**
   * Selects the currently displayed grid, possibly falsey.
   */
  export const selectCurrentGrid = createSelector(
    selectGridLayoutState,
    (state: GridLayoutStateModel) => state?.currentGrid
  );

  /**
   * Selects a map of id -> widget of the current grid, possibly null.
   */
  export const selectCurrentGridModelMap = createSelector(selectCurrentGrid, grid => grid?.viewModel);

  /**
   * Selects a specific model from the view model. Returns undefined if the key is unknown.
   */
  export const selectGridWidgetById = (id: string) => createSelector(selectCurrentGridModelMap, vm => vm?.[id]);

  /**
   * Selects if a specific widget is **currently** visible in the grid. This is separate from the persisted visibility
   * of a widget - that is handled by feature modules.
   */
  export const selectIsWidgetVisible = (id: string) =>
    createSelector(selectGridWidgetById(id), widget => !!widget?.visible);

  /**
   * Selects a list of current widgets, possibly empty.
   */
  export const selectCurrentGridModels = createSelector(selectCurrentGridModelMap, viewModel =>
    viewModel ? Object.values(viewModel) : []
  );

  export const selectIsGridActive = createSelector(selectCurrentGrid, currentGrid => !!currentGrid?.isInitialized);

  /**
   * Selects if the grid is initialized in the view and is ready for widgets.
   *
   * TODO inline if everything is working right
   */
  export const selectIsGridInitialized = selectIsGridActive;

  export const selectIsFirstPaintComplete = createSelector(
    selectCurrentGrid,
    currentGrid => !!currentGrid?.firstPaintComplete
  );

  /**
   * Selects if the grid widgets can be dragged/resized. When true, its expected that a beforeunload handler is
   * registered to prevent accidental unloads with unsaved data. Other feature modules add additional expectations.
   */
  export const selectIsLayoutModeEnabled = createSelector(
    selectCurrentGrid,
    currentGrid => !!currentGrid?.editLayoutEnabled
  );

  /**
   * Inverse of selectIsLayoutModeEnabled
   */
  export const selectIsLayoutModeDisabled = createSelector(selectIsLayoutModeEnabled, enabled => !enabled);

  /**
   * Selects which template to render as a singleton. May return null.
   */
  export const selectSingletonTemplate = createSelector(selectCurrentGrid, grid => grid?.singletonTemplate);

  export const selectIsSingletonActive = createSelector(selectSingletonTemplate, template => !!template);

  export const selectIsOneColumnMode = createSelector(selectCurrentGrid, grid => grid?.inOneColumnMode ?? false);

  export const selectShowMobileViewAlert = createSelector(
    selectIsOneColumnMode,
    selectIsSingletonActive,
    selectIsGridInitialized,
    selectIsLayoutModeEnabled,
    (isGridInOneColumnMode, isSingletonActive, isGridInitialized, isLayoutModeEnabled) =>
      isGridInOneColumnMode && !isSingletonActive && isGridInitialized && !isLayoutModeEnabled
  );

  export const selectShouldShowGridAlternate = createSelector(
    selectIsSingletonActive,
    selectIsOneColumnMode,
    selectCurrentGrid,
    (isSingletonActive, isOneColumnMode, grid) => {
      if (isSingletonActive) return true;
      return grid?.opts.useNinetyOneColumnMode ? isOneColumnMode : false;
    }
  );

  export const selectWidgetsShouldFillParent = createSelector(
    selectShouldShowGridAlternate,
    showAlternate => !showAlternate
  );

  /** This object maps the breakpoints of the app to whether the singleton view should be allowed */
  const config: Record<NinetyBreakpointNames, boolean> = {
    Small: false,
    Medium: false,
    Large: true,
    XLarge: true,
    HandsetPortrait: false,
    TabletPortrait: false,
    HandsetLandscape: false,
    TabletLandscape: true,
  };

  export const selectAllowSingletonHandling = selectLayoutFactory<boolean>(config);
}
