import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, of, switchMap, tap } from 'rxjs';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';
import { DataImportSelectors } from '@ninety/data-import/_state/data-import.selectors';
import { UserImportConfirmDialogComponent } from '@ninety/data-import/dialogs/user-import-confirm-dialog/user-import-confirm-dialog.component';
import { OneSchemaRoleName, OneSchemaRoleNameAsCode } from '@ninety/data-import/models/one-schema-role-name';
import { OneSchemaTemplateKey } from '@ninety/data-import/models/one-schema-template-key';
import { DataImportService } from '@ninety/data-import/services/data-import.service';
import { UserImportFormService } from '@ninety/data-import/services/user-import-form.service';
import { ConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/models';
import { WarningConfirmDialogComponent } from '@ninety/ui/legacy/shared/components/_mdc-migration/confirm-dialog/warning-confirm-dialog.component';
import { RoleCode } from '@ninety/ui/legacy/shared/models/_shared/role-code';
import { DataImportItemType } from '@ninety/ui/legacy/shared/models/enums/data-import-item-type';
import { selectRoleSelectData } from '@ninety/ui/legacy/state/app-entities/roles/roles-state.selectors';
import { TeamSelectors } from '@ninety/ui/legacy/state/app-entities/team-list/team-list-state.selectors';
import { BillingStateActions } from '@ninety/ui/legacy/state/app-global/billing/billing-state.actions';
import {
  selectCompanyBillingCounts,
  selectIsFree,
  selectIsTrialing,
  selectNumberOfAvailableSeats,
} from '@ninety/ui/legacy/state/app-global/billing/billing-state.selectors';
import { selectCompanyId } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';

@Injectable()
export class UserImportEffects {
  /** if not helpful and importing users, show users import confirm dialog */
  whenImportingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.setInitialImportType),
      concatLatestFrom(() => [this.store.select(DataImportSelectors.selectTemplateKey)]),
      filter(([_, templateKey]) => templateKey === OneSchemaTemplateKey.users && window.innerWidth >= 960),
      map(() => DataImportActions.showUsersImportConfirmDialog())
    )
  );

  handleUsersFromOneSchema$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.handleDataFromOneSchema),
      filter(({ data }) => data.template_key === OneSchemaTemplateKey.users),
      map(({ data }) => DataImportActions.buildImportUserFormArray({ records: data.records }))
    )
  );

  showUsersImportConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.showUsersImportConfirmDialog),
      exhaustMap(() =>
        this.dialog
          .open<UserImportConfirmDialogComponent>(UserImportConfirmDialogComponent, {
            autoFocus: false,
            disableClose: true,
            width: '460px',
          })
          .afterClosed()
      ),
      filter(proceed => !!proceed),
      map(() => DataImportActions.confirmedReadyToProceed())
    )
  );

  getBillingCountsWhenImportingUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.selectImportType, DataImportActions.setInitialImportType),
      /** if we implement deep linking to a particular template, we need to make sure this fires */
      filter(({ option }) => option.importType === DataImportItemType.user),
      map(() => BillingStateActions.getCompanyBillingCounts())
    )
  );

  buildImportUserFormArray$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.buildImportUserFormArray),
      concatLatestFrom(() => [
        this.store.select(TeamSelectors.selectAll),
        this.store.select(selectRoleSelectData),
        this.store.select(selectCompanyId),
      ]),
      map(([{ records }, allTeams, roles, companyId]) => {
        const users = records.map(user => {
          const { team1, team2, team3, team4, team5 } = user;
          const selectedTeams = [];
          const nonMatchingTeamNames = [];
          [team1, team2, team3, team4, team5].forEach(teamName => {
            if (!teamName) return;
            const team = allTeams.find(t => t.name.toLowerCase() === teamName.toLowerCase());
            if (team) selectedTeams.push(team);
            else nonMatchingTeamNames.push(teamName);
          });
          const roleCode = OneSchemaRoleNameAsCode[user.role] || RoleCode.managee;
          const isImplementer = user.role === OneSchemaRoleName.coach || user.role === OneSchemaRoleName.implementer;
          return {
            ...user,
            role: roles.find(
              r =>
                r.roleCode === roleCode &&
                // must account for coaches roleCode: owner and isImplementer: true
                r.isImplementer === isImplementer
            ),
            selectedTeams,

            //hidden controls
            teams: selectedTeams.map(({ _id: teamId }) => ({ teamId })),
            roleCode,
            isImplementer,
            companyId,
            hasBeenInvited: false,
            active: false,
            nonMatchingTeamNames, // to display in the UI in case they misspelled the team doesn't exist
          };
        });
        this.userImportFormService.buildImportUserFormArray(users);
        return DataImportActions.showUsersPreviewTable();
      })
    )
  );

  openCancelConfirmDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.openCancelConfirmDialog),
      switchMap(() =>
        this.dialog
          .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
            data: {
              title: 'Cancel User Import',
              message: 'Canceling this import will terminate the process and take you to the starting point.',
              confirmButtonText: 'Cancel import',
              cancelButtonText: `Don't cancel`,
            },
          })
          .afterClosed()
          .pipe(
            filter(confirmed => !!confirmed),
            map(() => DataImportActions.cancel())
          )
      )
    )
  );

  deleteUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DataImportActions.deleteUser),
        switchMap(({ index }) =>
          this.dialog
            .open<WarningConfirmDialogComponent, ConfirmDialogData>(WarningConfirmDialogComponent, {
              data: {
                title: 'Delete user',
                message: 'Are you sure you want to delete this user?',
                confirmButtonText: 'Delete user',
                confirmButtonIcon: 'delete',
              },
            })
            .afterClosed()
            .pipe(
              filter(confirmed => !!confirmed),
              tap(() => this.userImportFormService.deleteUser(index))
            )
        )
      ),
    { dispatch: false }
  );

  createUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.createUsers),
      switchMap(({ users }) =>
        this.dataImportService.createUsers(users).pipe(
          /** todo implement new snackbar */
          map(() => DataImportActions.success({ message: `Successfully imported users` })),
          catchError((error: unknown) => of(DataImportActions.error({ error })))
        )
      )
    )
  );

  openImportOverviewDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataImportActions.openImportOverviewDialog),
      map(() => this.userImportFormService.getUserBillingCounts()),
      concatLatestFrom(() => [
        this.store.select(selectCompanyBillingCounts),
        this.store.select(selectIsTrialing),
        this.store.select(selectIsFree),
        this.store.select(selectNumberOfAvailableSeats),
      ]),
      switchMap(([{ totalUsers, paidUsers, freeUsers }, billingCounts, isTrialing, isFree, numAvailableSeats]) => {
        let message = `You are about to import <strong>${totalUsers} total users`;
        message += isFree ? `.<strong>` : `: ${paidUsers} paid roles and ${freeUsers} free roles.</strong>`;
        let confirmButtonText = 'OK';
        if (!isTrialing) {
          message += ` You have <strong>${numAvailableSeats} available licenses.</strong>`;
          if (billingCounts.assignableSeats < paidUsers) {
            message += ` The payment method on file will be charged for the <strong>additional ${
              paidUsers - billingCounts.assignableSeats
            } licenses.</strong>`;
            confirmButtonText = 'Charge payment method';
          }
        }

        return this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
            data: {
              title: 'Subscription Overview',
              message,
              confirmButtonText,
            },
          })
          .afterClosed()
          .pipe(
            filter(result => result),
            map(() => this.userImportFormService.getUsersFromForm()),
            map(users => DataImportActions.createUsers({ users }))
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private dataImportService: DataImportService,
    private dialog: MatDialog,
    private userImportFormService: UserImportFormService
  ) {}
}
