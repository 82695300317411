<ng-container
  *ngrxLet="{
    settings: settings$,
    language: language$
  } as vm">
  <div>
    <terra-switch
      attr.data-cy="scorecard-settings_toggle-button-{{ vm.language.scorecard.route }}"
      matTooltip="Toggle Trailing Four/Thirteen Weeks {{ vm.language.scorecard.route }}"
      [(ngModel)]="vm.settings.scorecardTrailing"
      (ngModelChange)="updateSettings('scorecardTrailing', $event)">
      Trailing {{ vm.language.scorecard.route }}
    </terra-switch>
  </div>

  <div>
    <terra-switch
      attr.data-cy="scorecard-settings_toggle-button-{{ vm.language.measurable.item }}"
      [(ngModel)]="vm.settings.measurableAverageScore"
      (ngModelChange)="updateSettings('measurableAverageScore', $event)">
      {{ vm.language.measurable.item }} Average Column
    </terra-switch>
  </div>

  <div>
    <terra-switch
      attr.data-cy="scorecard-settings_toggle-button-{{ vm.language.measurable.item }}"
      [(ngModel)]="vm.settings.measurableTotalScore"
      (ngModelChange)="updateSettings('measurableTotalScore', $event)">
      {{ vm.language.measurable.item }} Total Column
    </terra-switch>
  </div>

  <div>
    <terra-switch
      attr.data-cy="scorecard-settings_toggle-button-{{ vm.language.measurable.item }}"
      [(ngModel)]="vm.settings.measurableStatusIndicator"
      (ngModelChange)="updateSettings('measurableStatusIndicator', $event)">
      {{ vm.language.measurable.item }} Status Color Indicator
    </terra-switch>
  </div>

  <div *ngIf="selectedTeam">
    <terra-switch
      attr.data-cy="scorecard-settings_toggle-button-{{
        ScorecardPeriodToHideCurrentPeriod[stateService.periodInterval]
      }}"
      [(ngModel)]="
        teamSettings.hideCurrentPeriodOnScorecard[ScorecardPeriodToHideCurrentPeriod[stateService.periodInterval]]
      "
      (ngModelChange)="onToggleCurrentPeriod()">
      Hide current {{ ScorecardPeriodToHideCurrentPeriod[stateService.periodInterval] }} for
      <strong>{{ selectedTeam.name }}</strong
      >?
    </terra-switch>
  </div>

  <div *ngIf="[PeriodInterval.weekly, PeriodInterval.trailingFourWeeks].includes(stateService.periodInterval)">
    <terra-form-field>
      <terra-form-label>{{ vm.language.measurable.item }} weekly start day</terra-form-label>
      <terra-select
        data-cy="scorecard-settings_weekly-start-date"
        [(ngModel)]="vm.settings.measurableWeeklyStartDay"
        [compareWith]="startDayIsSame"
        (ngModelChange)="updateSettings('measurableWeeklyStartDay', $event)">
        <terra-option
          attr.data-cy="scorecard-settings_start-day-option-{{ startDay.day }}"
          *ngFor="let startDay of measurableWeeklyStartDays"
          [value]="startDay">
          {{ startDay.day }}
        </terra-option>
      </terra-select>
    </terra-form-field>
  </div>
</ng-container>
