import { createReducer, on } from '@ngrx/store';

import { SharedReducerFunctions } from '@ninety/todos/_state/_shared/todo-state.shared.reducers';
import { TodoDetailActions } from '@ninety/todos/_state/detail/todo-detail.actions';
import { TODO_DETAIL_INITIAL_STATE, TodoDetailState } from '@ninety/todos/_state/detail/todo-detail.model';
import { PersonalTodoActions } from '@ninety/todos/_state/personal/personal-todo.actions';
import { TeamTodoActions, TeamTodoPubnubActions } from '@ninety/todos/_state/team/team-todo.actions';

export const todoDetailReducers = createReducer<TodoDetailState>(
  TODO_DETAIL_INITIAL_STATE,
  on(
    TodoDetailActions.openInDetail,
    TodoDetailActions.setSelectedTodo,
    (state, { todo }): TodoDetailState => ({ ...state, selectedTodo: todo })
  ),
  on(TodoDetailActions.clearSelectedTodo, (state): TodoDetailState => ({ ...state, selectedTodo: null })),
  /**
   * Update the selected _todo whenever the object in the list is updated by real-time events or a successful PATCH.
   *
   * Note, Its expected that the current selectedTodo is the same type of the source action - if TeamTodoActions.update is
   * dispatched, the selectedTodo is a team _todo.
   *
   * Note, these same actions are handled by the Team and Personal state "modules" in their reducers and effects to
   * update the _todo in the list.
   *
   * Note, {@link SharedReducerFunctions#mergeTodoUpdateIntoTodo} returns the original _todo unchanged if the update
   * does not have the same _id
   */
  on(
    TeamTodoActions.updateLocal,
    PersonalTodoActions.updateLocal,
    TeamTodoPubnubActions.attachmentEventSuccess,
    (state, { todo }) => {
      if (!state.selectedTodo) return state;
      return {
        ...state,
        selectedTodo: SharedReducerFunctions.mergeTodoUpdateIntoTodo(state.selectedTodo, todo),
      };
    }
  ),
  on(TeamTodoActions.createTaskSuccess, PersonalTodoActions.createTaskSuccess, (state, { googleTaskId }) => ({
    selectedTodo: Object.assign({}, state.selectedTodo, { googleTaskId }),
  }))
);
