<ng-container *ngrxLet="vm$ as vm">
  <h1 mat-dialog-title>Schedule a {{ vm.language.meeting.item }}</h1>
  <h2 mat-dialog-subtitle>Select an agenda, date and presenter for your {{ vm.language.meeting.item }}</h2>

  <mat-dialog-content>
    <form [formGroup]="form" class="parent" data-cy="schedule-meeting-modal">
      <div class="agenda">
        <mat-label>Agenda</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="agenda" placeholder="Select Agenda" data-cy="schedule-meeting-dialog_agenda">
            <mat-option
              *ngFor="let agenda of vm.agendas"
              [value]="agenda"
              class="agenda-item"
              attr.data-cy="agenda-option-{{ agenda.id }}"
              >{{ agenda.displayText }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="date">
        <mat-label>Date</mat-label>
        <mat-form-field appearance="outline">
          <input
            matInput
            [min]="minDate"
            [matDatepicker]="dueDatePicker"
            aria-label="date picker"
            formControlName="date"
            data-cy="schedule-meeting-dialog_date"
            (dateChange)="dateChanged($event.value)" />
          <mat-datepicker-toggle matSuffix [for]="dueDatePicker">
            <terra-icon matDatepickerToggleIcon icon="calendar" />
          </mat-datepicker-toggle>
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="time-wrp">
        <mat-label>Time</mat-label>
        <div class="time-fields-wrp">
          <mat-form-field appearance="outline" class="time-hh">
            <input
              type="number"
              min="1"
              max="12"
              matInput
              cdkTextareaAutosize
              aria-label="hours"
              formControlName="hours"
              placeholder="hh"
              data-cy="schedule-meeting-dialog_hour" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="time-mm">
            <input
              type="number"
              min="0"
              max="59"
              matInput
              cdkTextareaAutosize
              aria-label="minutes"
              formControlName="minutes"
              placeholder="mm"
              data-cy="schedule-meeting-dialog_minutes" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="period" data-cy="schedule-meeting-dialog_period">
            <mat-select [required]="true" formControlName="period">
              <mat-option [value]="TimePeriod.AM">{{ TimePeriod.AM }}</mat-option>
              <mat-option [value]="TimePeriod.PM">{{ TimePeriod.PM }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="cadence">
        <mat-label>Repeating {{ vm.language.meeting.item }} Cadence</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="cadence" data-cy="schedule-meeting-dialog_cadence">
            <mat-option *ngFor="let option of cadence$ | async" class="cadence-option" value="{{ option.value }}">
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="facilitator">
        <mat-label>Facilitator</mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            formControlName="presenterId"
            placeholder="Select Facilitator"
            data-cy="schedule-meeting-dialog_presenter">
            <mat-option *ngFor="let user of vm.users" [value]="user.value">{{ user.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="scribe">
        <mat-label>Scribe</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="scribeId" placeholder="Select Scribe" data-cy="schedule-meeting-dialog_scribe">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let user of vm.users" [value]="user.value">{{ user.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <ninety-button secondary stroked mat-dialog-close class="right-space-10">Cancel</ninety-button>
    <ninety-button filled primary (click)="save()" [disabled]="!form.valid" data-cy="schedule-meeting-dialog_save">
      <terra-icon icon="save" />
      Save
    </ninety-button>
  </mat-dialog-actions>
</ng-container>
