import { RoleSelectOption } from '@ninety/ui/legacy/shared/models/_shared/role-select-option';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import { InviteUserPayload } from '@ninety/ui/legacy/shared/models/directory/invite-user-payload';

export class ImportUserRecord extends InviteUserPayload {
  role: RoleSelectOption;
  selectedTeams: Team[];
  nonMatchingTeamNames: string[];

  static fromFormData({
    role,
    selectedTeams,
    nonMatchingTeamNames,
    teams,
    ...formData
  }: ImportUserRecord): InviteUserPayload {
    return {
      ...formData,
      /** to keep the selected teams, etc. in case the user changes role to implementer and then back to something else,
       *  only remove teams before sending payload */
      teams: role.isImplementer ? [] : teams,
    };
  }
}
