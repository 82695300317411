import { createSelector } from '@ngrx/store';

import { selectGlobalAppState } from '..';
import { StripeSubscriptionStatuses, SubscriptionTypes } from '../../../_shared';
import { CompleteBillingOverviewDetailsModel } from '../../../_shared/models/billingv2/complete-billing-overview-details.model';
import { selectCompany } from '../company/company-state.selectors';
import { selectBillingOverviewDetails, selectSubscription } from '../company/subscription/subscription-state.selectors';

import { BillingStateKey } from './billing-state.model';

export const selectIsFree = createSelector(selectSubscription, sub => sub?.type === SubscriptionTypes.Free);

export const selectIsTrialing = createSelector(
  selectSubscription,
  sub => sub?.providerStatus === StripeSubscriptionStatuses.Trialing
);

export const selectBillingState = createSelector(selectGlobalAppState, globalState =>
  globalState ? globalState[BillingStateKey] : null
);
export const selectSubscriptionConfigurations = createSelector(
  selectBillingState,
  state => state.subscriptionConfigurations
);

export const selectCurrentSubscriptionConfiguration = createSelector(
  selectSubscriptionConfigurations,
  selectSubscription,
  (subscriptionConfigurations, subscription) => {
    if (!subscriptionConfigurations?.length || !subscription?.planPriceId) {
      return null;
    }
    return subscriptionConfigurations.find(config => config.priceId === subscription.planPriceId);
  }
);

export const selectCompanyBillingCounts = createSelector(selectBillingState, state => state?.companyBillingCounts);
export const selectLicensesMaxed = createSelector(
  selectBillingState,
  state => state?.companyBillingCounts?.assignableSeats <= 0
);

export const selectUpcomingInvoice = createSelector(selectBillingState, state => state.upcomingInvoice);

export const selectCompleteBillingOverview = createSelector(
  selectBillingOverviewDetails,
  selectCurrentSubscriptionConfiguration,
  selectCompanyBillingCounts,
  selectUpcomingInvoice,
  (billingOverviewDetails, currentSubscriptionConfiguration, companyBillingCounts, upcomingInvoice) =>
    ({
      billingOverviewDetails,
      currentSubscriptionConfiguration,
      companyBillingCounts,
      upcomingInvoice,
    } as CompleteBillingOverviewDetailsModel)
);

export const selectNumberOfAvailableSeats = createSelector(
  selectIsTrialing,
  selectCompanyBillingCounts,
  selectCompany,
  (isTrialing, counts, { trialingUserLimit }) =>
    isTrialing ? trialingUserLimit - counts.activeBillingUsers : counts.assignableSeats
);
