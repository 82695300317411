<ng-container
  *ngrxLet="{
    route: currentRoute$,
    showAgendasV2: showAgendasV2$,
    language: language$,
    isManageeOrAbove: isManageeOrAbove$,
    agendaTypeOrIdOrNew: agendaTypeOrIdOrNew$,
    validForm: agendaV2Service.validForm$,
    newChanges: agendaV2Service.newChanges$,
    meetingScheduling: meetingScheduling$,
  } as vm">
  <header id="agenda-page-header" *ngIf="vm.agendaTypeOrIdOrNew">
    <div class="breadcrumbs">
      <a [routerLink]="meetingRoutes.agendasV2" data-cy="top-toolbar_back-to-team-agendas">
        <terra-icon icon="caret-left" size="12" />
        <span> Back to {{ teamName$ | async }} Agendas </span>
      </a>
    </div>
    <div class="titleOptions">
      <ng-container *ngIf="vm.agendaTypeOrIdOrNew === meetingRoutes.newCustomAgenda; else EditAgenda">
        <h1>Create <span>Custom Agenda</span></h1>
        <ninety-button
          filled
          primary
          [disabled]="vm.validForm === false"
          data-cy="top-toolbar_save-new-agenda-btn"
          *ngIf="vm.isManageeOrAbove"
          (click)="triggerSaveNewCustomAgenda()">
          Create Agenda
        </ninety-button>
      </ng-container>

      <ng-template #EditAgenda>
        <h1>
          Editing <span>{{ selectedAgendaName$ | async }}</span>
        </h1>
        <ninety-button-group class="save-reset-btn-grp">
          <ninety-button
            [disabled]="!vm.validForm || !vm.newChanges"
            filled
            primary
            data-cy="top-toolbar_save-agenda-changes-btn"
            *ngIf="vm.isManageeOrAbove"
            (click)="triggerUpdateAgenda()">
            Save Changes
          </ninety-button>
          <ninety-button stroked primary icon data-cy="top-toolbar_more-options-btn" [matMenuTriggerFor]="resetMenu">
            <terra-icon icon="more" size="20" />
          </ninety-button>
        </ninety-button-group>

        <mat-menu #resetMenu="matMenu">
          <ng-container
            *ngrxLet="{
              selectedAgendaIsCustom: selectedAgendaIsCustom$,
            } as vmMenu">
            <ninety-button
              mat-menu-item
              data-cy="top-toolbar_more-options_discard-changes"
              [disabled]="!vm.newChanges"
              (click)="triggerDiscardChanges()">
              <terra-icon icon="undo" />
              Undo All Changes
            </ninety-button>
            <ninety-button
              data-cy="top-toolbar_more-options_delete"
              mat-menu-item
              *ngIf="vmMenu?.selectedAgendaIsCustom"
              (click)="triggerDeleteCustomAgenda()">
              <terra-icon icon="delete" />
              Delete Agenda
            </ninety-button>
            <ninety-button
              data-cy="top-toolbar_more-options_reset"
              *ngIf="!vmMenu?.selectedAgendaIsCustom"
              mat-menu-item
              (click)="triggerResetAgenda()">
              <terra-icon icon="reset" />
              Reset to Default
            </ninety-button>
          </ng-container>
        </mat-menu>
      </ng-template>
    </div>
  </header>
  <div class="sticky-container">
    <ninety-page-header
      id="meetings-page-header"
      *ngIf="!vm.agendaTypeOrIdOrNew"
      header="Meetings"
      description="Improve alignment and transparency across your organization with a regular
      {{ vm.language.meeting.item }} pulse."
      [shouldHideDescriptionOnMobile]="shouldHideDescriptionOnMobile"
      data-cy="top-toolbar_create-button" />

    <ng-container *ngIf="!vm.agendaTypeOrIdOrNew">
      <ninety-tabs-bar>
        <nav mat-tab-nav-bar class="ninety-tabs" [tabPanel]="tabPanel" disablePagination="true">
          <a
            mat-tab-link
            [id]="ActiveMeetingsTab.Upcoming"
            [routerLink]="meetingRoutes.upcoming"
            data-cy="meetings-upcoming-tab"
            [active]="vm.route.routeConfig.path === '' || vm.route.routeConfig.path === meetingRoutes.upcoming"
            *ngIf="vm.meetingScheduling">
            Upcoming
          </a>
          <a
            mat-tab-link
            [id]="ActiveMeetingsTab.Past"
            [routerLink]="meetingRoutes.past"
            data-cy="meetings-past-tab"
            [active]="vm.route.routeConfig.path === meetingRoutes.past"
            *ngIf="vm.meetingScheduling">
            Past {{ vm.language.meeting.items }}
          </a>
          <a
            [id]="ActiveMeetingsTab.Agendas"
            *ngIf="vm.showAgendasV2 && (isManagerOrAbove$ | async) === true"
            mat-tab-link
            [routerLink]="meetingRoutes.agendasV2"
            data-cy="meetings-agenda-v2-tab"
            [active]="vm.route.routeConfig.path === meetingRoutes.agendasV2">
            Agendas
          </a>
        </nav>
      </ninety-tabs-bar>
    </ng-container>
    <ninety-meetings-action-bar [activeTab]="tabPanel._activeTabId"></ninety-meetings-action-bar>
  </div>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</ng-container>
