import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { Router, RouterLinkActive, RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { CompanyMenuComponent } from '@ninety/layouts/components/company-menu/company-menu.component';
import { LayoutModule } from '@ninety/layouts/layout.module';
import { TerraDividerModule, TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { NinetyChipComponent } from '@ninety/ui/legacy/components/index';
import { AuthService } from '@ninety/ui/legacy/core/services/auth.service';
import { NinetyTooltipDirective } from '@ninety/ui/legacy/directives/ninety-tooltip.directive';
import { FeatureFlagFacade } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.facade';
import { FeatureFlagKeys } from '@ninety/ui/legacy/state/app-entities/feature-flag/feature-flag-state.model';
import { selectCurrentUserIsManageeOrAbove } from '@ninety/ui/legacy/state/app-entities/users/users-state.selectors';
import { selectNavLinksDisabled } from '@ninety/ui/legacy/state/app-global/auth/auth.selectors';
import { selectCompanySettings } from '@ninety/ui/legacy/state/app-global/company/company-state.selectors';
import { selectCurrentPerson } from '@ninety/ui/legacy/state/app-global/current-person/current-person.selectors';
import { selectLanguage } from '@ninety/ui/legacy/state/app-global/language/language.selectors';
import { selectDoesUserHaveAccessToOrgChart } from '@ninety/ui/legacy/state/composite-selectors/user-team.selectors';

@Component({
  selector: 'ninety-mobile-nav-menu',
  standalone: true,
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    CompanyMenuComponent,
    RouterLinkActive,
    TerraIconModule,
    MatMenuModule,
    LetDirective,
    ButtonComponent,
    NinetyTooltipDirective,
    TerraDividerModule,
    NinetyChipComponent,
  ],
  templateUrl: './mobile-nav-menu.component.html',
  styles: [
    `
      .mobile-nav-menu {
        max-height: 85vh;
      }

      .beta-menu-item {
        display: block;
        position: relative;

        .beta-chip {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
        }
      }
    `,
  ],
})
export class MobileNavMenuComponent {
  protected readonly disableNavLinks$ = this.store.select(selectNavLinksDisabled);
  protected readonly language$ = this.store.select(selectLanguage);

  protected readonly companySettings$ = this.store.select(selectCompanySettings);
  protected readonly currentPerson$ = this.store.select(selectCurrentPerson);
  protected readonly isManageeOrAbove$ = this.store.select(selectCurrentUserIsManageeOrAbove);

  // Feature flagged routes
  protected readonly enableSurveys$ = this.featureFlags.getFlag(FeatureFlagKeys.enableAssessments);
  protected readonly canAccessInsights$ = this.featureFlags.getFlag(FeatureFlagKeys.insightsTopLevel);
  protected readonly canAccessOrgChart$ = this.store.select(selectDoesUserHaveAccessToOrgChart);
  protected readonly scorecardBeta$ = this.featureFlags.getFlag(FeatureFlagKeys.scorecardBeta);
  protected readonly enableLearningModule$ = this.featureFlags.getFlag(FeatureFlagKeys.enableLearningModule);
  protected readonly directoryV2$ = this.featureFlags.getFlag(FeatureFlagKeys.enableUserManagementEnhancements);
  protected readonly canAccessMeetingsV2$ = this.featureFlags.getFlag(FeatureFlagKeys.meetingsV2);
  protected readonly rocksV3$ = this.featureFlags.getFlag(FeatureFlagKeys.webRocksV3);

  @ViewChild(MatMenuTrigger) mobileNavMenuTrigger: MatMenuTrigger;

  constructor(
    private store: Store,
    private featureFlags: FeatureFlagFacade,
    private router: Router,
    private authService: AuthService
  ) {}

  routeTo(route: string) {
    this.router.navigateByUrl(route);
  }

  logout() {
    this.authService.logout();
  }

  handleSettingLinkClicked() {
    this.mobileNavMenuTrigger?.closeMenu();
  }
}
