// logging.service.ts
import { Injectable } from '@angular/core';
import { Span, SpanStatusCode } from '@opentelemetry/api';

@Injectable()
export class LoggingService {
  logEvent(span: Span, message: string, data?: any): void {
    if (span && message) {
      span.addEvent(message, { data });
    }
  }

  logError(span: Span | null, errorMessage: string, errorData?: any): void {
    if (span) {
      span.addEvent(errorMessage, errorData);
      span.setStatus({ code: SpanStatusCode.ERROR, message: errorMessage });
    } else {
      // Log the error to the console if no span is available
      console.error(`[Error]: ${errorMessage}`, errorData || '');
    }
  }
}
