import { CustomLanguage } from '@ninety/ui/legacy/shared/models/language/custom-language';

import { SeatDetailsDialogMode } from '../models';

export function cardTitleFromDialogMode(language: CustomLanguage, mode: SeatDetailsDialogMode): string {
  const record: Record<SeatDetailsDialogMode, string> = {
    [SeatDetailsDialogMode.Create]: `Create ${language.acChart.seat}`,
    [SeatDetailsDialogMode.Edit]: `Edit ${language.acChart.seat}`,
    [SeatDetailsDialogMode.View]: `View ${language.acChart.seat}`,
  };
  return record[mode];
}
