import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { EmptyListViewModule } from '@ninety/_layouts/components/empty-list-view/empty-list-view.module';
import { WidgetTemplateType } from '@ninety/my-ninety/_models/widget-template-type';
import { TerraIconModule } from '@ninety/terra';
import { ButtonComponent } from '@ninety/ui/legacy/components/buttons/button/button.component';
import { CardModule } from '@ninety/ui/legacy/components/cards/card.module';
import { QuickFilterComponent } from '@ninety/ui/legacy/components/inputs/quick-filter/quick-filter.component';
import { ActionBarComponent } from '@ninety/ui/legacy/components/toolbars/action-bar/action-bar.component';

import { MeetingsFacade } from '../meetings.facade';

import { ActiveMeetingComponent } from './active-meeting/active-meeting.component';
import { MeetingSchedulesComponent } from './meeting-schedules/meeting-schedules.component';

@Component({
  selector: 'ninety-meetings',
  standalone: true,
  templateUrl: './upcoming-meetings-page.component.html',
  styles: [
    `
      @use 'terra';

      ninety-quick-filter {
        margin-right: 10px;
      }

      .action-bar-right {
        @media (max-width: 599px) {
          display: none;
        }
      }

      .action-bar-left {
        display: flex;
        align-items: center;
      }

      .meetings-v2-card-wrp {
        margin-bottom: 24px;
      }

      .card-wrp {
        padding: 24px;
        padding-bottom: 0;

        ninety-card {
          max-width: 1300px;
          margin: auto;
        }

        .description {
          display: block;
          color: terra.color('text', 'light');
          line-height: 200%;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ActionBarComponent,
    QuickFilterComponent,
    TerraIconModule,
    ButtonComponent,
    LetDirective,
    CardModule,
    EmptyListViewModule,
    ActiveMeetingComponent,
    MeetingSchedulesComponent,
  ],
})
export class UpcomingMeetingsPageComponent {
  facade = inject(MeetingsFacade);

  readonly WidgetTemplateType = WidgetTemplateType;

  LOADING_SKELETON_ROWS = 2;
}
