// open-telemetry.module.ts
import { NgModule } from '@angular/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

import { environment } from '@ninety/ui/web/environments';

import { LoggingService } from './logging.service';
import { OT_RESOURCE, OT_TRACER_PROVIDER } from './open-telemetry.tokens';
import { SpanService } from './span.service';

@NgModule({
  providers: [
    {
      provide: OT_RESOURCE,
      useFactory: () =>
        new Resource({
          'service.name': environment.service,
          'service.version': environment.appVersion,
        }),
    },
    {
      provide: OT_TRACER_PROVIDER,
      useFactory: (resource: Resource) => {
        const provider = new WebTracerProvider({ resource });
        const exporter = new OTLPTraceExporter({ url: environment.openTelemetryCollectorUrl });
        console.log('OpenTelemetryModule.OT_TRACER_PROVIDER', provider, exporter);
        provider.addSpanProcessor(new BatchSpanProcessor(exporter));
        provider.register();
        return provider;
      },
      deps: [OT_RESOURCE],
    },
    LoggingService,
    SpanService,
  ],
})
export class OpenTelemetryModule {}
