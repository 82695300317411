import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { TerraIconModule } from '@ninety/terra';

import { NinetyTooltipDirective } from '../../_directives/ninety-tooltip.directive';
import { ButtonComponent } from '../buttons/button/button.component';

export type AlertType = 'negative' | 'neutral' | 'positive';

@Component({
  selector: `ninety-alert-message`,
  standalone: true,
  imports: [CommonModule, ButtonComponent, TerraIconModule, NinetyTooltipDirective],
  template: `
    <ng-template [ngIf]="visible">
      <ng-template [ngIf]="hasIcon">
        <terra-icon *ngIf="type === 'negative'" icon="warning-triangle" variant="fill" class="icon--negative" />
        <terra-icon *ngIf="type === 'neutral'" icon="warning-circle" variant="fill" class="icon--neutral" />
        <terra-icon *ngIf="type === 'positive'" icon="check-circle" variant="fill" class="icon--positive" />
      </ng-template>

      <div data-cy="alert_ninety-alert-content" class="ninety-alert-content">
        <ng-content></ng-content>
      </div>

      <span class="fill-remaining-space"></span>

      <ninety-button *ngIf="dismissible" flat icon (click)="visible = false">
        <terra-icon icon="x" ninetyTooltip="Close" />
      </ninety-button>
    </ng-template>
  `,
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @HostBinding('class.ninety-alert-negative') get negativeClass() {
    return this.type === 'negative';
  }
  @HostBinding('class.ninety-alert-neutral') get neutralClass() {
    return this.type === 'neutral';
  }
  @HostBinding('class.ninety-alert-positive') get positiveClass() {
    return this.type === 'positive';
  }
  @HostBinding('class.hide') get hideClass() {
    return this.visible === false;
  }

  /**
   * Default role for the Alert.
   */
  @HostBinding('attr.role')
  @Input()
  role = 'alert';
  /**
   * Controls the color and icon style of the Alert.
   */
  @Input() type?: AlertType = 'neutral';
  /**
   * Whether the Alert displays an icon to the left of it's content.
   * The icon that's displayed is based on the value of `type`.
   */
  @Input() hasIcon?: boolean = false;
  /**
   * Whether the Alert can be dismissed.
   */
  @Input() dismissible?: boolean = false;
  /**
   * Toggle the visibility of the Alert.
   */
  @Input()
  set visible(visible: boolean) {
    if (this._visible !== visible) {
      this._visible = visible;
      this.visibleChange.emit(visible);
    }
  }
  get visible() {
    return this._visible;
  }
  private _visible = true;
  /**
   * Event triggered when Alert visibility changed.
   */
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
}
