<div class="inline-editable-input" [class.editing]="editing">
  <input
    #input
    (keydown.enter)="toggleEdit()"
    (ngModelChange)="writeValue($event)"
    [class.no-value]="!_value"
    [ngModel]="_value"
    [placeholder]="placeholder"
    autocomplete="new-password"
    [disabled]="!editing"
    type="text" />
  <ninety-button
    (click)="toggleEdit()"
    *ngIf="editing && _value?.length"
    class="terra-input__suffix"
    compact
    flat
    icon
    primary>
    <terra-icon icon="check" size="20" />
  </ninety-button>
  <ninety-button (click)="toggleEdit()" *ngIf="!editing" class="edit-btn" compact flat icon ninetyTooltip="Edit">
    <terra-icon icon="edit" size="20" />
  </ninety-button>
</div>
