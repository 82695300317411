<div
  *ngrxLet="{
    enableReferrals: enableReferralProgram$,
    isTrialSubscription: isTrialSubscription$,
  } as context">
  <ng-container *ngIf="context.enableReferrals && !context.isTrialSubscription">
    <div *ngIf="impactToken && isWidgetReady">
      <impact-embed widget="p/24474/w/referrerWidget"></impact-embed>
    </div>
  </ng-container>
  <ng-template *ngIf="!context.enableReferrals || context.isTrialSubscription">
    <div class="text-center">
      <h2>Referral Program is not enabled</h2>
    </div>
  </ng-template>
</div>
