import { IdSelector, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import { PaginationConstants } from '@ninety/ui/legacy/components/pagination/pagination.component.model';
import { SortDirection } from '@ninety/ui/legacy/shared/models/enums/sort-direction';
import { MeetingType } from '@ninety/ui/legacy/shared/models/meetings/meeting-type.enum';
import { MeetingSortFieldsEnum } from '@ninety/ui/legacy/shared/models/meetings-v2/meetings-v2-sort-fields';
import { PastMeetingsPaginatedResponse } from '@ninety/ui/legacy/shared/models/meetings-v2/past-meetings-v2-response';
import { RealTimeActions, TeamListStateActions } from '@ninety/ui/legacy/state/index';

import {
  MeetingConcludeActions,
  MeetingDialogActions,
  MeetingRealTimeActions,
  MeetingSchedulingActions,
  MeetingStateActions,
  MeetingsAgendasV2Actions,
  MeetingsPageActions,
  MeetingsTabsActions,
  PastMeetingsPageActions,
} from './meetings.actions';
import { MeetingsStateModel } from './meetings.model';

const selectId: IdSelector<PastMeetingsPaginatedResponse> = ({ _id }) => _id;
export const meetingsStateAdapter = createEntityAdapter<PastMeetingsPaginatedResponse>({ selectId });

export const meetingsInitialState = meetingsStateAdapter.getInitialState({
  selectedId: null,

  loading: false,
  error: false,

  totalCount: 0,

  filters: { meetingType: null, agendaId: null, teamId: null },

  page: { index: 0, size: PaginationConstants.DEFAULT_PAGE_SIZES[0] },

  sort: { direction: SortDirection.DESC, field: MeetingSortFieldsEnum.date },

  currentMeeting: null,

  meetingStatus: null,

  attendees: [],

  meetingSchedulesLoading: false,
});

export const meetingsReducer = createReducer(
  meetingsInitialState,
  on(
    MeetingsTabsActions.reset,
    MeetingsPageActions.reset,
    (state): MeetingsStateModel => ({
      ...state,
      ...meetingsInitialState,
      filters: state.filters,
      currentMeeting: state.currentMeeting,
      attendees: state.attendees,
    })
  ),
  /** Custom meetings are saved with an agendaId */
  on(PastMeetingsPageActions.selectMeetingType, (state, { meetingAgenda }): MeetingsStateModel => {
    const meetingType = meetingAgenda.item ? MeetingType.custom : (meetingAgenda?.id as MeetingType);
    const agendaId = meetingAgenda.item ? meetingAgenda?.item._id : null;

    return {
      ...state,
      filters: { ...state.filters, meetingType, agendaId },
    };
  }),
  on(
    MeetingsPageActions.selectTeam,
    PastMeetingsPageActions.selectTeam,
    MeetingsAgendasV2Actions.selectTeam,
    (state, { team }): MeetingsStateModel => ({ ...state, filters: { ...state.filters, teamId: team._id } })
  ),
  on(
    TeamListStateActions.getSelectedTeam,
    (state, { id }): MeetingsStateModel => ({ ...state, filters: { ...state.filters, teamId: id } })
  ),
  on(
    MeetingsPageActions.selectMeeting,
    (state, { meeting }): MeetingsStateModel => ({
      ...state,
      selectedId: meeting._id,
    })
  ),
  on(
    MeetingsPageActions.loadPastMeetings,
    MeetingsPageActions.getMeeting,
    MeetingsPageActions.resumeMeeting,
    MeetingsPageActions.deleteActiveMeeting,
    MeetingsPageActions.deleteMeeting,
    (state): MeetingsStateModel => ({
      ...state,
      loading: true,
      error: false,
    })
  ),
  on(
    MeetingsPageActions.deleteActiveMeetingFailure,
    MeetingsPageActions.deleteMeetingSuccess,
    MeetingsPageActions.getMeetingSuccess,
    MeetingsPageActions.resumeMeetingSuccess,
    (state): MeetingsStateModel => ({
      ...state,
      loading: false,
      error: false,
    })
  ),
  on(MeetingsPageActions.deleteActiveMeetingSuccess, (state, { meetingId }) =>
    meetingsStateAdapter.removeOne(meetingId, {
      ...state,
      totalCount: state.totalCount - 1,
      loading: false,
      error: false,
    })
  ),
  on(
    MeetingsPageActions.loadPastMeetingsSuccess,
    (state, { pastMeetingsResponse }): MeetingsStateModel =>
      meetingsStateAdapter.setAll(pastMeetingsResponse.paginatedResults, {
        ...state,
        totalCount: pastMeetingsResponse.totalCount,
        loading: false,
        error: false,
      })
  ),
  on(
    MeetingsPageActions.getMeetingFailure,
    MeetingsPageActions.resumeMeetingFailure,
    MeetingsPageActions.loadPastMeetingsFailure,
    (state): MeetingsStateModel => ({
      ...state,
      loading: false,
      error: true,
    })
  ),
  on(
    MeetingsPageActions.paginationChange,
    (state, { index, size }): MeetingsStateModel => ({
      ...state,
      page: { index, size },
    })
  ),
  on(
    MeetingsPageActions.sortChange,
    (state, { sort }): MeetingsStateModel => ({
      ...state,
      sort: { direction: sort.direction.toUpperCase() as SortDirection, field: sort.active as MeetingSortFieldsEnum },
    })
  ),
  on(
    MeetingDialogActions.createMeetingSuccess,
    (state, { meeting }): MeetingsStateModel => ({
      ...state,
      currentMeeting: meeting,
    })
  ),
  on(
    MeetingsPageActions.getActiveMeetingInfoFailure,
    MeetingConcludeActions.meetingConcluded,
    (state): MeetingsStateModel => ({
      ...state,
      currentMeeting: null,
      meetingStatus: null,
      attendees: [],
    })
  ),
  on(
    MeetingsPageActions.getActiveMeetingInfoSuccess,
    (state, { meeting }): MeetingsStateModel => ({
      ...state,
      meetingStatus: meeting,
    })
  ),
  on(
    MeetingsPageActions.getMeetingSuccess,
    MeetingsPageActions.resumeMeetingSuccess,
    (state, { meeting }): MeetingsStateModel => ({
      ...state,
      currentMeeting: meeting,
    })
  ),
  on(RealTimeActions.presenceChanged, (state, { channelId, userId, action }): MeetingsStateModel => {
    let attendees = [...state.attendees];
    if (state?.meetingStatus?._id === channelId || state?.currentMeeting?._id === channelId) {
      if (action === 'join' && !state.attendees.includes(userId)) {
        attendees = [...state.attendees, userId];
      } else if (action === 'leave') {
        attendees = state.attendees.filter(id => id !== userId);
      }
    }

    return {
      ...state,
      attendees,
    };
  }),
  on(MeetingRealTimeActions.setMeetingPresence, (state, { attendees }): MeetingsStateModel => {
    return {
      ...state,
      attendees,
    };
  }),
  on(MeetingStateActions.updateCurrentMeeting, (state, { update }): MeetingsStateModel => {
    const currentMeeting = update ? { ...cloneDeep(state.currentMeeting), ...cloneDeep(update) } : null;
    return {
      ...state,
      currentMeeting,
      //remove attendees list if current meeting is being set to null
      attendees: currentMeeting ? state.attendees : [],
    };
  }),
  on(MeetingsPageActions.updateMeetingInStore, (state, { _id, update }): MeetingsStateModel => {
    let meeting = cloneDeep(state.entities[_id]);
    const entities = cloneDeep(state.entities);
    if (meeting) {
      let ratingAverage = meeting.ratingAverage;
      if (update.userRatings) {
        const ratings = update.userRatings.filter(userRating => userRating.rating !== null);
        if (ratings.length > 0) {
          ratingAverage = ratings.reduce((acc, userRating) => acc + (userRating.rating ?? 0), 0) / ratings.length;
          ratingAverage = ratingAverage ? parseFloat(ratingAverage.toFixed(2)) : null;
        } else {
          ratingAverage = null;
        }
      }
      meeting = {
        ...meeting,
        ...cloneDeep(update),
        ratingAverage,
      };
      entities[_id] = meeting;
    }
    return {
      ...state,
      entities,
    };
  }),

  on(
    MeetingSchedulingActions.getMeetingSchedules,
    MeetingSchedulingActions.deleteMeetingSchedule,
    MeetingSchedulingActions.updateMeetingSchedule,
    (state): MeetingsStateModel => {
      return {
        ...state,
        meetingSchedulesLoading: true,
      };
    }
  ),

  on(
    MeetingSchedulingActions.getMeetingSchedulesSuccess,
    MeetingSchedulingActions.getMeetingSchedulesFailure,
    MeetingSchedulingActions.deleteMeetingScheduleSuccess,
    MeetingSchedulingActions.deleteMeetingScheduleFailure,
    MeetingSchedulingActions.updateMeetingScheduleSuccess,
    MeetingSchedulingActions.updateMeetingScheduleFailure,
    (state): MeetingsStateModel => {
      return {
        ...state,
        meetingSchedulesLoading: false,
      };
    }
  )
);
