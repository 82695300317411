import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { DataImportActions } from '@ninety/data-import/_state/data-import.actions';

@Component({
  selector: 'ninety-data-import-user-step-one',
  template: ` <ninety-card>
    <div class="header">
      <terra-icon icon="warning-triangle" size="28"></terra-icon>
      <div class="title">Step 1: Prepare for User Import</div>
    </div>
    <div class="content">
      <ul>
        <li>For the best experience, <a class="brand" (click)="downloadCSVTemplate()">download our template</a>.</li>
        <li>
          To learn more about roles and permissions,
          <a class="brand" target="_blank" href="https://help.eos.ninety.io/en_US/directory/user-roles"
            >visit our help article</a
          >.
        </li>
        <li>
          Assigning paid roles to imported users beyond the number of available licenses will increase your license
          count and subscription cost. The payment method on file will be charged accordingly.
        </li>
        <li>
          Ensure the teams you're assigning users to are already set up.
          <a class="brand" routerLink="/settings/company/teams">Visit the Teams page</a> to create any required teams.
        </li>
      </ul>
    </div>
  </ninety-card>`,
  styleUrls: ['./info-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataImportUserStepOneComponent {
  constructor(private store: Store) {}

  downloadCSVTemplate() {
    this.store.dispatch(DataImportActions.downloadCSVTemplate());
  }
}
