import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { combineLatestWith, filter, map, Observable, skip, switchMap, tap } from 'rxjs';

import { SharedResponsibilityChartsActions } from '@ninety/accountability-chart/_state/shared-with-me/shared-responsibility-charts.actions';
import { LinkedItemsActions } from '@ninety/detail-view/_shared/linked-items/_state/linked-items.actions';
import { LinkedItemsSelectors } from '@ninety/detail-view/_shared/linked-items/_state/linked-items.selectors';
import { GuideActions } from '@ninety/getting-started/guide/_state/guide.actions';
import { CascadingMessagesStateActions } from '@ninety/headlines/_state/cascading-messages/cascading-messages-state.actions';
import { HeadlinesStateActions } from '@ninety/headlines/_state/headlines/headlines-state.actions';
import { IssuesActions } from '@ninety/issues/_state/issues.actions';
import { TodosTableActions } from '@ninety/my-ninety/components/todos-table/_state/todos-table.actions';
import {
  MeetingDialogActions,
  MeetingSchedulingActions,
  MeetingStateActions,
} from '@ninety/pages/meetings/_state/meetings.actions';
import { RocksActions } from '@ninety/rocks/_state/rocks.actions';
import { TeamComponentActions } from '@ninety/settings/company/teams/_state/teams-component.actions';
import { PersonalTodoActions, PersonalTodoInlineActions } from '@ninety/todos/_state/personal/personal-todo.actions';
import { TeamTodoActions, TeamTodoInlineActions } from '@ninety/todos/_state/team/team-todo.actions';
import { SegmentContactTraits } from '@ninety/ui/legacy/core/analytics/segment/models/segment-contact-traits';
import { SegmentGroupTraits } from '@ninety/ui/legacy/core/analytics/segment/models/segment-group-traits';
import { SegmentTrackEvent } from '@ninety/ui/legacy/core/analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '@ninety/ui/legacy/core/analytics/segment/segment.actions';
import { SegmentService } from '@ninety/ui/legacy/core/analytics/segment/segment.service';
import { HelperService } from '@ninety/ui/legacy/core/services/helper.service';
import { RoleService } from '@ninety/ui/legacy/core/services/role.service';
import { StateService } from '@ninety/ui/legacy/core/services/state.service';
import type { Person } from '@ninety/ui/legacy/shared/models/_shared/person';
import { Team } from '@ninety/ui/legacy/shared/models/_shared/team';
import type { User } from '@ninety/ui/legacy/shared/models/_shared/user';
import { BillingTypes } from '@ninety/ui/legacy/shared/models/company/billing-types.enum';
import type { Company } from '@ninety/ui/legacy/shared/models/company/company';
import type { CompanyUser } from '@ninety/ui/legacy/shared/models/company/company-user';
import { IntervalCode } from '@ninety/ui/legacy/shared/models/issues/interval-code';
import { UserNotificationSettings } from '@ninety/ui/legacy/shared/models/notifications/event-notification.models';
import {
  InviteUsersActions,
  UserPreferencesActions,
  UserSettingsActions,
} from '@ninety/ui/legacy/state/app-entities/users/users-state.actions';
import { AuthActions } from '@ninety/ui/legacy/state/app-global/auth/auth.actions';
import { CompanyActions } from '@ninety/ui/legacy/state/app-global/company/company-state.actions';
import { SubscriptionActions } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.actions';
import { selectSubscription } from '@ninety/ui/legacy/state/app-global/company/subscription/subscription-state.selectors';
import { selectIsLoginUrl } from '@ninety/ui/legacy/state/route.selectors';

import { MeetingsStateSelectors } from '../../pages/meetings/_state/meetings.selectors';
import { GainsightPXService } from '../gainsight/gainsight-px.service';
import { GainsightAccountAttributes } from '../gainsight/models/gainsight-account-attributes';
import type { IGainsightGlobalContext } from '../gainsight/models/gainsight-global-context';
import { GainsightUserAttributes } from '../gainsight/models/gainsight-user-attributes'; // Not sure why this isn't being picked up by our tsconfig.app.json compilerOptions.types definition

// Not sure why this isn't being picked up by our tsconfig.app.json compilerOptions.types definition
declare const analytics: SegmentAnalytics.AnalyticsJS;

@Injectable()
export class SegmentEffects {
  constructor(
    private actions$: Actions,
    // TODO: Remove once user & person are in ngrx
    private stateService: StateService,
    private segmentService: SegmentService,
    private gainsightPxService: GainsightPXService,
    private helperService: HelperService,
    private store: Store
  ) {}

  // TODO: Replace with ngrx selector after state service migrated to store
  private currentCompanyUser$ = this.stateService.currentCompanyUser$.pipe(filter(cu => !!cu));

  /**
   * Track navigation events.
   */
  trackNavigation$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(routerNavigatedAction),
        // initial page event called when is segment first load
        skip(1),
        concatLatestFrom(() => [this.segmentService.loaded(), this.store.select(selectIsLoginUrl)]),
        filter(([_, _loaded, isLoginUrl]) => !isLoginUrl),
        map(([routerEvent, _loaded, _isLoginUrl]) => routerEvent.payload),
        tap(_ => this.segmentService.page())
      );

      return action;
    },
    { dispatch: false }
  );

  /**
   * Initialize user/company information for Segment calls.
   */
  initializeSession$ = createEffect(
    () => {
      const action = this.currentCompanyUser$.pipe(
        filter(cu => !!cu),
        tap(cu => {
          const payload = this.getCommonTraits(cu);
          this.gainsightPxService.setGlobalContext(payload);
        }),
        map(cu => cu.company),
        combineLatestWith(
          this.stateService.currentUser$.pipe(filter(u => !!u)),
          this.stateService.currentPerson$.pipe(filter(p => !!p))
        ),
        map(([company, user, person]) => {
          const contactTraits = this.getContactTraits(user, person, company);
          const groupTraits = this.getGroupTraits(company);

          return { contactTraits, groupTraits };
        }),
        tap(({ contactTraits, groupTraits }) => {
          this.segmentService.onUserChange(contactTraits, groupTraits);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  onReady$ = createEffect(() =>
    // TODO: Could make a "destination ready" observable on the service to encapsulate the "analytics" global
    this.segmentService.loaded().pipe(
      /* We need to wait for segment's destinations to be ready.
       * I'm wrapping it in an observable to wait until the callback is triggered then we can emit
       * There might be a better way to do this...
       * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#ready
       */
      switchMap(_ => new Observable(observer => analytics.ready(() => observer.next()))),
      map(_ => SegmentActions.ready())
    )
  );

  trackGenericEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SegmentActions.track),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([{ event, params }, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            ...params,
          };

          this.segmentService.track(event, payload);
        })
      ),
    { dispatch: false }
  );

  /**
   * Track when a user invites another user to a company.
   */
  trackUserInvites$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(InviteUsersActions.inviteUserToCompany),
        combineLatestWith(this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            invitedUserEmail: action.invitedUser.email,
          };

          if (action?.sendInvite) {
            this.segmentService.track(SegmentTrackEvent.INVITED_USER, payload);
          }

          if (!action?.existingDirectoryUser) {
            this.segmentService.track(SegmentTrackEvent.ADDED_PERSON_TO_DIRECTORY, payload);
          }
        })
      );

      return action;
    },
    { dispatch: false }
  );

  /**
   * Track when a user saves a theme change
   */
  trackThemeUpdated$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(UserPreferencesActions.themeSaved),
        combineLatestWith(this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            companyName: currentCompanyUser.company.name,
            email: currentCompanyUser.emailAddresses[0].email,
            themeSelected: action.theme,
          };

          this.segmentService.track(SegmentTrackEvent.THEME_CHANGED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  /**
   * Track when a user archives a team
   */
  trackTeamArchived$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(TeamComponentActions.archiveTeamSuccess),
        combineLatestWith(this.currentCompanyUser$),
        filter(([_, currentCompanyUser]) => !!currentCompanyUser && !!currentCompanyUser.emailAddresses?.[0]?.email),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            companyName: currentCompanyUser.company?.name ?? 'Unknown Company',
            email: currentCompanyUser.emailAddresses[0].email,
            teamId: action.teamId,
          };

          this.segmentService.track(SegmentTrackEvent.TEAM_ARCHIVED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  /**
   * Track when a user saves a notification events
   */
  trackNotificationEventsUpdated$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(UserPreferencesActions.updateEventNotification),
        combineLatestWith(this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const userNotificationSettingsArray: UserNotificationSettings[] = action.data;

          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            companyName: currentCompanyUser.company.name,
            email: currentCompanyUser.emailAddresses[0].email,
            userNotificationSettingsArray: userNotificationSettingsArray,
          };

          this.segmentService.track(SegmentTrackEvent.USER_EVENT_NOTIFICATION_CHANGED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackGoogleTaskIntegrationChanged$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(UserSettingsActions.googleTaskIntegrationToggled),
        combineLatestWith(this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            companyName: currentCompanyUser.company.name,
            email: currentCompanyUser.emailAddresses[0].email,
            enabled: action.enabled,
          };

          this.segmentService.track(SegmentTrackEvent.GOOGLE_TASK_INTEGRATION_CHANGED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackMicrosoftTaskIntegrationChanged$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(UserSettingsActions.microsoftTaskIntegrationToggled),
        combineLatestWith(this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            companyName: currentCompanyUser.company.name,
            email: currentCompanyUser.emailAddresses[0].email,
            enabled: action.enabled,
          };

          this.segmentService.track(SegmentTrackEvent.MICROSOFT_TASK_INTEGRATION_CHANGED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackLogin$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(AuthActions.logIn),
        combineLatestWith(this.currentCompanyUser$),
        tap(([_, currentCompanyUser]) => {
          this.segmentService.track(SegmentTrackEvent.LOGIN, {
            ...this.getCommonTraits(currentCompanyUser),
          });
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackUserCreatedTodo$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(
          TeamTodoActions.addOne,
          PersonalTodoActions.addOne,
          TeamTodoActions.addMany,
          PersonalTodoActions.addMany
        ),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: action.todo._id,
            companyId: action.todo?.companyId ?? '',
            isPersonal: action.todo.isPersonal ?? false,
            teamId: action.todo.teamId,
            userId: action.todo.userId,
            eventSource: 'regular create',
            repeat: action.todo.repeat,
          };

          this.segmentService.track(SegmentTrackEvent.TODO_CREATED, payload);
        })
      );
      return action;
    },
    { dispatch: false }
  );

  trackUserCreatedTodoInline$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(TeamTodoInlineActions.createOne, PersonalTodoInlineActions.createOne),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: action.todo._id,
            companyId: action.todo?.companyId ?? '',
            isPersonal: action.todo.isPersonal ?? false,
            teamId: action.todo.teamId,
            userId: action.todo.userId,
            eventSource: 'inline create',
            repeat: action.todo.repeat,
          };

          this.segmentService.track(SegmentTrackEvent.TODO_CREATED, payload);
        })
      );
      return action;
    },
    { dispatch: false }
  );

  trackCompletedTodo$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(TeamTodoActions.setCompleted),
        concatLatestFrom(() => [
          this.currentCompanyUser$,
          this.store.select(MeetingsStateSelectors.selectCurrentMeetingInProgress),
        ]),
        tap(([{ id, todo, eventSource }, currentCompanyUser, meetingInProgress]) => {
          eventSource = meetingInProgress && eventSource === 'to-do page' ? 'meeting' : eventSource;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id,
            companyId: todo?.companyId ?? '',
            isPersonal: todo.isPersonal ?? false,
            teamId: todo.teamId,
            userId: todo.userId,
            eventSource,
          };

          this.segmentService.track(SegmentTrackEvent.TODO_SET_COMPLETED, payload);
        })
      );
      return action;
    },
    { dispatch: false }
  );

  trackUserCreatedRock$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(RocksActions.createRock),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { rock } = action;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: rock._id,
            additionalTeamIds: (rock?.additionalTeamIds ?? []).join(','),
            companyId: rock.companyId,
            dueDate: rock.dueDate,
            levelCode: rock.levelCode,
            statusCode: rock.statusCode,
            teamId: rock.teamId,
            userId: rock.userId,
          };

          this.segmentService.track(SegmentTrackEvent.ROCK_CREATED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackUserUpdatedRockStatus$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(RocksActions.updateRockStatus),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { rock } = action;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: rock._id,
            additionalTeamIds: (rock?.additionalTeamIds ?? []).join(','),
            companyId: rock.companyId,
            dueDate: rock.dueDate,
            levelCode: rock.levelCode,
            statusCode: rock.statusCode,
            teamId: rock.teamId,
            userId: rock.userId,
          };

          this.segmentService.track(SegmentTrackEvent.ROCK_STATUS_UPDATED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackUserCreatedIssue$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(IssuesActions.createIssue),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { issue, eventSource } = action;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: issue._id,
            companyId: issue.companyId,
            intervalCode: issue.intervalCode,
            isPublic: issue.isPublic,
            rating: issue.rating,
            teamId: issue.teamId,
            userId: issue.userId,
            eventSource,
          };

          this.segmentService.track(SegmentTrackEvent.ISSUE_CREATED, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackCompletedIssue$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(IssuesActions.completeIssue),
        filter(({ issue }) => issue.intervalCode !== IntervalCode.longTerm),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([{ issue, eventSource }, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: issue._id,
            companyId: issue.companyId ?? '',
            teamId: issue.teamId,
            userId: issue.userId,
            eventSource,
            issueCompletedFlag: issue.completed,
            issueCompletedDate: issue.completedDate,
          };

          this.segmentService.track(SegmentTrackEvent.ISSUE_SHORT_TERM_SET_COMPLETED, payload);
        })
      );
      return action;
    },
    { dispatch: false }
  );

  trackCompanyRegistered$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(CompanyActions.registerCompany),
        tap(action => {
          const { response } = action;
          const { user, company } = response;
          const payload = {
            bos: company.bos,
            companyId: company._id,
            createdByUserId: user._id,
            createdByPersonId: company.createdByPersonId,
            partnerKey: company.partnerKey,
            industry: company.industry,
            employeeRange: company.employeeRange,
            implementerCode: company.implementerCode,
            implementerFree: company.implementerFree,
            parentAffiliateCode: company.parentAffiliateCode,
          };

          this.segmentService.track(SegmentTrackEvent.REGISTER_COMPANY, payload);

          // false means the coupon failed to apply but the company successfully registered
          if (response.appliedCoupon === false) {
            this.segmentService.track(SegmentTrackEvent.REGISTER_COMPANY_APPLY_COUPON_FAILED, payload);
          }
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackCompanyRegisterFailed$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(CompanyActions.registerCompanyFail),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { company, error } = action;

          this.segmentService.track(SegmentTrackEvent.REGISTER_COMPANY_FAIL, {
            ...this.getCommonTraits(currentCompanyUser),
            personId: company.personId,
            companyName: company.companyName,
            error,
          });
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackUpdateDefaultStripePaymentMethod$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(CompanyActions.updateDefaultStripePaymentMethod),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { paymentMethod } = action;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            paymentMethodId: paymentMethod.id,
            stripeCustomer: paymentMethod.customer,
            type: paymentMethod.type,
          };

          this.segmentService.track(SegmentTrackEvent.UPDATED_STRIPE_PAYMENT_METHOD, payload);
        })
      );

      return action;
    },
    { dispatch: false }
  );

  trackContextMenuForTodosTable = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TodosTableActions.openContextMenu),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            source: action.source,
          };

          this.segmentService.track(SegmentTrackEvent.TODOS_TABLE_CONTEXT_MENU, payload);
        })
      ),
    { dispatch: false }
  );

  trackMeetingCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingDialogActions.createMeetingSuccess, MeetingStateActions.trackMeetingCreation),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { meeting } = action;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            meetingType: meeting.type,
          };

          this.segmentService.track(SegmentTrackEvent.MEETING_CREATE, payload);
        })
      ),
    { dispatch: false }
  );

  trackScheduleMeeting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeetingSchedulingActions.addMeetingSchedule),
        concatLatestFrom(() => [this.currentCompanyUser$, this.store.select(MeetingsStateSelectors.selectTeamId)]),
        tap(([action, currentCompanyUser, teamId]) => {
          const { schedule } = action;

          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            teamId,
            agendaId: schedule.agendaId,
            cadence: schedule.cadence,
            meetingType: schedule.meetingType,
          };

          this.segmentService.track(SegmentTrackEvent.MEETING_SCHEDULE_CREATE, payload);
        })
      ),
    { dispatch: false }
  );

  trackHeadlineCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(HeadlinesStateActions.createForSegment, HeadlinesStateActions.add),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { headline } = action;

          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: headline._id,
            companyId: headline.companyId ?? '',
            teamId: headline.teamId,
            userId: headline.userId,
          };

          this.segmentService.track(SegmentTrackEvent.HEADLINE_CREATED, payload);
        })
      ),
    { dispatch: false }
  );

  trackCompletedHeadline$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(HeadlinesStateActions.setCompletedSuccess, HeadlinesStateActions.markCompletedForSegment),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { id, changes } = action.update;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: id,
            companyId: currentCompanyUser?.company?._id,
            headlineCompletedFlag: changes.isDone,
            headlineCompletedDate: changes.completedDate,
          };

          this.segmentService.track(SegmentTrackEvent.HEADLINE_SET_COMPLETED, payload);
        })
      );
      return action;
    },
    { dispatch: false }
  );

  trackCascadedMessageCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CascadingMessagesStateActions.createCascadedForSegment, CascadingMessagesStateActions.add),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { cascadingMessage } = action;

          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: cascadingMessage._id,
            companyId: cascadingMessage.companyId ?? '',
            teamId: cascadingMessage.teamId,
            userId: cascadingMessage.userId,
          };

          this.segmentService.track(SegmentTrackEvent.CASCADED_CREATED, payload);
        })
      ),
    { dispatch: false }
  );

  trackCompletedCascadedMessage$ = createEffect(
    () => {
      const action = this.actions$.pipe(
        ofType(
          CascadingMessagesStateActions.setCompletedSuccess,
          CascadingMessagesStateActions.markCompletedForSegment
        ),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([action, currentCompanyUser]) => {
          const { id, changes } = action.update;
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            id: id,
            companyId: currentCompanyUser?.company?._id,
            headlineCompletedFlag: changes.isDone,
            headlineCompletedDate: changes.completedDate,
          };

          this.segmentService.track(SegmentTrackEvent.CASCADED_SET_COMPLETED, payload);
        })
      );
      return action;
    },
    { dispatch: false }
  );

  trackOrgChartCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedResponsibilityChartsActions.createMACSuccess),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([{ chart, source }, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            createdChartId: chart._id,
            source,
          };

          this.segmentService.track(SegmentTrackEvent.MAC_CREATED, payload);
        })
      ),
    { dispatch: false }
  );

  trackOrgChartCloned$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedResponsibilityChartsActions.cloneMACSuccess),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([{ chart, source }, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            createdChartId: chart._id,
            clonedFromChartId: chart.clonedFrom,
            source,
          };

          this.segmentService.track(SegmentTrackEvent.MAC_CLONED, payload);
        })
      ),
    { dispatch: false }
  );

  trackOrgChartSetPrimary$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SharedResponsibilityChartsActions.setPrimarySuccess),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([{ oldPrimaryChartId, newPrimaryChartId, source }, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            oldPrimaryChartId,
            newPrimaryChartId,
            source,
          };

          this.segmentService.track(SegmentTrackEvent.MAC_SET_PUBLIC_CHART, payload);
        })
      ),
    { dispatch: false }
  );

  trackTrialStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyActions.registerCompany),
        combineLatestWith(this.currentCompanyUser$, this.store.select(selectSubscription)),
        tap(([_, currentCompanyUser, subscription]) => {
          if (currentCompanyUser?.company?.billingType === BillingTypes.BillingV2) {
            const payload = {
              ...this.getCommonTraits(currentCompanyUser),
              subscriptionPlan: subscription?.planId,
              trialStartDate: subscription?.trialStart,
              trialEndDate: subscription?.trialEnd,
              paymentAddedDate: new Date().toISOString(),
            };
            this.segmentService.track(SegmentTrackEvent.TRIAL_STARTED, payload);
          }
        })
      ),
    { dispatch: false }
  );

  trackPaymentMethodAdded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubscriptionActions.triggerPaymentAddedSegmentEvent),
        combineLatestWith(this.currentCompanyUser$, this.store.select(selectSubscription)),
        tap(([_, currentCompanyUser, subscription]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            subscriptionPlan: subscription?.planId,
            trialStartDate: subscription?.trialStart,
            trialEndDate: subscription?.trialEnd,
            paymentAddedDate: new Date().toISOString(),
          };
          this.segmentService.track(SegmentTrackEvent.PAYMENT_ADDED, payload);
        })
      ),
    { dispatch: false }
  );

  trackSubscriptionCancelled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SubscriptionActions.triggerSubscriptionCancelledSegmentEvent),
        combineLatestWith(this.currentCompanyUser$, this.store.select(selectSubscription)),
        tap(([_, currentCompanyUser, subscription]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            subscriptionPlan: subscription?.planId,
            cancellationDate: new Date().toISOString(),
            effectiveUntilDate: subscription?.currentPeriodEnd,
          };

          this.segmentService.track(SegmentTrackEvent.SUBSCRIPTION_CANCELLED, payload);
        })
      ),
    { dispatch: false }
  );

  trackLinkedItemClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LinkedItemsActions.openLinkedItem),
        concatLatestFrom(() => [this.currentCompanyUser$, this.store.select(LinkedItemsSelectors.selectId)]),
        tap(([{ linkedItemType, linkedItemId }, currentCompanyUser, _id]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            itemId: _id,
            linkedItemType: linkedItemType,
            linkedItemId,
          };

          this.segmentService.track(SegmentTrackEvent.LINKED_ITEM_OPENED, payload);
        })
      ),
    { dispatch: false }
  );

  trackLinkedItemCreated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LinkedItemsActions.createdLinkedItem),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(
          ([
            {
              linkedItem: { id, linkedItemType, linkedItemId },
            },
            currentCompanyUser,
          ]) => {
            const payload = {
              ...this.getCommonTraits(currentCompanyUser),
              itemId: id,
              linkedItemType: linkedItemType,
              linkedItemId,
            };

            this.segmentService.track(SegmentTrackEvent.LINKED_ITEM_CREATED, payload);
          }
        )
      ),
    { dispatch: false }
  );

  trackABTodosSetting$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CompanyActions.updatedABTodosSetting),
        concatLatestFrom(() => this.currentCompanyUser$),
        tap(([{ agreementBasedTodos }, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            agreementBasedTodos: agreementBasedTodos,
          };

          this.segmentService.track(SegmentTrackEvent.AGREEMENT_BASED_TODOS_CHANGED, payload);
        })
      ),
    { dispatch: false }
  );

  /** we automatically open the getting started guide the first time it's shown to the user */
  trackForceOpeningGuide$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GuideActions.forceOpenGuide),
        combineLatestWith(this.currentCompanyUser$),
        tap(([_, currentCompanyUser]) => {
          const payload = {
            ...this.getCommonTraits(currentCompanyUser),
            companyName: currentCompanyUser.company.name,
            email: currentCompanyUser.emailAddresses[0].email,
          };

          this.segmentService.track(SegmentTrackEvent.GETTING_STARTED_GUIDE_FORCED_OPEN, payload);
        })
      ),
    { dispatch: false }
  );

  /**
   * User data will be overwritten in Gainsight, so we want to save a snapshot
   * of relevant data about the current user/company to the events to
   * enhance filtering capabilities.
   *
   * TODO: Create Segment Track Event Traits selector
   */
  private getCommonTraits(cu: CompanyUser): IGainsightGlobalContext {
    return {
      currentUserId: cu?._id,
      currentUserIsImplementer: cu?.isImplementer ?? false,
      currentUserRoleCode: cu?.roleCode,
      currentUserRoleName: RoleService.roleName(cu?.roleCode),
      currentPersonId: cu?.personId,
      currentUserCompany: cu?.company?._id,
      currentUserCompanyBos: cu?.company?.bos,
      url: location.href,
      urlPath: location.pathname,
      isLeadership:
        cu.teams.some((t: Pick<Team, 'teamId'>) => t?.teamId === cu.company?.seniorLeadershipTeamId) ?? false,
    } as const;
  }

  // TODO: Create Segment contact trait selector
  private getContactTraits(user: User, person: Person, company: Company): SegmentContactTraits {
    /*
      TODO: Plan to split out analytics providers into their own ngrx state and
      use selectors instead.

      Make a gainsight user attribute selector
    */
    const gainsight: GainsightUserAttributes = {
      accountId: company._id,
      active: user.active,
      // This method is only called after company user is fetched, assume true
      completedSignup: true,
      firstVisitDate: this.helperService.getCreatedDate(user._id),
      guideEnabled: user.guideEnabled ?? false,
      isLeadership: user.teams.some((t: Pick<Team, 'teamId'>) => t?.teamId === company.seniorLeadershipTeamId) ?? false,
      personIsImplementer: person.isImplementer,
      role: RoleService.roleName(user.roleCode),
      roleCode: user.roleCode,
      signUpDate: this.helperService.getCreatedDate(user._id),
      userIsImplementer: user.isImplementer,
    };

    return {
      // Reserved traits
      id: person._id, // Don't use user._id
      email: person.primaryEmail ?? undefined,
      phone: person.primaryPhoneNumber ?? undefined,
      firstName: user.metadata?.name?.first,
      lastName: user.metadata?.name?.last,
      company: {
        id: user.company.companyId,
        name: company.name,
      },

      // Common custom traits
      active: user.active ?? true,
      userId: user._id,
      lastAccessed: new Date().toISOString(),
      bos: company.bos,
      ...gainsight,
    };
  }

  // TODO: Create Segment group trait selector
  private getGroupTraits(company: Company): SegmentGroupTraits {
    // TODO: Move to gainsight selector
    const gainsight: GainsightAccountAttributes = {
      affiliateCode: company.affiliateCode,
      bos: company.bos,
      clearbit_employees_range: company.employeeRange,
      clearbit_industry: company.industry,
      implementerCode: company.implementerCode,
      implementerFree: company.implementerFree,
      parentAffiliateCode: company.parentAffiliateCode,
      partnerKey: company.partnerKey,
      trialEndDate: company.trialingUntil,
      stripeStatus: company.subscription?.providerStatus,
      subscriptionProvider: company.subscription?.provider,
      accountStatus: company.accountStatus,
    };

    return {
      avatar: company.logo?.url,
      id: company._id,
      name: company.name,
      phone: company.phoneNumber,
      ...gainsight,
    };
  }
}
