import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { SegmentTrackEvent } from '@ninety/ui/legacy/core/analytics/segment/models/segment-track-event.enum';
import { SegmentActions } from '@ninety/ui/legacy/core/analytics/segment/segment.actions';

import { MazActions } from './maz.actions';

@Injectable()
export class MazAnalyticsEffects {
  constructor(private actions$: Actions) {}

  trackToggleMazChat = createEffect(() =>
    this.actions$.pipe(
      ofType(MazActions.toggleMazChat),
      map(() =>
        SegmentActions.track({
          event: SegmentTrackEvent.MAZ_CHAT_TOGGLED,
        })
      )
    )
  );

  /** track when users opt in/out to Maz chat */
  trackUserMazStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MazActions.toggleUserMazStatus),
      map(({ enabled }) => {
        const event = enabled ? SegmentTrackEvent.MAZ_CHAT_OPT_IN : SegmentTrackEvent.MAZ_CHAT_OPT_OUT;
        return SegmentActions.track({ event });
      })
    )
  );

  trackMazChatMessageSent = createEffect(() =>
    this.actions$.pipe(
      ofType(MazActions.sendMessageToMaz),
      map(() =>
        SegmentActions.track({
          event: SegmentTrackEvent.MAZ_CHAT_SENT_MESSAGE,
        })
      )
    )
  );
}
