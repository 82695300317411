<h1 mat-dialog-title>Update Scheduled {{ data.schedule.agendaName }}</h1>
<h2 mat-dialog-subtitle *ngIf="data.schedule.cadence !== Cadence.none">
  This {{ data.meetingLanguage.item }} is repeating {{ data.schedule.cadence }}
</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <div class="date">
      <mat-label for="date-field">Date</mat-label>
      <mat-form-field appearance="outline" id="date-field">
        <input
          matInput
          [min]="data.schedule.scheduledDate"
          [matDatepicker]="datePicker"
          required
          aria-label="date picker"
          formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="datePicker">
          <terra-icon matDatepickerToggleIcon icon="calendar" />
        </mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="time">
      <mat-label for="time-fields">Time</mat-label>
      <div class="time-fields-wrp" id="time-fields">
        <mat-form-field appearance="outline" class="time-hh">
          <input
            type="number"
            min="1"
            max="12"
            matInput
            aria-label="hours"
            formControlName="hours"
            placeholder="hh"
            data-cy="update-schedule-time_hours" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="time-mm">
          <input
            type="number"
            min="0"
            max="59"
            matInput
            aria-label="minutes"
            formControlName="minutes"
            placeholder="mm" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="period">
          <mat-select formControlName="period">
            <mat-option [value]="TimePeriod.AM">{{ TimePeriod.AM }}</mat-option>
            <mat-option [value]="TimePeriod.PM">{{ TimePeriod.PM }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="presenter">
      <mat-label for="presenter-id">Facilitator</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="presenterId" id="presenter">
          <mat-option *ngFor="let user of data.users" [value]="user.value">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="scribe">
      <mat-label for="presenter-id">Scribe</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="scribeId" placeholder="Select Scribe" id="scribe">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let user of data.users" [value]="user.value">{{ user.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="update-options" *ngIf="data.schedule.cadence !== Cadence.none">
      <mat-label> Do you want to update one or all {{ data.meetingLanguage.item }} schedules? </mat-label>
      <mat-radio-group aria-labelledby="What-to-update" formControlName="updateAll">
        <mat-radio-button [value]="false" data-cy="update-schedule_radio-btn-one">
          Update only this {{ data.meetingLanguage.item }}
        </mat-radio-button>
        <mat-radio-button [value]="true" data-cy="update-schedule_radio-btn-all"> Update all </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <ninety-button stroked mat-dialog-close class="right-space-10">Cancel</ninety-button>
  <ninety-button
    filled
    primary
    (click)="onUpdate()"
    [disabled]="!form.valid || !form.dirty"
    data-cy="update-schedule-actions_save-btn">
    <terra-icon icon="save" />
    Save
  </ninety-button>
</mat-dialog-actions>
