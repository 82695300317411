import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';

import { MeetingSchedule } from '@ninety/pages/meetings/_models';
import { TerraIconModule, TerraDividerModule } from '@ninety/terra';

@Component({
  selector: 'ninety-meeting-schedules-context-menu',
  template: `
    <button
      [matMenuTriggerFor]="contextMenu"
      [matMenuTriggerData]="schedule"
      class="schedules-context-menu-trigger"></button>
    <mat-menu class="schedules-context-menu" #contextMenu="matMenu">
      <ng-template matMenuContent>
        <ninety-button
          data-cy="schedules-context-menu_edit-button"
          mat-menu-item
          id="schedules-edit-btn"
          (click)="onEdit(schedule)">
          <terra-icon icon="edit" size="20" class="mat-menu-icon" />
          Edit Schedule
        </ninety-button>
        <terra-divider />
        <ninety-button
          data-cy="schedules-context-menu_delete-button"
          mat-menu-item
          id="schedules-delete-btn"
          (click)="onDelete(schedule)">
          <terra-icon icon="delete" size="20" class="mat-menu-icon" />
          Delete
        </ninety-button>
      </ng-template>
    </mat-menu>
  `,
  styles: [
    `
      .schedules-context-menu-trigger {
        position: fixed;
        width: 0;
        height: 0;
        visibility: hidden;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule, MatMenuModule, TerraIconModule, LetDirective, TerraDividerModule],
})
export class MeetingSchedulesContextMenuComponent {
  @Output() delete = new EventEmitter<{ schedule: MeetingSchedule }>();
  @Output() edit = new EventEmitter<{ schedule: MeetingSchedule }>();

  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef }) triggerElement: ElementRef;
  @ViewChild(MatMenu) menu: MatMenu;

  schedule: MeetingSchedule;

  openMenu(event: MouseEvent, schedule: MeetingSchedule) {
    event.preventDefault();
    event.stopPropagation();

    this.triggerElement.nativeElement.style.top = `${event.pageY}px`;
    this.triggerElement.nativeElement.style.left = `${event.pageX}px`;

    this.schedule = schedule;
    this.trigger.openMenu();
  }

  onDelete(schedule: MeetingSchedule) {
    this.delete.emit({ schedule });
  }

  onEdit(schedule: MeetingSchedule) {
    this.trigger.closeMenu();
    this.edit.emit({ schedule });
  }
}
